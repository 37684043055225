import { Component, OnInit } from "@angular/core";
import { BackendService } from "src/app/services/backend/backend.service";
import { OAuthService } from "angular-oauth2-oidc";
import { Observable } from "rxjs";
import { Router } from "@angular/router";

@Component({
    selector: "cc-terms-and-conditions",
    templateUrl: "./terms-and-conditions.component.html",
    styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
    username: string = "";
    hasAcceptedTerms: boolean = false;
    siteId: string = "";

    constructor(
        private backendService: BackendService,
        private authService: OAuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.username = this.authService.getIdentityClaims()["email"];

        this.backendService.checkTermsOfUseStatus(this.username).subscribe(
            (response) => {
                this.hasAcceptedTerms = response.hasAgreedTermsAndConditions;
                sessionStorage.setItem(
                    "hasAcceptedTerms",
                    JSON.stringify(this.hasAcceptedTerms)
                );
            },
            (err) => {
                console.log(err);
            }
        );
    }

    acceptOnTermsOfUse() {
        this.backendService.acceptTermsOfUse(this.username).subscribe(
            (response) => {
                this.hasAcceptedTerms = true;
                sessionStorage.setItem(
                    "hasAcceptedTerms",
                    JSON.stringify(this.hasAcceptedTerms)
                );
                this.router.navigate([`/equipments`]);
            },
            (err) => {
                console.log(err);
            }
        );
    }

    canDeactivate(): Observable<boolean> | boolean {
        return this.hasAcceptedTerms;
    }
}
