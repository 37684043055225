import { Component, Input, OnInit, Renderer2 } from '@angular/core';
import { EquipmentFilters } from 'src/app/models/dashboard';
import { COLOR_LIST_EXTENDED, TimeFramesValues } from 'src/app/config/constants';
import moment from 'moment-timezone';
import * as Highcharts from 'highcharts/highstock';
import _ from 'lodash';
import { SelectItem } from 'primeng/api';
import { BackendService } from 'src/app/services/backend/backend.service';
import { forkJoin } from 'rxjs';
import { TelemetryData } from 'src/app/models/telemetry-data';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import {CsvExportService} from "../../services/data/csv-export.service";

@Component({
	selector: 'cc-historical-data',
	templateUrl: './historical-data.component.html',
	styleUrls: ['./historical-data.component.scss']
})
export class HistoricalDataComponent implements OnInit {
	@Input() pointsObj: {};

	historiesOptions: EquipmentFilters[] = [];
	selectedHistories: any[] = [];
	haveDisabledHistoriesOptions: boolean = false;
	isHistorySelectionActive: boolean = false;
	filterButtonScheme: string = 'discreetDark';
	noHistoriesMessage: string = '';
	historiesNoMaxLimit: number = null;

	start: Date = moment.utc().subtract(7, 'days').toDate();
	end: Date = moment.utc().toDate();
	maxDateValue: Date = moment.utc().toDate();
	minDateValue: Date = moment.utc().subtract(90, 'days').toDate();
	rangeDates: Date[];
	timeOptionList: SelectItem[] = [
		{ label: 'Select time', value: '' },
		{ label: 'Today', value: TimeFramesValues.TODAY },
		{ label: 'Yesterday', value: TimeFramesValues.YESTERDAY },
		{ label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS },
		{ label: 'Last 30 days', value: TimeFramesValues.LAST_30_DAYS },
		{ label: 'Last 365 days', value: TimeFramesValues.LAST_365_DAYS }
	];
	selectedTime: SelectItem = { label: 'Last 7 days', value: 7 };

    selectedSite: {
        label: string,
        value: string,
        customerId: string,
        timezone: string
    } = JSON.parse(sessionStorage.getItem('selectedSite'));

	Highcharts: typeof Highcharts = Highcharts;
	chartOptions: Highcharts.Options = {
		credits: {
			enabled: false
		},
		title: {
			text: null
		},
		subtitle: {
			text: null
		},
		chart: {
			zooming: {
				type: 'x'
			},
			events: {
				redraw: function (event) {
					this.xAxis[0].setExtremes(null, null, false);
				}
			}
		},
		legend: {
			verticalAlign: 'top',
		},
		xAxis: {
			type: 'datetime'
		},
		yAxis: [],
		tooltip: {
			shared: true
		},
		plotOptions: {
			line: {
				marker: {
					radius: 3
				},
				lineWidth: 1
			}
		},
		navigator: {
			enabled: true
		},
		time: {
			timezone: this.selectedSite.timezone
		}
	};

	updateFlag: boolean = false;
	showChart: boolean = false;

	constructor(private backendService: BackendService,
                private renderer: Renderer2,
                private breakpointObserver: BreakpointObserver,
                private csvExportService: CsvExportService,
    ) { }

	ngOnInit(): void {
		window.moment = moment;
		this.breakpointObserver
			.observe([Breakpoints.Small])
			.subscribe((state: BreakpointState) => {
				if (!state.matches) {
					this.noHistoriesMessage = 'Please select required histories from drop down menu<br />Maximum 10 histories can be viewed';
					this.historiesNoMaxLimit = 10;
				} else {
					this.noHistoriesMessage = 'Please select required histories from drop down menu<br />Maximum 5 histories can be viewed';
					this.historiesNoMaxLimit = 5;
				}
			});

		this.createHistoriesOptions();
		this.setDefaultTimeframe();
	}

	public createHistoriesOptions() {
		let rootEqClassLabel = '';
		let pointsArray = [];
		let currentObj = {}

		Object.keys(this.pointsObj).forEach((eqName) => {
			rootEqClassLabel = eqName;
			currentObj = this.pointsObj[eqName];

            Object.keys(currentObj).map((key, index) => {
                pointsArray.push(...currentObj[key].map(point => {
                    if (index === 0) {
                        return {
                            label: `${point.comment || point.sourceDescription || point.classLabel}`,
                            value: Object.assign({}, point, {chartLegend: `${eqName} - ${point.comment || point.sourceDescription || point.classLabel}`}),
                            pointUUID: point.pointUUID,
                            disabled: false
                        }
                    }
                    return {
                        label: `${key} - ${point.comment || point.sourceDescription || point.classLabel}`,
                        value: Object.assign({}, point, {chartLegend: `${eqName} - ${point.comment || point.sourceDescription || point.classLabel}`}),
                        pointUUID: point.pointUUID,
                        disabled: false
                    }
                }))
            });

			this.historiesOptions.push({
				label: rootEqClassLabel,
				value: rootEqClassLabel,
				items: pointsArray
			});
			pointsArray = [];
		});
	}

	public onIntervalSelected(event: any) {
		this.resetSelectedTime();
	}

	public onFilterByDate() {
		this.getFormattedTelemetryData();
	}

	public onIntervalOptionSelected() {
		this.setCalendarInterval();
	}

	public applyHistories(event: any, historiesDDRef: any) {
		if (!this.selectedHistories.length) {
			this.clearHistories(event, historiesDDRef);
			return;
		}
		this.getFormattedTelemetryData();
		historiesDDRef.close(event);
	}

	public clearHistories(event: any, historiesDDRef: any) {
		this.selectedHistories = [];
		this.showChart = false;
		this.setDefaultTimeframe();
		this.onHistoriesSelectionChange();
		historiesDDRef.close(event);
	}

	public onHistoriesSelectionChange() {
		if (this.selectedHistories.length === this.historiesNoMaxLimit) {
			this.historiesOptions.map(historyOption => {
				_.differenceBy(historyOption.items, this.selectedHistories, 'pointUUID').map(item => item.disabled = true);
				this.haveDisabledHistoriesOptions = true;
			})
		}
		if (this.selectedHistories.length < this.historiesNoMaxLimit && this.haveDisabledHistoriesOptions) {
			this.historiesOptions.map(historyOption => {
				historyOption.items.map(item => item.disabled = false);
			});
			this.haveDisabledHistoriesOptions = false;
		}
	}

	public applyHistoriesOnMobile() {
		if (!this.selectedHistories.length) {
			this.clearHistoriesOnMobile();
			return;
		}
		this.getFormattedTelemetryData();
		this.closeHistoriesSelectionOnMobile();
	}

	public clearHistoriesOnMobile() {
		this.selectedHistories = [];
		this.showChart = false;
		this.setDefaultTimeframe();
		this.onHistoriesSelectionChange();
		this.closeHistoriesSelectionOnMobile();
	}

	public showHistoriesSelectionMenuOnMobile() {
		this.renderer.addClass(document.body, 'noscroll');
		this.renderer.addClass(document.getElementById('app-wrapper'), 'noscroll');
		this.isHistorySelectionActive = true;
	}

	public closeHistoriesSelectionOnMobile() {
		this.renderer.removeClass(document.body, 'noscroll');
		this.renderer.removeClass(document.getElementById('app-wrapper'), 'noscroll');
		this.isHistorySelectionActive = false;
	}

	private getFormattedTelemetryData() {
		let start = moment(this.rangeDates[0]).utc().format();
		let end = '';

		if (!this.rangeDates[1]) {
			end = moment(this.rangeDates[0]).endOf("day").utc().format();
		} else {
			end = moment(this.rangeDates[1]).endOf("day").utc().format();
		}

		this.getTelemetryData(this.selectedHistories.map(point => point.pointUUID), start, end).subscribe((response => {
			this.chartOptions.series = this.formatTelemetryData(response);
			this.updateFlag = true;
			this.showChart = true;
		}));
	}

	private getTelemetryData(pointUUIDList: string[], start?: string, end?: string) {
		const startDate = start || moment.utc().subtract(7, 'days').format();
		const endDate = end || moment.utc().format();
		let requestsArray = pointUUIDList.map(pointUUID => {
			return this.backendService.getTelemetryData(pointUUID, startDate, endDate);
		});

		return forkJoin(requestsArray);
	}

	private formatTelemetryData(dataToFormat: TelemetryData[]) {
		let data: any[] = [];
		this.selectedHistories.forEach((point, index) => {
			if (dataToFormat[index] == null) { return; }
			dataToFormat[index].chartLegend = point.chartLegend;
		})

		let filterdByNull = dataToFormat.filter(telemetryData => telemetryData !== null);
		const groupedByUnit = _.groupBy(filterdByNull, (telemetryData) => telemetryData.units);
		let colorIndex = 0;

		Object.keys(groupedByUnit).map((unit, index) => {
			let unitType = unit === "null" || unit === "noUnits" ? '' : unit;
			// let color = this.getColorByIndex(index) || Highcharts.getOptions().colors[index];
			let color = '#000000';


			this.chartOptions.yAxis[index] =
			{
				title: {
					text: unitType,
					style: {
						color: color
					}
				},
				labels: {
					style: {
						color: color
					}
				},
				opposite: false
			};

			groupedByUnit[unit].forEach((telemetryData, i) => {
				if (telemetryData != null) {
					data.push({
						name: telemetryData.chartLegend,
						type: 'line',
						dashStyle: 'solid',
						yAxis: index,
						color: this.getColorByIndex(colorIndex++),
						data: telemetryData.samples.sort((a, b) => { return a.timestamp - b.timestamp }).map(entry => {
							return [entry.timestamp, entry.value];
						})
					});
				};
			})
		});
		return data;
	}

	private setCalendarInterval() {
		let start = moment('00:00:00', 'HH:mm:ss').utc().subtract(this.selectedTime.value, 'days').toDate();
		let end = moment('23:59:00', 'HH:mm:ss').utc().toDate();

		if (this.selectedTime.value === TimeFramesValues.YESTERDAY) {
			end = moment(start).endOf("day").seconds(0).milliseconds(0).toDate();
		}

		this.rangeDates = [start, end];
	}

	private resetSelectedTime() {
		this.selectedTime = { label: 'Select time', value: '' };
	}

	private setDefaultTimeframe() {
		this.selectedTime = { label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS };
		this.setCalendarInterval();
	}

	private getColorByIndex(index: number): string {
		return COLOR_LIST_EXTENDED[index];
    }

    exportToCsv() {
        this.csvExportService.exportToCsv(this.chartOptions, `${this.selectedSite.label} Data Points History ${moment(new Date()).format('YYYYMMDD HH:mm')}`);
    }
}
