<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your Site Overview</h1>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="header">
            <img src="../../../assets/svg/Buildings-1.svg" />Site Overview
        </div>
        <div class="title">
            <h3>Building Opening Time</h3>
            <button
                pButton
                type="button"
                icon="pi pi-pencil"
                [className]="
                    userHasEditPermission
                        ? 'p-element ui-button-info cbms-margin-left p-button p-component p-button-icon-only'
                        : 'hide-item p-element ui-button-info cbms-margin-left p-button p-component p-button-icon-only'
                "
                (click)="editSchedule()"
            ></button>
        </div>
        <div class="content">
            <table>
                <tr *ngFor="let day of days">
                    <td class="day">{{ day | titlecase }}</td>
                    <td *ngIf="!siteOverviewInfo?.siteSchedule[day]['openTime']; else showTime">
                        : Closed
                    </td>
                    <ng-template #showTime>
                        <td>
                            {{
                                ": " +
                                siteOverviewInfo?.siteSchedule[day]['openTime'] +
                                    " - " +
                                siteOverviewInfo?.siteSchedule[day]['closeTime']
                            }}
                        </td>
                    </ng-template>
                </tr>
            </table>
            <span class="timezone"
                >*Timezone: {{ siteOverviewInfo?.timezone }}</span
            >
        </div>
        <div class="footer">
            <div
                class="entity-card-footer flex align-items-center justify-content-between"
            >
                <eon-ui-icon
                    name="more"
                    aria-label="more"
                    class="hydrated"
                ></eon-ui-icon>
                <cc-share-link
                    [link]="siteOverviewLink"
                ></cc-share-link>
            </div>
        </div>
    </div>
</div>
