import { Component, OnInit } from "@angular/core";
import { BackendService } from "src/app/services/backend/backend.service";
import { Customer } from "src/app/models/customer";
import { OAuthService } from 'angular-oauth2-oidc';
import { NotificationService } from "src/app/services/notification/notification.service";
import {
    EventNotificationService,
    EventType,
} from "src/app/services/notification/event-notification.service";
import {AuthorizationService, ROLE_PERMISSIONS} from "../../services/authorization.service";

@Component({
    selector: "cc-customer-specification",
    templateUrl: "./customer-specification.component.html",
    styleUrls: ["./customer-specification.component.scss"],
})
export class CustomerSpecificationComponent implements OnInit {
    customerSpecificationsText: string = "";
    specificationsTextToSave: string = "";
    isEditMode: boolean = false;
    userHasWritePermission: boolean;
    customerList: Customer[] = null;
    selectedCustomer: Customer = null;
    username: string = "";
    apiKey = 'jgcvu0makfo31g2cr360uaj1syrvgfgreep0uk18atwmo5cz';

    constructor(
        private backendService: BackendService,
        private authService: OAuthService,
        private notificationService: NotificationService,
        private eventNotificationService: EventNotificationService,
        private authorizationService: AuthorizationService,
    ) {}

    ngOnInit(): void {
        this.eventNotificationService.emittEvent({
            type: EventType.CUSTOMER_SPECIFICATIONS_VIEW_SELECTED,
            payload: null,
        });

        const claims = this.authService.getIdentityClaims();
        this.username = claims ? claims['email'] : '';
        this.userHasWritePermission = this.authorizationService.hasPermission(ROLE_PERMISSIONS.EDIT_CUSTOMER_SPEC_BUTTON_DASHBOARDS_APP);

        this.backendService
            .getCustomerListByUser()
            .subscribe((response: any) => {
                this.customerList = response.content;
            });
    }

    ngOnDestroy() {
        this.eventNotificationService.emittEvent({
            type: EventType.DEFAULT_VIEW_SELECTED,
            payload: null,
        });
    }

    onCustomerChosen() {
        this.backendService
            .getCustomerSpecificationsByCustomerId(
                this.selectedCustomer.id,
                this.username
            )
            .subscribe((response) => {
                this.customerSpecificationsText = response.userDescription;
            });
    }

    activateEditMode() {
        this.isEditMode = true;
        this.specificationsTextToSave = this.customerSpecificationsText;
    }

    save() {
        this.backendService
            .addCustomerSpecifications(
                this.selectedCustomer.id,
                this.specificationsTextToSave,
                this.username
            )
            .subscribe(
                (response: any) => {
                    this.notificationService.addSuccessMessage(
                        "Success",
                        "Specifications successfully added."
                    );
                    this.customerSpecificationsText = response.userDescription;
                    this.cancel();
                },
                (err) => {
                    this.notificationService.addErrorMessage("Error", err);
                }
            );
    }

    cancel() {
        this.isEditMode = false;
        this.specificationsTextToSave = "";
    }

    parseSpecificationsTextForHTML(text: string) {
        return text.replaceAll("\n", "<br>");
    }

    parseSpecificationsTextForBE(text: string) {
        return text.replaceAll("<br>", "\n");
    }
}
