import { PipeTransform, Pipe } from "@angular/core";
import { IN_PROGRESS } from "../config/constants";
import { AlertStatusCode, SmartAlertStatusLabel } from "../models/core/alert-rule.model";


@Pipe({ name: "alertStatusLabel" })
export class AlertStatusLabelPipe implements PipeTransform {
    transform(alertStatusCode: string): string {
        switch (alertStatusCode) {
            case AlertStatusCode.OPEN:
                return SmartAlertStatusLabel.OPEN;
            case AlertStatusCode.CLOSED:
                return SmartAlertStatusLabel.CLOSED;
            case AlertStatusCode["IN PROGRESS"]:
                return SmartAlertStatusLabel.IN_PROGRESS;
            default:
                return `Unknown alert status ${alertStatusCode}`;
        }
    }

}
