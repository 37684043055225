import { Component, OnInit } from "@angular/core";
import { OAuthService } from "angular-oauth2-oidc";

@Component({
    selector: "cbms-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
    constructor(private authService: OAuthService) {}

    ngOnInit(): void {
        // this.dataService.currentTabHasChanged('error');
    }

    logout() {
        this.authService.logOut();
    }
}
