import {Component, Input, OnInit} from "@angular/core";
import {Observable} from "rxjs";
import {CollectionDetails, DeviceStatus, Point} from "src/app/models/dashboard";
import {BackendService} from "src/app/services/backend/backend.service";
import {Operation} from "src/app/config/constants";

@Component({
    selector: "cc-collection-details-view",
    templateUrl: "./details-view.component.html",
    styleUrls: ["./details-view.component.scss"],
})
export class CollectionDetailsViewComponent implements OnInit {
    @Input() collectionDetails: CollectionDetails;
    @Input() username: string;
    @Input() selectedSite: { siteId: string, customerId: string };
    @Input() pointsStatus: { [key: string]: DeviceStatus } = {};


    showScheduleDialog: boolean = false;
    showExceptionScheduleDialog: boolean = false;
    selectedScheduleUUID: string = "";
    selectedExceptionScheduleUUID: string = "";
    collectionLink: string;
    pointsObj: { [key: string]: { [key: string]: Point[] } } = {};

    isOverrideResetOn: boolean = false;
    userHasWritePermission$: Observable<boolean>;
    category = 'collection';

    constructor(private backendService: BackendService) {
    }

    ngOnInit(): void {
        const collectionId = this.collectionDetails.uri.split(':').pop();
        this.collectionLink = `${window.location.origin}/customers/${this.selectedSite.customerId}/sites/${this.selectedSite.siteId}/collections/${collectionId}`;
    }

    showSchedule(pointUUID: string) {
        this.selectedScheduleUUID = pointUUID;
        this.showScheduleDialog = true;
    }

    showExceptionSchedule(pointUUID: string) {
        this.selectedExceptionScheduleUUID = pointUUID;
        this.showExceptionScheduleDialog = true;
    }

    togglePin(event: MouseEvent, collection: CollectionDetails) {
        event.stopPropagation();
        const operation = collection.pinned ? Operation.Delete : Operation.Add;
        this.backendService
            .entityAddRemovePin(
                this.category,
                this.username,
                this.selectedSite,
                operation,
                collection.uri
            )
            .subscribe(
                () => {
                    collection.pinned = !collection.pinned;
                },
                (err) => {
                    console.log(err);
                }
            );
    }
}
