import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';

@Injectable()
export class NotificationService {
    constructor(private messageService: MessageService) {

    }

    public addErrorMessage(title: string, message: string, sticky: boolean = true) {
        this.messageService.add({severity: 'error', summary: title, detail: message, sticky});
    }

    public addInfoMessage(title: string, message: string, sticky: boolean = true) {
        this.messageService.add({severity: 'info', summary: title, detail: message, sticky});
    }

    public addWarnMessage(title: string, message: string, sticky: boolean = true) {
        this.messageService.add({severity: 'warn', summary: title, detail: message, sticky});
    }

    public addSuccessMessage(title: string, message: string, sticky: boolean = true) {
        this.messageService.add({severity: 'success', summary: title, detail: message, sticky});
    }
}
