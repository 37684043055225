<div class="wrapper">
    <div class="sub-wrapper">
        <div class="flex-auto flex-container">
            <div class="scrollable">
                <div class="dashboard page-padding flex-container rendered-complete" data-dashboard-id="terms"
                    data-dashboard-template-id="terms">
                    <div class="splashpage-dashboard">
                        <div class="dashboard flex-container ng-star-inserted">
                            <div class="splashpage-ems-widget flex-auto flex-container">
                                <div class="terms-acceptance">
                                    <div class="terms-of-use-header flex-none">
                                        <div _ngcontent-huc-c214="" class="terms-header">
                                            <div _ngcontent-huc-c214="" class="spoke-red"></div>
                                            <div _ngcontent-huc-c214="" class="spoke-bordeaux"></div>
                                            <div _ngcontent-huc-c214="" class="innerheader">
                                                <h1 _ngcontent-huc-c214="">Optimum, Terms of Use</h1>

                                                <p>Optimum Connect and Control is a manufacturer agnostic, cloud-based
                                                    application that gives customers the ability to truly optimise their
                                                    buildings and assets.</p>

                                            </div>
                                            <div class="spoke-lime"></div>
                                        </div>
                                    </div>
                                    <div class="terms-of-use-container flex-auto terms-of-use-acceptance">
                                        <div class="terms-scrollable">
                                            <div class="inner">
                                                <div class="terms-of-use-content">
                                                    <div class="terms-of-use-content">
                                                        <div>
                                                            <p>By accepting these Terms the Customer agrees to follow
                                                                and be bound by these Terms when using the Online
                                                                Services. If the Customer does not agree to these Terms,
                                                                it must decline and may not have access to or use the
                                                                Online Services.</p>
                                                            <p><br></p>

                                                            <p><strong>Terms of Use: Online Services</strong></p>

                                                            <p><br></p>

                                                            <ol>
                                                                <li>
                                                                    <strong>Online Services</strong>
                                                                    <ol>
                                                                        <li>E.ON shall provide the Online Services to
                                                                            the Customer via the Optimum Connect &
                                                                            Control platform which shall include:
                                                                            &nbsp;
                                                                            <ol>
                                                                                <li>visualisation of energy consumption
                                                                                    and BMS data provided to it by the
                                                                                    Customer (either directly or by the
                                                                                    Customer’s agent) from multiple
                                                                                    sources for advanced remote
                                                                                    monitoring and the visualisation of
                                                                                    data points. </li>
                                                                                <li>visualisation of the connected Asset
                                                                                    details and time schedules.</li>
                                                                                <li>the ability to remotely steer and
                                                                                    adjust device setpoints and override
                                                                                    outputs as well as configuring
                                                                                    alarms.</li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>The provision of the Online Services also
                                                                            provides the necessary information for
                                                                            energy and Asset analysis and reporting.
                                                                            Such analysis and reporting can be provided
                                                                            to the Customer by E.ON as an Additional
                                                                            Service for an Additional Charge or may be
                                                                            used by the Customer directly to undertake
                                                                            its own energy and Asset analysis and
                                                                            reporting. The Customer will have access to
                                                                            the Online Services via a web portal and
                                                                            E.ON shall use reasonable endeavours to
                                                                            facilitate the Customer’s access to the
                                                                            Online Services on a 24 hours a day, 7 days
                                                                            a week basis subject always to the Customer
                                                                            complying with the Minimum IT Requirements
                                                                            and these Terms.</li>
                                                                        <li>The Online Services also includes access to
                                                                            E.ON’s standard customer support services
                                                                            (including webchat) during the hours of
                                                                            08:00 to 17:00 on a Business Day. </li>
                                                                        <li>The Customer or its agent will provide E.ON
                                                                            with information, collaboration and if
                                                                            relevant the Equipment within reasonable
                                                                            timescales (as requested by E.ON) in order
                                                                            to set up the Online Services and test its
                                                                            operation for the Sites, Metering Points,
                                                                            Communication Equipment data and other Data
                                                                            (“Online Testing”).</li>
                                                                        <li>1.5 Optimum Connect & Control contains
                                                                            content made available by E.ON and/or third
                                                                            parties. All information delivered to the
                                                                            Customer in the course of using Optimum
                                                                            Connect & Control and all Intellectual
                                                                            Property Rights that exist in Optimum
                                                                            Connect & Control and the related
                                                                            information and materials are exclusively
                                                                            owned by E.ON and/or third parties.</li>

                                                                        <li>E.ON uses Strictly Necessary Cookies when
                                                                            providing the Online Services. No
                                                                            non-essential cookies are used within the
                                                                            Optimum Connect & Control platform.</li>
                                                                        <li>To monitor actions and changes made by Users
                                                                            throughout the Optimum Connect & Control
                                                                            platform, the system uses an Action Log to
                                                                            capture this information. The information
                                                                            that is collected includes the Users full
                                                                            name and the Site the action was carried
                                                                            out, along with details around the Data
                                                                            Point changed and a time stamp. This is the
                                                                            only example of where a User's personal
                                                                            information will be stored in the system and
                                                                            is essential to retain an audit log of
                                                                            actions undertaken.</li>
                                                                        <li>E.ON will provide the Online Services from
                                                                            the Online Services Start Date until
                                                                            cancelled in accordance with clause 8 of
                                                                            these Terms. </li>
                                                                    </ol>
                                                                </li>


                                                                <p><br></p>

                                                                <li>
                                                                    <strong>Pre-Conditions to the provision of Online
                                                                        Services</strong>
                                                                    <ol>
                                                                        <li>The following Online Services Pre-Conditions
                                                                            shall be met upon E.ON being satisfied of
                                                                            the following:

                                                                            <p>The Customer:</p>
                                                                            <ol>
                                                                                <li>confirming to E.ON in writing (and
                                                                                    this confirmation is a
                                                                                    representation and warranty) that
                                                                                    the pre-conditions listed in this
                                                                                    clause 2.1 are met with respect to
                                                                                    the provision by E.ON of the Online
                                                                                    Services; and
                                                                                </li>

                                                                                <li>ensuring that all Industry
                                                                                    Agreements that are relevant to the
                                                                                    provision of the Online Services and
                                                                                    in respect of each Site (and every
                                                                                    part of a Site) are in full force
                                                                                    and effect and the Customer (and/or
                                                                                    any owner or occupier of the Sites)
                                                                                    has complied with them in all
                                                                                    respects; and</li>
                                                                                <li>completing the installation of
                                                                                    BACnet capable BMS (or other
                                                                                    supported hardware / gateway
                                                                                    devices) at the Sites at which the
                                                                                    Online Services are due to be
                                                                                    provided; and</li>
                                                                                <li>completing any actions that E.ON
                                                                                    asks the Customer to complete in
                                                                                    advance of the Online Services Start
                                                                                    Date; and</li>
                                                                                <li>ensuring that the Equipment complies
                                                                                    with the Minimum IT Requirements;
                                                                                    and</li>
                                                                                <li>ensuring or procuring that all Third
                                                                                    Party Meters and any parts of the
                                                                                    Metering System or Equipment that
                                                                                    are not installed or maintained by
                                                                                    E.ON are certified (if required),
                                                                                    installed and maintained by
                                                                                    Accredited Personnel and fully
                                                                                    compliant with all relevant Industry
                                                                                    Agreements and Law to the extent the
                                                                                    Customer’s energy consumption and
                                                                                    Site require that each meter has a
                                                                                    Meter Identifier; and</li>
                                                                                <li>completing the Online Testing to
                                                                                    E.ON’s reasonable satisfaction
                                                                                    (“Online Go Live”); and</li>
                                                                                <li>confirming that the data accessible
                                                                                    to Optimum Connect & Control from
                                                                                    the BMS is complete and accurate
                                                                                </li>
                                                                                <li>ensuring that the BMS remains
                                                                                    accessible to E.ON via agreed
                                                                                    communication methods. Data loss due
                                                                                    to devices going offline and being
                                                                                    inaccessible to E.ON is the
                                                                                    responsibility of the Customer or
                                                                                    its agent.</li>
                                                                                <p>E.ON:</p>
                                                                                <li>holding any qualifications,
                                                                                    authorisations and consents needed
                                                                                    to provide the Online Services; and
                                                                                </li>
                                                                                <li>being able to support the provision
                                                                                    of the Online Services through the
                                                                                    Customer’s existing Communications
                                                                                    Equipment and E.ON shall not be
                                                                                    obliged to make any changes to them.
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Data Availability</strong></li>

                                                                <p>Unless otherwise agreed between the Parties in
                                                                    writing, E.ON shall use reasonable endeavours to
                                                                    make available the Data (as it is made available to
                                                                    E.ON) from the relevant Online Services Start Date
                                                                    for a period of up to three (3) years from the day
                                                                    to which such Data relates during the Online
                                                                    Services Period. </p>
                                                                <p><br></p>

                                                                <li><strong>Exclusions and Limitations</strong>
                                                                    <ol>
                                                                        <li>E.ON does not warrant that the Customer’s
                                                                            use of the Online Services will be
                                                                            uninterrupted or error-free and the Customer
                                                                            acknowledges and agrees that the Online
                                                                            Services may not be available as a result of
                                                                            planned or unplanned maintenance and testing
                                                                            or as a result of failures beyond E.ON’s
                                                                            reasonable control (which include failures
                                                                            or delays to communications and the system
                                                                            hosting the website). Where reasonably
                                                                            possible to do so, E.ON shall use reasonable
                                                                            endeavours to notify the Customer in advance
                                                                            of any reason why the Online Services may be
                                                                            interrupted or otherwise not working.</li>
                                                                        <li>E.ON accepts no liability and shall not be
                                                                            in breach of these Terms for any delay or
                                                                            non-performance due to any reason beyond
                                                                            it’s control including but not limited to:
                                                                            if there is a fault with the Communications
                                                                            Equipment or any part of the Metering
                                                                            System/BMS not caused by E.ON, if the Data
                                                                            received is inaccurate or if the delay or
                                                                            non-performance is caused by E.ON’s
                                                                            inability to communicate with Communications
                                                                            Equipment or receive Data for any Data Point
                                                                            or Site other than as a result of the fault
                                                                            of E.ON.</li>
                                                                        <li>Save to the extent of any obligations E.ON
                                                                            may have in respect of the Data:
                                                                            <ol class="letters">
                                                                                <li>the Data will be raw, unvalidated
                                                                                    data; and</li>
                                                                                <li>E.ON does not warrant that it will
                                                                                    be accurate, complete or up-to-date;
                                                                                    and</li>
                                                                                <li>E.ON shall have no responsibility
                                                                                    for:-
                                                                                    <p>(i) interpretation of the Data
                                                                                    </p>
                                                                                    <p>(ii) analysis or commentary on
                                                                                        the content of the reports
                                                                                        generated by the Online
                                                                                        Services;</p>
                                                                                    <p>(iii) the Customer’s or any other
                                                                                        person’s reliance on it; and</p>
                                                                                    <p>(iv) the Data or the Online
                                                                                        Services not meeting the
                                                                                        Customer’s expectations</p>
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>E.ON shall not be liable for any misuse of
                                                                            the Online Services by the Customer or its
                                                                            agent. The Customer warrants that it shall
                                                                            not rely or override the Online Services for
                                                                            the purposes of electrical isolation or
                                                                            similar as a substitute for good practice
                                                                            and physical isolation. E.ON accepts no
                                                                            liability to the Customer or a third party
                                                                            for a breach by the Customer, the Users or
                                                                            its agent of this clause and the Customer
                                                                            shall indemnify E.ON and keep E.ON fully and
                                                                            effectively indemnified against any and all
                                                                            costs, claims, losses, liabilities and
                                                                            expenses which E.ON may sustain or incur
                                                                            arising out of or in relation to a breach by
                                                                            the Customer of this clause. </li>
                                                                        <li>Subject to clause 4.7, E.ON's maximum
                                                                            aggregate liability under or in respect of
                                                                            these Terms, whether arising in contract,
                                                                            tort (including negligence) or otherwise,
                                                                            shall in no circumstances exceed £1,000.
                                                                        </li>
                                                                        <li>E.ON shall not be liable under these Terms
                                                                            for any loss of profit, loss of revenue,
                                                                            loss of margin, loss of use, loss of
                                                                            production, loss of opportunity, loss of
                                                                            contract, loss of goodwill, loss of
                                                                            business, anticipated savings or tax
                                                                            mitigation, costs of obtaining new financing
                                                                            or prepayment of debt, lost management time,
                                                                            loss of data or any indirect loss or
                                                                            consequential loss, howsoever arising from
                                                                            any reason whatsoever.</li>
                                                                        <li>Nothing in these Terms shall exclude or in
                                                                            any way limit (i) either Party's liability
                                                                            for death or personal injury caused by its
                                                                            own negligence, (ii) either Party's
                                                                            liability for fraud or fraudulent
                                                                            misrepresentation, or (iii) any other
                                                                            liability which cannot be excluded by Law.
                                                                        </li>
                                                                    </ol>
                                                                </li>

                                                                <li><strong>Restrictions on Use</strong>
                                                                    <ol>
                                                                        <li>The Customer shall not (and shall not allow
                                                                            or assist any other person) to:-
                                                                            <ol class="letters">
                                                                                <li>reproduce, redistribute, use, store
                                                                                    or download all or any part of the
                                                                                    Online Services or any associated
                                                                                    software or applications; or</li>
                                                                                <li>reverse compile, disassemble or
                                                                                    reverse engineer or otherwise all or
                                                                                    any part of the Online Services or
                                                                                    the software or hardware that
                                                                                    relates to it; or</li>
                                                                                <li>to observe, study or test the
                                                                                    functioning of the underlying
                                                                                    software (or any part of it) that is
                                                                                    used to provide Optimum Control &
                                                                                    Connect, except and only to the
                                                                                    extent that such restriction is
                                                                                    prohibited pursuant to Applicable
                                                                                    Law; or</li>
                                                                                <li>unless explicitly agreed in the
                                                                                    commercial arrangement between
                                                                                    Parties, transfer all or any part of
                                                                                    the Online Services or the data
                                                                                    obtained from them to any third
                                                                                    party; or</li>
                                                                                <li>Unless explicitly agreed in writing
                                                                                    between the Parties use or make
                                                                                    available the Online Services to
                                                                                    provide services to third parties
                                                                                    whether for commercial gain or
                                                                                    otherwise, or to build a product or
                                                                                    service which competes with the
                                                                                    Online Services; or</li>
                                                                                <li>use the Online Service in any manner
                                                                                    that could interfere with E.ON or
                                                                                    any other person's use and enjoyment
                                                                                    of any services E.ON provides to
                                                                                    others.</li>
                                                                                <li>and shall procure that Users shall
                                                                                    not, introduce any software virus or
                                                                                    other malware (including any bugs,
                                                                                    worms, logic bombs, trojan horses or
                                                                                    any other self-propagating or other
                                                                                    such program) that may infect or
                                                                                    cause damage to Optimum Connect &
                                                                                    Control or E.ON’s systems or
                                                                                    otherwise disrupt the provision of
                                                                                    Optimum Connect & Control.</li>
                                                                                <li>not share with any third party their
                                                                                    password or access details provided
                                                                                    to facilitate access to the Online
                                                                                    Service. The Customer warrants and
                                                                                    represents that it shall, and ensure
                                                                                    that Users shall, keep confidential
                                                                                    such password or access details, The
                                                                                    Customer is responsible for
                                                                                    maintaining the confidentiality of
                                                                                    its passwords and usernames, and
                                                                                    shall be liable for all activities
                                                                                    that occur under its Optimum
                                                                                    account. The Customer shall contact
                                                                                    E.ON if updates to any list of Users
                                                                                    given to E.ON are required,
                                                                                    including when Users cease to be
                                                                                    employed or engaged by the Customer.
                                                                                </li>
                                                                            </ol>
                                                                        </li>

                                                                        <li>The Customer may transfer data obtained
                                                                            through its use of the Online Services to
                                                                            its professional advisors provided always
                                                                            that that transfer is:
                                                                            <ol class="letters">
                                                                                <li>for the purposes of obtaining advice
                                                                                    or assistance from that person in
                                                                                    relation to the Customer’s use of
                                                                                    Energy; and</li>
                                                                                <li>only of that part of the data that
                                                                                    is necessary to obtain that advice
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>In addition to E.ON’s rights and remedies
                                                                            under Applicable Law, in the event of any
                                                                            breach of Authorised Use, by the Customer
                                                                            and/or the Users that the Customer:
                                                                            <ol class="letters">
                                                                                <li>(a) becomes aware of, the Customer
                                                                                    shall promptly notify E.ON and ask
                                                                                    for E.ON’s consent for that
                                                                                    Unauthorised Use. E.ON may at its
                                                                                    absolute discretion permit the
                                                                                    Unauthorised Use and if E.ON does
                                                                                    permit it, to the extent that E.ON
                                                                                    permits it, E.ON may do so as
                                                                                    Additional Services for Additional
                                                                                    Charges: or
                                                                                </li>
                                                                                <li>(b) either does not become aware of
                                                                                    when the Customer (acting as a
                                                                                    reasonable economic operator) should
                                                                                    have become aware of or that the
                                                                                    Customer becomes aware of but does
                                                                                    not notify E.ON or ask for E.ON’s
                                                                                    consent for such Unauthorised Use
                                                                                    and E.ON discovers it: E.ON will
                                                                                    assume each Unauthorised Use is an
                                                                                    Additional Service and E.ON shall
                                                                                    charge the Customer Additional
                                                                                    Charges.</li>
                                                                            </ol>
                                                                        </li>

                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Changes</strong>
                                                                    <ol>
                                                                        <li>E.ON may upgrade, modify, replace or
                                                                            reconfigure or change the way it provides
                                                                            the Online Services (including for example
                                                                            changing software, platforms or other
                                                                            systems which E.ON use to supply the Online
                                                                            Services) or E.ON may change the Minimum IT
                                                                            Requirements (in each case an (“Online
                                                                            Services Upgrade”) at any time provided
                                                                            always that E.ON shall use reasonable
                                                                            endeavours to mitigate the impact on the
                                                                            Customer or the Customer’s use of the Online
                                                                            Services.</li>
                                                                        <li>In the event any such Online Services
                                                                            Upgrade may impact the way that the Customer
                                                                            or the Users use or interface with the
                                                                            Online Services then E.ON shall notify the
                                                                            Customer or its agent in a timely manner of
                                                                            the expected nature and extent of such
                                                                            impact, where possible to do so.</li>
                                                                        <li>In the event that the Customer wishes to
                                                                            change the functionality of the Online
                                                                            Services, the Customer shall notify E.ON of
                                                                            the nature, type and extent of change that
                                                                            the Customer seeks E.ON to make. In the
                                                                            event that E.ON considers that it is able to
                                                                            accommodate such a change, for example via
                                                                            an Online Services Upgrade, then the Parties
                                                                            shall seek to agree appropriate amendments
                                                                            in writing. In the event no written
                                                                            agreement can be reached or E.ON does not
                                                                            send to the Customer an amendment in
                                                                            writing, no change to the Online Services
                                                                            shall be made and these Terms shall continue
                                                                            to apply in full.</li>
                                                                        <li>E.ON shall maintain a Change Log of all
                                                                            relevant modifications, upgrades or
                                                                            alterations made to the Optimum Connect &
                                                                            Control platform.</li>
                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Failure to Comply</strong>
                                                                    <ol>
                                                                        <li>Where E.ON believes (acting reasonably)
                                                                            that:-
                                                                            <ol>
                                                                                <li>The Customer has failed to or has
                                                                                    ceased to comply with obligations
                                                                                    under or any requirement of these
                                                                                    Terms including without limitation
                                                                                    the Online Services Pre-Conditions;
                                                                                    or</li>
                                                                                <li>The Customer (or the Customer’s
                                                                                    agent) act or omission or the act or
                                                                                    omission of any third party prevents
                                                                                    or impedes E.ON’s ability to provide
                                                                                    the Online Services; </li>
                                                                                <li>A sum due and payable to E.ON for
                                                                                    the Online Services is in arrears
                                                                                    for more than 5 Business Days after
                                                                                    the due date for payment; and/or
                                                                                </li>
                                                                                <li>The Customer fails to secure data
                                                                                    flow from the Metering System or
                                                                                    Communication Equipment to the
                                                                                    Online Services </li>
                                                                                <li>The Customer fails to send reliable,
                                                                                    timely and/or accurate data to the
                                                                                    Optimum Connect & Control platform
                                                                                    it shall in each case be a failure
                                                                                    to comply (a “Failure to Comply”).
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>Where there has been a Failure to Comply,
                                                                            E.ON:<ol>
                                                                                <li>has no obligation to provide the
                                                                                    Online Services or to perform
                                                                                    identified Additional Services;
                                                                                    and/or</li>
                                                                                <li>may suspend or cease to carry out
                                                                                    the Online Services impacted by the
                                                                                    Failure to Comply (and any other
                                                                                    Additional Services dependent on
                                                                                    them) until such time as the
                                                                                    Customer has remedied the
                                                                                    circumstances giving rise to the
                                                                                    suspension and complied with any
                                                                                    other requirements E.ON may
                                                                                    reasonably require; and/or</li>
                                                                                <li>may continue to charge for the
                                                                                    Online Services suspended or ceased;
                                                                                    and/or</li>
                                                                                <li>may recover as a debt (and charge as
                                                                                    Additional Charges) the costs,
                                                                                    losses and expenses it incurs
                                                                                    (including any costs and expenses
                                                                                    E.ON incurs and any other person).
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                        <li>The Customer will indemnify E.ON for all
                                                                            losses, claims and expenses E.ON incurs
                                                                            because of any Failure to Comply or by
                                                                            reason of any wrongful act or omission by
                                                                            the Customer or the Users.</li>
                                                                        <li>Where there has been a Failure to Comply
                                                                            and:-<ol>
                                                                                <li>The Customer fails to remedy a
                                                                                    failure or a delay and/or fails to
                                                                                    comply with E.ON’s requirements in
                                                                                    relation to them within a reasonable
                                                                                    period of time as determined by E.ON
                                                                                    acting reasonably; and/or</li>
                                                                                <li>The Customer fails to agree or to
                                                                                    pay the Additional Charges; and/or
                                                                                </li>
                                                                                <li>E.ON has cause to be delayed or to
                                                                                    cease or suspend its provision of
                                                                                    the Online Services to the Customer
                                                                                    on multiple occasions and/or to the
                                                                                    extent that E.ON are substantially
                                                                                    unable to perform any of the Online
                                                                                    Services,
                                                                                    then in each case, in addition to
                                                                                    any other rights and remedies E.ON,
                                                                                    it may invoice the Customer for and
                                                                                    the Customer shall be obliged to pay
                                                                                    the Online Services Charge for any
                                                                                    Online Services that E.ON is unable
                                                                                    to commence or continue; and, E.ON
                                                                                    shall be entitled to cancel the
                                                                                    Online Services by notice in writing
                                                                                    to the Customer. Such cancellation
                                                                                    shall take effect on the date set
                                                                                    out in the notice provided by E.ON.
                                                                                </li>
                                                                            </ol>
                                                                        </li>
                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Cancellation of the Online Services</strong>
                                                                    <ol>
                                                                        <li>The Online Services may be cancelled by
                                                                            either Party in accordance with this clause
                                                                            8 (Failure to Comply). </li>
                                                                        <li>Unless otherwise agreed between the Parties,
                                                                            the Online Services may be cancelled by
                                                                            either Party subject to a period of 6 months
                                                                            prior written notice to the other.</li>
                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Payment of Charges</strong>
                                                                    <ol>
                                                                        <li>E.ON shall include the Online Services
                                                                            Charge within the invoices provided to the
                                                                            Customer. Invoices shall be paid by the
                                                                            Customer within 30 days of receipt.</li>
                                                                    </ol>
                                                                </li>

                                                                <p><br></p>

                                                                <li><strong>Glossary</strong></li>
                                                            </ol>
                                                            <p>The following shall have the meanings set out below when
                                                                used in these Terms:</p>
                                                            <p><br></p>
                                                            <table cellpadding="0" cellspacing="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Accredited Personnel:</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>persons that have completed all mandatory
                                                                                training courses required to operate in
                                                                                a legally compliant manner.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Action Log:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>also known as an audit log, is a record
                                                                                of events and changes made to parameters
                                                                                undertaken by Users of the Online
                                                                                Services. Typically this will record (a)
                                                                                who performed that activity (b) what
                                                                                activity was performed (c) what time was
                                                                                the activity performed and (d) how the
                                                                                system responded to the activity.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Additional Charges</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>either&nbsp;</p>
                                                                            <p>(a) any charges relating to the Online
                                                                                Services under these Terms other than
                                                                                the Online Services Charge; </p>
                                                                            <p>b) charges for Additional Services,&nbsp;
                                                                            </p>
                                                                            <p>in each case the charges shall be the
                                                                                amount that either are agreed in advance
                                                                                or that E.ON reasonably determines are
                                                                                current and applicable for the work and
                                                                                materials performed</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Additional Services</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>services which are not included as part
                                                                                of the Online Services which may be
                                                                                provided by E.ON in accordance with
                                                                                these Terms </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Affiliates:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>in relation to either Party, any
                                                                                "subsidiary", any "holding company", any
                                                                                subsidiary of such holding company, any
                                                                                "group undertaking", any "subsidiary
                                                                                undertaking", or any "parent
                                                                                undertaking" with each phrase having the
                                                                                same meaning as their respective
                                                                                definitions in sections 1159 to 1162
                                                                                inclusive of the Companies Act 2006 as
                                                                                amended provided always that for the
                                                                                purposes of determining if an entity is
                                                                                a subsidiary undertaking within Section
                                                                                1162 the existence of any security over
                                                                                any shares in an entity which would
                                                                                otherwise be a subsidiary undertaking
                                                                                shall be ignored.
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Applicable Law:</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>any law applicable in England and shall
                                                                                include common law, statute, statutory
                                                                                instrument, proclamation, by-law,
                                                                                directive, decision, regulation, rule,
                                                                                order, notice, code of practice, code of
                                                                                conduct, rule of court, instrument, or
                                                                                delegated or subordinate legislation
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Asset:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the asset(s) owned by the Customer and connected to the Optimum Connect & Control platform</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Authorised Use:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>use of the Online Services in accordance with these Terms
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>BMS:</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>building management system to control and manage electrical services in a building
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Business Day:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>a day (other than a Saturday, Sunday or public holiday) when banks in London are generally open for non-automated business</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Change Log:</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>an itemised list of all new  features, developments and modifications made to the Online Services by E.ON development teams
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Communications Equipment:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the technology, data communication lines, network connections and telecommunications links between the Customer’s Meter, Asset or BMS and  E.ON’s data centres that enables the remote retrieval of data from a meter in each case pursuant to minimum requirements and specification(s) prescribed by E.ON and/or by Industry Agreements (as notified to the Customer from time to time)</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Customer:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the entity procuring or in receipt of the Online Services and its Affiliates</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Data:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>any data collected by a metering device at a Metering Point
                                                                            </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Data Point:</strong>
                                                                            </p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>a measured value brought into the system from a hardware device.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>E.ON:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>E.ON UK Heat Limited and/or its Affiliates
                                                                                &nbsp; &nbsp;</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Equipment:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>any relevant equipment or telecommunications links and interfaces needed by E.ON to provide the Online Services</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Industry Agreements:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>an agreement that is necessary with a third party for the provision of metering data or data transfer to E.ON in order for us to fulfil the Online Services</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Intellectual Property Rights:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>all intellectual property rights (including copyrights, trademarks and patents), proprietary rights (including trade secrets and know-how), and moral rights (including rights of authorship and modification) throughout the world</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Meter Identifier:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>a unique meter reference number that can be used to identify the specific meter or piece of metering equipment.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Metering Point:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the name given to each point at which data is measured. Can refer to a physical meter, sensor or any piece of Communications Equipment transmitting data to E.ON.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Metering System:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the arrangement of metering and/or monitoring equipment on a customer’s premises</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Minimum IT Requirements:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>Internet browsers: Optimum is supported by the 2 most recent versions of the following browsers, but for the best user experience, we do recommend keeping your preferred browser up to date: </p>
                                                                            <ul>
                                                                                <li>Microsoft Edge</li>
                                                                                <li>Google Chrome</li>
                                                                                <li>Firefox</li>
                                                                                <li>Safari</li>
                                                                            </ul>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Services:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the provision of access to the configuration of the on-line services that the Customer has selected as may be amended or varied from time to time by E.ON and as more particularly detailed in clause 1. </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Services Charge:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>shall be invoiced to the Customer in accordance with the terms of their commercial arrangements </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Services Pre-Conditions:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>shall be as defined in paragraph 2.1</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Services Start Date:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>shall be the date on which the Parties satisfy all the Online Services Pre-Conditions</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Services Upgrade:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>shall be as defined in paragraph 6.1</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Online Testing:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>shall be as defined in paragraph 1.4</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Optimum Connect & Control:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the platform offered by E.ON to provide the Online Services </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Party/Parties:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>E.ON and the Customer (separately a Party and together the Parties)</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Sites:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the buildings or premises occupied or monitored by the Customer or its agent.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Strictly Necessary Cookies:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>cookies which are necessary for the Optimum Control and Connect platform and/or Online Services to function and cannot be switched off in E.ON systems.  The Cookies are usually only set in response to the Customer’s actions which amount to a request for services, such as setting privacy preferences, logging in or filling in forms.  The Customer can set its browser to block or alert it about these cookies but this may impact parts of the Online Services.  These cookies do not store any personally identifiable information.  </p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Terms:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>These Optimum Connect & Control Terms of Use: Online Services</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Third Party Meters:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>metering equipment that E.ON is not legally or commercially responsible for or obliged by law to maintain.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Unauthorised Use:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>the use of the Online Services contrary to these Terms in part or in whole</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td valign="top">
                                                                            <p><strong>Users:</strong></p>
                                                                        </td>
                                                                        <td valign="top">
                                                                            <p>means and includes the Customer, the Customer’s employees or service providers or agents or any third party/person accessing the Online Services on the Customer’s behalf.</p>
                                                                            <p><br></p>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="accept-terms-wrapper" *ngIf="!hasAcceptedTerms">
                                        <div class="terms-footer flex-none">
                                            <p>Once having accepted you will be led to your personal E.ON Optimum
                                                dashboard.</p>

                                            <p class="emphasized">I have read and accept the
                                                Terms of Use.</p>
                                        </div>
                                        <div class="terms-action flex-none"><button type="button" (click)="acceptOnTermsOfUse()"
                                                class="eon-button bg-eon-red eon-white">
                                                Accept and Continue </button>
                                        </div>
                                    </div>
                                    <!---->
                                </div>
                                <!---->
                                <!---->
                            </div>
                            <!---->
                        </div>
                        <!---->
                    </div>
                    <!---->
                    <!---->
                </div>
                <!---->
            </div>
        </div>
    </div>
</div>