<div class="confirmation-container">
    <p class="msg" >Please confirm that you want to change the status to <b>CLOSED</b>. This Alert will be removed from this section, hence the total number count in summary card is decreased by 1.</p>
    <div class="footer">
        <button class="common-btn button-turquoise-bg h-2rem no-border" (click)="handleConfirmation()">
            Confirm
        </button>
        <button class="common-btn button-gray-bg h-2rem no-border" (click)="onCancel()">
            Cancel
        </button>
    </div>
</div>
