import {Component, Input} from '@angular/core';
import {SmartAlert} from "../../models/dashboard";
import {ButtonDirective} from "primeng/button";

@Component({
    selector: 'cc-smart-alerts',
    standalone: true,
    imports: [
        ButtonDirective
    ],
    templateUrl: './smart-alerts.component.html',
    styleUrl: './smart-alerts.component.scss'
})
export class SmartAlertsComponent {

    @Input()
    alerts: SmartAlert[] = [];

    get alertsToRender() {
        return this.alerts.filter(alert => ['OPEN', 'IN_PROGRESS'].includes(alert.status));
    }

}
