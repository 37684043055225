<table>
    <tr *ngFor="let day of days">
        <ng-container>
            <td>{{ day | titlecase }}</td>
            <td>
                <p-inputSwitch
                    class="p-inputswitch"
                    [(ngModel)]="siteSchedule[day].isOpen"
                    (onChange)="handleTimeSwitchOff(day)"
                ></p-inputSwitch>
            </td>
            <td class="time">
                <span>
                    <p-calendar
                        [placeholder]="defaultOpenTime"
                        [styleClass]="'full-width'"
                        [class]="
                            siteSchedule[day]?.error?.openTimeErr
                                ? 'time-error'
                                : ''
                        "
                        [timeOnly]="true"
                        hourFormat="24"
                        [(ngModel)]="siteSchedule[day]['openTime']"
                        [disabled]="!siteSchedule[day].isOpen"
                        [stepMinute]="15"
                        (ngModelChange)="isValidTime(day, 'openTime')"
                    >
                    </p-calendar>
                    <label
                        [class]="
                            siteSchedule[day]?.error?.openTimeErr
                                ? 'text-error'
                                : 'hidden-text'
                        "
                        ><br />{{
                            this.siteSchedule[day]?.error?.openTimeErr
                        }}</label
                    >
                </span>
                <span>
                    <p-calendar
                        [placeholder]="defaultCloseTime"
                        [styleClass]="'full-width'"
                        [class]="
                            siteSchedule[day]?.error?.closeTimeErr
                                ? 'time-error'
                                : ''
                        "
                        [timeOnly]="true"
                        hourFormat="24"
                        [(ngModel)]="siteSchedule[day]['closeTime']"
                        [disabled]="!siteSchedule[day].isOpen"
                        [stepMinute]="15"
                        (ngModelChange)="isValidTime(day, 'closeTime')"
                    >
                    </p-calendar>
                    <label
                        [class]="
                            siteSchedule[day]?.error?.closeTimeErr
                                ? 'text-error'
                                : 'hidden-text'
                        "
                        ><br />{{
                            this.siteSchedule[day]?.error?.closeTimeErr
                        }}</label
                    >
                </span>
            </td>
        </ng-container>
    </tr>
</table>
<div class="footer">
    <button
        [class]="
            isValidData
                ? 'common-btn button-turquoise-bg h-2rem no-border'
                : 'common-btn button-turquoise-bg h-2rem no-border button-disabled'
        "
        (click)="onSave()"
        [disabled]="!isValidData"
    >
        Save
    </button>
    <button
        class="common-btn button-gray-bg h-2rem no-border"
        (click)="onCancel()"
    >
        Cancel
    </button>
</div>
