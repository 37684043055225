<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your equipment</h1>
        <div
            class="flex align-items-center justify-content-between sub-navigation"
        >
            <div class="grouped-buttons">
                <button
                    class="grouped-button-left button-gray-bg selected"
                    [routerLink]="['/equipments']"
                >
                    All <span>16</span>
                </button>
                <button
                    class="grouped-button-right button-gray-bg"
                    [routerLink]="['/equipments/pinned']"
                >
                    Pinned <span>0</span>
                </button>
            </div>

            <div class="flex">
                <div class="desktop-navigation">
                    <span class="w-full margin-right-26 custom-mm">
                        <p-multiSelect
                            inputId="eq-filter-select"
                            [options]=""
                            placeholder="Location Filter"
                        >
                        </p-multiSelect>
                    </span>
                    <span class="w-full margin-right-26 custom-mm">
                        <p-multiSelect
                            inputId="eq-filter-select"
                            [options]=""
                            placeholder="All Equipment types"
                        >
                        </p-multiSelect>
                    </span>

                    <div class="search-group">
                        <button type="button" class="search">
                            <img
                                src="assets/svg/search.svg"
                                width="20"
                                height="25"
                            />
                        </button>
                        <input
                            type="text"
                            pInputText
                            placeholder="Search by name..."
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="main-wrapper">
    <div class="container">
        <div class="grid">
            <div class="col-12 xl:col-4">
                <div class="entity-card">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img
                                _ngcontent-ng-c2967956265=""
                                width="48"
                                height="48"
                                src="assets/svg/Lighting.svg"
                            />
                        </div>
                        <div class="entity-details">
                            <h2 class="entity-name">Lighting, Fire & Security</h2>
                            <p class="entity-class">Lighting</p>
                        </div>
                        <div class="info-bullet info-bullet__red">
                            <span>2</span>
                        </div>
                        <eon-ui-icon
                            name="bookmark_outline"
                            class="top-right-position cursor-pointer hydrated"
                            aria-label="bookmark_outline"
                        ></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div
                            class="entity-kpi flex align-items-center justify-content-around"
                        >
                            <span class="margin-right-05">33% Lighting Enabled</span
                            >
                            <p-inputSwitch styleClass="default-switch__black" [disabled]="true"></p-inputSwitch>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="entity-card" [routerLink]="['/equipment-details']">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img
                                _ngcontent-ng-c2967956265=""
                                width="48"
                                height="48"
                                src="assets/svg/Fan.svg"
                            />
                        </div>
                        <div class="entity-details">
                            <h2 class="entity-name">Sales Air Handling Unit</h2>
                            <p class="entity-class">Air Handling Unit</p>
                        </div>
                        <div class="info-bullet info-bullet__orange">
                            <span>2</span>
                        </div>
                        <eon-ui-icon
                            name="bookmark_outline"
                            class="top-right-position cursor-pointer hydrated"
                            aria-label="bookmark_outline"
                        ></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div
                            class="entity-kpi flex align-items-center justify-content-around"
                        >
                            <span class="margin-right-05">Sales AHU 1 Inlet Air Temperature</span
                            >
                            <span class="kpi-value ng-star-inserted"> 17.66 <span style="font-size: 1rem;">°C</span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 xl:col-4">
                <div class="entity-card">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img
                                _ngcontent-ng-c2967956265=""
                                width="48"
                                height="48"
                                src="assets/svg/Computer_Room_Air_Conditioning.svg"
                            />
                        </div>
                        <div class="entity-details">
                            <h2 class="entity-name">Basement Sales</h2>
                            <p class="entity-class">Air Handling Unit</p>
                        </div>
                        <div class="info-bullet info-bullet__green">
                            <span>0</span>
                        </div>
                        <eon-ui-icon
                            name="bookmark_outline"
                            class="top-right-position cursor-pointer hydrated"
                            aria-label="bookmark_outline"
                        ></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div
                            class="entity-kpi flex align-items-center justify-content-around"
                        >
                            <span class="margin-right-05">Basement Sales Space Temperature</span
                            >
                            <span class="kpi-value ng-star-inserted"> 18.98 <span style="font-size: 1rem;">°C</span></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

