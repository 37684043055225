import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

@Injectable()
export class SvgService {
    constructor(iconRegistry: MatIconRegistry, sanitizer: DomSanitizer) {
        // iconRegistry.addSvgIcon('home', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/home.svg'));
        // iconRegistry.addSvgIcon('gateways', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/gateways_black.svg'));
        // iconRegistry.addSvgIcon('devices', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/devices.svg'));
        // iconRegistry.addSvgIcon('datapoints', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/datapoints.svg'));
        // iconRegistry.addSvgIcon('search', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/search.svg'));
        // iconRegistry.addSvgIcon('edit', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/edit.svg'));
        // iconRegistry.addSvgIcon('user', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/user.svg'));
        // iconRegistry.addSvgIcon('language', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/language.svg'));
        // iconRegistry.addSvgIcon('eon', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/logo-red.svg'));
        iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/add.svg'));
        // iconRegistry.addSvgIcon('delete', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));
        // iconRegistry.addSvgIcon('error', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/error.svg'));
        // iconRegistry.addSvgIcon('warning', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/warning.svg'));
        // iconRegistry.addSvgIcon('scan', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/scan.svg'));
        // iconRegistry.addSvgIcon('alarm', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/alarm.svg'));
        // iconRegistry.addSvgIcon('offline', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/offline.svg'));
        // iconRegistry.addSvgIcon('question', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/question.svg'));
        // iconRegistry.addSvgIcon('chart', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/chart.svg'));
        // iconRegistry.addSvgIcon('control', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/control.svg'));
        // iconRegistry.addSvgIcon('plus', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus.svg'));
        // iconRegistry.addSvgIcon('add', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/plus_in_circle.svg'));
        // iconRegistry.addSvgIcon('minus', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/minus.svg'));
        // iconRegistry.addSvgIcon('equal', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/equal.svg'));
        // iconRegistry.addSvgIcon('reset', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/reset.svg'));
        // iconRegistry.addSvgIcon('success', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/success.svg'));
        // iconRegistry.addSvgIcon('failure', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/failure.svg'));
        // iconRegistry.addSvgIcon('bar_chart', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bar_chart.svg'));
        // iconRegistry.addSvgIcon('timeline', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/timeline.svg'));
        // iconRegistry.addSvgIcon('wifi_on', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/wifi.svg'));
        // iconRegistry.addSvgIcon('wifi_off', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/wifi_off.svg'));
        // iconRegistry.addSvgIcon('input', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/input.svg'));
        // iconRegistry.addSvgIcon('swap', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/swap_horiz.svg'));
        // iconRegistry.addSvgIcon('tags', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/local_offer.svg'));
        // iconRegistry.addSvgIcon('description', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/description.svg'));
        // iconRegistry.addSvgIcon('watch', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/youtube_searched_for.svg'));
        // iconRegistry.addSvgIcon('maintenance', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/build.svg'));
        // iconRegistry.addSvgIcon('filter', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/filter_list.svg'));
        // iconRegistry.addSvgIcon('clear', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clear.svg'));
        // iconRegistry.addSvgIcon('schedule', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/schedule.svg'));
        // iconRegistry.addSvgIcon('twin_schedule', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/twin_schedule.svg'));
        // iconRegistry.addSvgIcon('clone', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clone.svg'));
        // iconRegistry.addSvgIcon('copyTo', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/file_copy.svg'));
        // iconRegistry.addSvgIcon('cloud', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cloud_queue.svg'));
        // iconRegistry.addSvgIcon('disabled', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/remove_circle_outline.svg'));
        // iconRegistry.addSvgIcon('visibility_on', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility-24px.svg'));
        // iconRegistry.addSvgIcon('visibility_off', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/visibility_off-24px.svg'));
        // iconRegistry.addSvgIcon('select_hand', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/select-hand.svg'));
        // iconRegistry.addSvgIcon('exception', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/exception.svg'));
        // iconRegistry.addSvgIcon('done', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/done-24px.svg'));
        // iconRegistry.addSvgIcon('sites', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/location_city-24px.svg'));
        // iconRegistry.addSvgIcon('compare', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/compare_arrows-black.svg'));
        // iconRegistry.addSvgIcon('arrow_up', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_circle_up.svg'));
        // iconRegistry.addSvgIcon('arrow_down', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/arrow_circle_down.svg'));
        // iconRegistry.addSvgIcon('menu_book', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/menu_book2.svg'));
        // iconRegistry.addSvgIcon('developer_board', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/developer_board-24px.svg'));
        // iconRegistry.addSvgIcon('show_selected', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/show_selected.svg'));
        // iconRegistry.addSvgIcon('checked', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/checked_icon.svg'));
        // iconRegistry.addSvgIcon('unchecked', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/unchecked_icon.svg'));

        // iconRegistry.addSvgIcon('lighting', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/bulb.svg'));
        // iconRegistry.addSvgIcon('air', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cooling-symbol.svg'));
        // iconRegistry.addSvgIcon('domestic_water', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hand-wash.svg'));
        // iconRegistry.addSvgIcon('hot_water', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fire.svg'));
        // iconRegistry.addSvgIcon('chiller', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/snowflake.svg'));
        // iconRegistry.addSvgIcon('pool', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pool.svg'));
        // iconRegistry.addSvgIcon('settings', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/settings.svg'));
        // iconRegistry.addSvgIcon('door', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/door.svg'));
        // iconRegistry.addSvgIcon('simple-door', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/door-icon.svg'));
        // iconRegistry.addSvgIcon('double-arrow', sanitizer.bypassSecurityTrustResourceUrl('assets/icons/double-arrow.svg'));

        iconRegistry.addSvgIcon('generic_ac', 'assets/svg/Fan.svg');
    }
}
