import {Injectable} from '@angular/core';
import {BackendService} from './backend/backend.service';
import {OAuthService} from 'angular-oauth2-oidc';
import {Router} from '@angular/router';
import {CookieService} from "ngx-cookie-service";
import {LOGIN_IDP} from "../config/constants";
import {interval, Observable, take} from "rxjs";
import {filter, map} from "rxjs/operators";

export const ROLE_PERMISSIONS = {
    DASHBOARDS_APP: 'DASHBOARDS_APP',

    EDIT_CUSTOMER_SPEC_BUTTON_DASHBOARDS_APP: 'EDIT_CUSTOMER_SPEC_BUTTON.DASHBOARDS_APP',
};

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    public userRolePermissionList: string;

    constructor(private backendService: BackendService,
                private authService: OAuthService,
                private router: Router,
                private cookieService: CookieService,
    ) {
    }

    hasPermission(permission: string): boolean {
        if (
            this.userRolePermissionList == null ||
            this.userRolePermissionList.length == 0
        ) {
            return false;
        }
        return this.userRolePermissionList.includes(permission);
    }

    init(): void {
        this.backendService
            .getUserRolePermissionList()
            .subscribe((response: any) => {
                localStorage.setItem(
                    'userRolePermissionList',
                    response.userRolePermissionList
                );
                this.userRolePermissionList = response.userRolePermissionList;

                if (this.authService.hasValidIdToken() && !this.userRolePermissionList.includes(ROLE_PERMISSIONS.DASHBOARDS_APP)) {
                    this.router.navigateByUrl('/no-access');
                }
            });
    }

    /**
     * Returns the username of the currently logged in user.
     * not safe - can return null or undefined when auth flow is not yet completed
     */
    getUsername(): string {
        const claims = this.authService.getIdentityClaims();
        return claims['email'] || claims['preferred_username'] || '';
    }

    /**
     * Returns an observable that emits the username when it is available.
     * prevents the null/undefined username issue
     */
    getUsernameObservable(): Observable<string> {
        return interval(100).pipe(
            map(() => this.authService.getIdentityClaims()),
            filter(claims => claims !== null),
            take(1),
            map(claims => claims['email'] || claims['preferred_username'] || '')
        );
    }

    logout(): void {
        sessionStorage.removeItem('userRolePermissionList');
        this.clearLoginProviderCookie();
        this.authService.logOut();
    }

    clearLoginProviderCookie(): void {
        this.cookieService.delete(LOGIN_IDP, '/', `.${window.location.hostname.replace('dashboard.', '')}`);
    }
}
