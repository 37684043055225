<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your collections</h1>
        <div
            class="flex align-items-center justify-content-between sub-navigation"
        >
            <div class="grouped-buttons">
                <button
                    class="grouped-button-left button-gray-bg selected"
                    [routerLink]="['/collections']"
                >
                    All <span>{{ collections.length }}</span>
                </button>
                <button class="grouped-button-right button-gray-bg" [routerLink]="['/collections/pinned']">Pinned <span>{{noOfPinnedCollections}}</span></button>
            </div>
            <cc-filter-and-search
                [dataToFilter]="collections"
                [filterOptions]="collectionFiltersOptions"
                [category]="category"
                (onFilterChanged)="onFilterChangedHandler($event)"
            ></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="collections.length">
            <div
                class="col-12 xl:col-4"
                *ngFor="let collectionCard of collections"
            >
                <div
                    class="entity-card"
                    [routerLink]="['/collections', collectionCard.id]"
                >
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img
                                src="assets/svg/{{
                                    collectionCard.classIcon
                                }}.svg"
                                width="48"
                                height="48"
                            />
                        </div>
                        <div class="entity-details">
                            <h2
                                class="entity-name"
                                [pTooltip]="collectionCard.label"
                                [tooltipDisabled]="
                                    collectionCard.label &&
                                    collectionCard.label.length <= 30
                                "
                                tooltipPosition="top"
                                [hideDelay]="100"
                            >
                                {{ collectionCard.label }}
                            </h2>
                            <p class="entity-class">
                                {{ collectionCard.classLabel }}
                            </p>
                        </div>
                        <eon-ui-icon *ngIf="collectionCard.pinned" class="top-right-position cursor-pointer" name="bookmark"
                            (click)="togglePin($event, collectionCard)"></eon-ui-icon>
                        <eon-ui-icon *ngIf="!collectionCard.pinned" class="top-right-position cursor-pointer" name="bookmark_outline"
                            (click)="togglePin($event, collectionCard)"></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div
                            class="entity-kpi flex align-items-center justify-content-around"
                            *ngIf="collectionCard.selectedKpi?.signalType"
                        >
                            <ng-container
                                [ngSwitch]="
                                    collectionCard.selectedKpi.signalType
                                "
                            >
                                <ng-container *ngSwitchCase="'Binary'">
                                    <span class="margin-right-05">{{
                                        collectionCard.selectedKpi.comment ||
                                            collectionCard.selectedKpi
                                                .classLabel
                                    }}</span>
                                    <p-inputSwitch
                                        styleClass="default-switch__black"
                                        [disabled]="true"
                                        [(ngModel)]="
                                            collectionCard.selectedKpi
                                                .presentValue
                                        "
                                    ></p-inputSwitch>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span class="margin-right-05">{{
                                        collectionCard.selectedKpi.comment ||
                                            collectionCard.selectedKpi
                                                .classLabel
                                    }}</span>
                                    <span class="kpi-value">
                                        {{
                                            collectionCard.selectedKpi
                                                .presentValue
                                        }}
                                        <span
                                            style="font-size: 1rem"
                                            [innerHTML]="
                                                collectionCard.selectedKpi
                                                    .htmlSymbol
                                            "
                                        ></span>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid" *ngIf="!collections.length">
            <div class="col-12">
                <h2>No Collections found</h2>
            </div>
        </div>
    </div>

    <router-outlet></router-outlet>
</div>
