import { PipeTransform, Pipe } from "@angular/core";
import { AlertStatusCode, SmartAlertStatusLabel } from "../models/core/alert-rule.model";


@Pipe({ name: "smartAlertCode" })
export class SmartAlertCodePipe implements PipeTransform {
    transform(statusLabel) {
        switch (statusLabel) {
            case SmartAlertStatusLabel.OPEN:
                return AlertStatusCode.OPEN;
            case SmartAlertStatusLabel.CLOSED:
                return AlertStatusCode.CLOSED;
            case SmartAlertStatusLabel.IN_PROGRESS:
                return AlertStatusCode["IN PROGRESS"];
            default:
                return `Unknown alert status ${statusLabel}`;
        }
    }
}
