import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Site } from '../../models/site';
import { HttpBaseService } from '../http/http-base.service';
import { Customer } from 'src/app/models/customer';
import { Equipment, Point, Collection, CollectionDetails, LocationDetails, EquipmentV2, EquipmentDetails } from 'src/app/models/dashboard';
import { Operation } from 'src/app/config/constants';
import { IdentitySerializer, JsonSerializer, RSocketClient } from "rsocket-core";
import RSocketWebSocketClient from "rsocket-websocket-client";
import { environment } from '../../../environments/environment';
import { TelemetryData } from 'src/app/models/telemetry-data';

//endpoints
const CC_API_URL = 'ccAPIUrl';
const DASHBOARD_API_URL = 'dashboardAPIUrl';
const CUSTOMERS_ENDPOINT = 'customers';
const DATAPOINT_ENDPOINTS = `data-points`;

@Injectable()
export class BackendService {
    constructor(private httpBaseService: HttpBaseService) {
    }

    public checkUserNamePresence(username: string) {
        return this.httpBaseService.get(CC_API_URL, `user-account/details?username=${username}`);
    }

    public getCustomers(): Observable<any> {
        return this.httpBaseService.get<Customer[]>(CC_API_URL, `${CUSTOMERS_ENDPOINT}?dashboardEnabled=true`);
    }

    public getLastSiteList(): Observable<any> {
        return this.httpBaseService.get<Site>(CC_API_URL, `filters/last-site`);
    }

    public updateLastSiteList(siteIdList: string[]) {
        return this.httpBaseService.patch(CC_API_URL, `filters/last-site`, null, siteIdList);
    }

    public writeCommand(pointToUpdate: any, priority: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointToUpdate.dataPointId,
                value: pointToUpdate.value,
                rollbackTimer: pointToUpdate.rollbackTimer,
                priority: priority
            }]
        };
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points`, null, body);
    }

    public getEquipments(username: string, selectedSite: {siteId: string, customerId: string}, searchText: string = '', typeFilter: string[] = []):Observable<Equipment[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            typeFilter: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getDataByCategory(category: string, username: string, selectedSite: {siteId: string, customerId: string}, searchText: string = '', typeFilter: string[] = []):Observable<any[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            classTypeFilterList: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `${category}/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getEquipmentsV2(username: string, selectedSite: {siteId: string, customerId: string}, searchText: string = '', typeFilter: string[] = []):Observable<EquipmentV2[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            classTypeFilterList: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getCollections(selectedSite: {siteId: string, customerId: string}):Observable<Collection[]> {
        let siteId = selectedSite.siteId;

        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}`, null);
    }

    public getEquipmentDetails(username: string, selectedSite: {siteId: string, customerId: string}, equipmentUri: string) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/${siteId}/${equipmentUri}?username=${username}&customerId=${customerId}`);
    }

    public getPinnedEquipments(username: string, selectedSite: {siteId: string, customerId: string}) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedEquipmentsDetailes(username: string, selectedSite: {siteId: string, customerId: string}, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getPinnedCollections(username: string, selectedSite: {siteId: string, customerId: string}) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedCollectionsDetailes(username: string, selectedSite: {siteId: string, customerId: string}, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getPinnedLocations(username: string, selectedSite: {siteId: string, customerId: string}) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedLocationsDetailes(username: string, selectedSite: {siteId: string, customerId: string}, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getEquipmentsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/${siteId}/classifications`);
    }

    public getEquipmentsClassificationsV2(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/v2/${siteId}/classifications`);
    }

    public getCollectionsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `collection/${siteId}/classifications`);
    }

    public getLocationsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `location/${siteId}/classifications`);
    }

    public getCollectionDetails(siteId: string, username: string, collectionId: string, customerId: string):Observable<CollectionDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/${collectionId}?customerId=${customerId}&username=${username}`, null);
    }

    public getLocationDetails(siteId: string, username: string, locationId: string, customerId: string):Observable<LocationDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/${locationId}?customerId=${customerId}&username=${username}`, null);
    }

    public getEquipmentDetailsV2(siteId: string, username: string, locationId: string, customerId: string):Observable<EquipmentDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/${locationId}?customerId=${customerId}&username=${username}`, null);
    }

    public getRSocketClient(): RSocketClient {
        return new RSocketClient({
            serializers: {
                data: JsonSerializer,
                metadata: IdentitySerializer
            },
            setup: {
                // ms btw sending keepalive to server
                keepAlive: 60000,
                // ms timeout if no keepalive response
                lifetime: 180000,
                // format of `data`
                dataMimeType: 'application/json',
                // format of `metadata`
                metadataMimeType: 'message/x.rsocket.routing.v0',
            },
            transport: new RSocketWebSocketClient({
                url: `${environment.ccAPIUrl.replace('https', 'wss')}/core-backend-websocket4`
            }),
        });
    }

    public getTelemetryData(pointUUID: string, from: string, to: string): Observable<TelemetryData> {
        const params = new HttpParams()
            .set('dataPointId', pointUUID)
            .set('from', from)
            .set('to', to);
        return this.httpBaseService.get<TelemetryData>(CC_API_URL, `time-series`, params);
    }

    public entityAddRemovePin(category: string, username: string, selectedSite: {siteId: string, customerId: string}, operation: Operation, entityUri: string) {
        const siteId = selectedSite.siteId;
        const payload = {
            pinnedEntityUri: entityUri,
            operation,
            username
        }
        return this.httpBaseService.patch(DASHBOARD_API_URL, `users/${siteId}/pin-${category}`, null, payload)
    }

    public acceptTermsOfUse(username) {
        return this.httpBaseService.patch(DASHBOARD_API_URL, `users/terms-and-conditions?username=${username}&hasAgreedTermsAndConditions=true`)
    }

    public checkTermsOfUseStatus(username): Observable<any> {
        return this.httpBaseService.get(DASHBOARD_API_URL, `users/terms-and-conditions?username=${username}`);
    }

    public getDataPointWeeklySchedule(pointUUID: string): Observable<any> {
        return this.httpBaseService.get(CC_API_URL, `${DATAPOINT_ENDPOINTS}/${pointUUID}/weekly-schedule`);
    }

    public getDataPointExceptionSchedule(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(CC_API_URL, `${DATAPOINT_ENDPOINTS}/${dataPointId}/exception-schedule`);
    }

    public getDataPointsRollbackInfo(pointId: string) {
        return this.httpBaseService.post(CC_API_URL, `${DATAPOINT_ENDPOINTS}/processes`, null, [pointId]);
    }

    public getDataPointDetails(pointID: string): Observable<Point> {
        const body =
        {
            "includePresentValue": true,
            "checkIsOverridden": true,
            "filter":{
               "boolean":{
                  "operator":"AND",
                  "expressions":[
                     {
                        "relation":{
                           "field":"id",
                           "operator":"is",
                           "value": pointID
                        }
                     },
                     {
                        "relation":{
                           "field":"polled",
                           "operator":"IS",
                           "value":true
                        }
                     }
                  ]
               }
            }
         };

         return this.httpBaseService.post<any>(CC_API_URL, `data-points/filter?page=0&size=1000`, null, body).pipe(map(res => res.content[0]));
    }

    public removeOverrideOnP8(pointUUID: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointUUID,
                value: null,
                rollbackTimer: null,
                priority: 'PRIO_8',
            }]
        }
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points`, null, body);
    }

    public removeOverrideWithRunDownTimer(pointUUID: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointUUID,
                value: null,
                rollbackTimer: null,
                rundownTimer: true
            }]
        }
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points-with-rollback`, null, body);
    }

    public checkUserWritePermission() {
        return this.httpBaseService.get(CC_API_URL, `user-account/user-has-write-permission`);
    }

    public getCustomerSpecificationsByCustomerId(customerId: string, username: string): Observable<any> {
        return this.httpBaseService.get(DASHBOARD_API_URL, `customer/${customerId}?username=${username}`)
    }

    public getCustomerListByUser() {
        return this.httpBaseService.post(CC_API_URL, `customers/filter`)
    }

    public addCustomerSpecifications(customerId: string, text: string, username: string) {
        const body = {
            customerId,
            text
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `customer?username=${username}`, null, body);
    }

    public getUserRole(username: string) {
        return this.httpBaseService.get(CC_API_URL, `user-account/role?username=${username}`);
    }

}
