import { Injectable } from '@angular/core';
import { BackendService } from './backend/backend.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { Router } from '@angular/router';

export const ROLE_PERMISSIONS = {
    DASHBOARDS_APP: 'DASHBOARDS_APP',

    EDIT_CUSTOMER_SPEC_BUTTON_DASHBOARDS_APP: 'EDIT_CUSTOMER_SPEC_BUTTON.DASHBOARDS_APP',
};

@Injectable({
    providedIn: 'root',
})
export class AuthorizationService {
    public userRolePermissionList: string;

    constructor(private backendService: BackendService, private authService: OAuthService, private router: Router) {}

    hasPermission(permission: string): boolean {
        if (
            this.userRolePermissionList == null ||
            this.userRolePermissionList.length == 0
        ) {
            return false;
        }
        return this.userRolePermissionList.includes(permission);
    }

    init() {
        this.backendService
            .getUserRolePermissionList()
            .subscribe((response: any) => {
                localStorage.setItem(
                    'userRolePermissionList',
                    response.userRolePermissionList
                );
                this.userRolePermissionList = response.userRolePermissionList;

                if (this.authService.hasValidIdToken() && !this.userRolePermissionList.includes(ROLE_PERMISSIONS.DASHBOARDS_APP)) {
                    this.router.navigateByUrl('/no-access');
                }
            });
    }
}
