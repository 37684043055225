import { Component, Input, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification/notification.service';

@Component({
	selector: 'cc-share-link',
	templateUrl: './share-link.component.html',
	styleUrls: ['./share-link.component.scss']
})
export class ShareLinkComponent implements OnInit {
	@Input() link: string;

	constructor(private notificationService: NotificationService) { }

	ngOnInit(): void {
	}

	public onClipboardCopy(successful: boolean): void {
		if (successful) {
			this.notificationService.addSuccessMessage('Success', 'Link copied');
		}
	}
}
