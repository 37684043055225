import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {DeviceStatus, LocationDetails, Point} from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import { Operation } from "src/app/config/constants";

@Component({
    selector: "cc-location-details-view",
    templateUrl: "./details-view.component.html",
    styleUrls: ["./details-view.component.scss"],
})
export class LocationDetailsViewComponent implements OnInit {
    @Input() locationDetails: LocationDetails;
	@Input() username: string;
	@Input() selectedSite: { siteId: string, customerId: string };
    @Input() pointsStatus: { [key: string]: DeviceStatus } = {};

    showScheduleDialog: boolean = false;
    showExceptionScheduleDialog: boolean = false;
    selectedScheduleUUID: string = "";
    selectedExceptionScheduleUUID: string = "";
    locationLink: string;
    pointsObj: { [key: string]: { [key: string]: Point[] } } = {};

    isOverrideResetOn: boolean = false;
    userHasWritePermission$: Observable<boolean>;
	category = 'location';

    constructor(private backendService: BackendService) {}

    ngOnInit(): void {
		const locationId = this.locationDetails.uri.split(':').pop();
		this.locationLink = `${window.location.origin}/customers/${this.selectedSite.customerId}/sites/${this.selectedSite.siteId}/locations/${locationId}`;
    }

    showSchedule(pointUUID: string) {
        this.selectedScheduleUUID = pointUUID;
        this.showScheduleDialog = true;
    }

    showExceptionSchedule(pointUUID: string) {
        this.selectedExceptionScheduleUUID = pointUUID;
        this.showExceptionScheduleDialog = true;
    }

	togglePin(event: MouseEvent, location: LocationDetails) {
        event.stopPropagation();
        const operation = location.pinned ? Operation.Delete : Operation.Add;
        this.backendService
            .entityAddRemovePin(
                this.category,
                this.username,
                this.selectedSite,
                operation,
                location.uri
            )
            .subscribe(
                () => {
                    location.pinned = !location.pinned;
                },
                (err) => {
                    console.log(err);
                }
            );
    }
}
