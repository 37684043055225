import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule } from "./app/app.module";
import { environment } from "./environments/environment";

import {
    applyPolyfills,
    defineCustomElements,
} from "@eon-ui/eon-ui-components/loader";

import * as Sentry from "@sentry/angular";

Sentry.init({
    environment: environment.name,
    dsn: "https://17bc7bf37be11f417a2bc8077307500d@sentry.eon.com/156",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [
        /^https:\/\/api.dev.cbms.eon-optimum.com/,
        /^https:\/\/api.cbms.eon-optimum.com/,
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));

// If your app doesn't have to support IE/Edge(legacy), you can skip the applyPolyfills call
applyPolyfills().then(() => {
    defineCustomElements(window); // this registers the E.ON UI components in the browser
});
