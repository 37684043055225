import { HttpBaseService } from './../http/http-base.service';
import {Injectable} from '@angular/core';
import {HttpParams} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';

import { Site, SiteInfo, UpdateSiteScheduleReq } from '../../models/site';
import {Customer} from 'src/app/models/customer';
import {
    Equipment,
    Point,
    Collection,
    CollectionDetails,
    LocationDetails,
    EquipmentV2,
    EquipmentDetails, EntityStatusResponse,
    EntityOverriddenStatusReq,
    EntityOverriddenStatusRes,
    ISelectedSite
} from 'src/app/models/dashboard';
import {ExternalSource, FieldList, Operation, PAGINATION} from 'src/app/config/constants';
import {IdentitySerializer, JsonSerializer, RSocketClient} from "rsocket-core";
import RSocketWebSocketClient from "rsocket-websocket-client";
import {environment} from '../../../environments/environment';
import {TelemetryData} from 'src/app/models/telemetry-data';
import {CoreSmartAlert, IUpdateSmartAlertReq, RolePermissionListRes} from "../../models/core/alert-rule.model";
import {FieldFilter, FilterParams} from "../../models/filter-param";
import {DataPoint, DataPointTag} from "../../models/data-point";
import {DataPointAPI, dataPointApiSortFieldMapper} from "../../models/api/data-point";
import moment from "moment-timezone";
import {ViewDevice} from "../../models/device";
import {EntityType} from "../../models/api/entity-type";

//endpoints
const CC_API_URL = 'ccAPIUrl';
const DASHBOARD_API_URL = 'dashboardAPIUrl';
const CUSTOMERS_ENDPOINT = 'customers';
const DATAPOINT_ENDPOINTS = `data-points`;
const SITES_ENDPOINTS = `sites`;

type SortOptions = {
    sortField: string;
    sortOrder: SortOrderType;
    email?: string;
}

export enum SortOrderType {
    ASC = 'asc', DESC = 'desc'
}

const IS = 'IS';
const IN = 'IN';
const CONTAINS = 'CONTAINS';


@Injectable()
export class BackendService {
    constructor(private httpBaseService: HttpBaseService) {
    }

    public checkUserNamePresence(username: string) {
        return this.httpBaseService.get(CC_API_URL, `user-account/details?username=${username}`);
    }

    public getCustomers(): Observable<any> {
        return this.httpBaseService.get<Customer[]>(CC_API_URL, `${CUSTOMERS_ENDPOINT}?dashboardEnabled=true`);
    }

    public getLastSiteList(): Observable<any> {
        return this.httpBaseService.get<Site>(CC_API_URL, `filters/last-site`);
    }

    public updateLastSiteList(siteIdList: string[]) {
        return this.httpBaseService.patch(CC_API_URL, `filters/last-site`, null, siteIdList);
    }

    public writeCommand(pointToUpdate: any, priority: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointToUpdate.dataPointId,
                value: pointToUpdate.value,
                rollbackTimer: pointToUpdate.rollbackTimer,
                priority: priority
            }]
        };
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points`, null, body);
    }

    public getEquipments(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, searchText: string = '', typeFilter: string[] = []): Observable<Equipment[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            typeFilter: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getDataByCategory(category: string, username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, searchText: string = '', typeFilter: string[] = []): Observable<any[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            classTypeFilterList: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `${category}/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getEquipmentsV2(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, searchText: string = '', typeFilter: string[] = []): Observable<EquipmentV2[]> {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId
        const body = {
            nameFilter: searchText,
            classTypeFilterList: typeFilter,
            username
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}?customerId=${customerId}&sortDataPoints=true`, null, body);
    }

    public getEntitiesStatus(params: {
        siteId: string,
        customerId: string,
        entityIds: string[],
        entityType: EntityType,
    }): Observable<EntityStatusResponse> {
        const {customerId, entityIds, siteId, entityType} = params;
        const httpParams = new HttpParams().set('customerId', customerId);

        if (entityIds.length === 0) {
            return of({entityStatus: {}});
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `${entityType}/${siteId}/status`, httpParams, {entityIds: entityIds});
    }


    public getEntitiesOverriddenStatus(params: {
        siteId: string,
        customerId: string,
        requests: EntityOverriddenStatusReq[]
    }): Observable<EntityOverriddenStatusRes> {
        const {customerId, requests, siteId} = params;
        const httpParams = new HttpParams().set('customerId', customerId);

        if (requests.length === 0) {
            return of({entityOverriddenStatuses: {}});
        }

        return this.httpBaseService.post(DASHBOARD_API_URL, `${siteId}/getOverriddenStatus`, httpParams, {requests});
    }


    public loadEntityOverriddenStatus(collections: any[], entityType: string, selectedSite: ISelectedSite) {
        const params = {
            ...selectedSite,
            requests: collections.map((collection) => {
                return {entityId: collection.id, entityType: entityType};
            }),
        }
        return this.getEntitiesOverriddenStatus(params);
    }

    public getCollections(selectedSite: { siteId: string, customerId: string }): Observable<Collection[]> {
        let siteId = selectedSite.siteId;

        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}`, null);
    }

    public getEquipmentDetails(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, equipmentUri: string) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/${siteId}/${equipmentUri}?username=${username}&customerId=${customerId}`);
    }

    /**
     * @description update smart alert status
     * @input alertObj: IUpdateSmartAlertReq
     * @returns Observable
     */
    public updateStatusForSmartAlert(alertObj: IUpdateSmartAlertReq): Observable<any> {
        const path = `smart-alert/update-status?alertId=${alertObj.alertId}&status=${alertObj.alertStatus}`;
        return this.httpBaseService.patch<any>(DASHBOARD_API_URL, path);
    }

    public getSiteOverviewInfo(siteId: string): Observable<SiteInfo> {
        const path = `${SITES_ENDPOINTS}\\${siteId}`;
        return this.httpBaseService.get(CC_API_URL, path)
    }

    public updateSiteScheduleInfo(body: UpdateSiteScheduleReq): Observable<SiteInfo> {
        const path = `${SITES_ENDPOINTS}`;
        return this.httpBaseService.patch(CC_API_URL, path, null, body);
    }

    public getPinnedEquipments(username: string, selectedSite: { siteId: string, customerId: string }) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedEquipmentsDetailes(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getPinnedCollections(username: string, selectedSite: { siteId: string, customerId: string }) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedCollectionsDetailes(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getPinnedLocations(username: string, selectedSite: { siteId: string, customerId: string }) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = {
            username: username
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/pinned?customerId=${customerId}`, null, body);
    }

    public getPinnedLocationsDetailes(username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, equipmentIds: string[]) {
        let siteId = selectedSite.siteId;
        let customerId = selectedSite.customerId;
        let body = equipmentIds;
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/list?customerId=${customerId}&username=${username}`, null, body);
    }

    public getEquipmentsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/${siteId}/classifications`);
    }

    public getEquipmentsClassificationsV2(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `equipment/v2/${siteId}/classifications`);
    }

    public getCollectionsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `collection/${siteId}/classifications`);
    }

    public getLocationsClassifications(siteId: string) {
        return this.httpBaseService.get(DASHBOARD_API_URL, `location/${siteId}/classifications`);
    }

    public getCollectionDetails(siteId: string, username: string, collectionId: string, customerId: string): Observable<CollectionDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `collection/${siteId}/${collectionId}?customerId=${customerId}&username=${username}`, null);
    }

    public getLocationDetails(siteId: string, username: string, locationId: string, customerId: string): Observable<LocationDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `location/${siteId}/${locationId}?customerId=${customerId}&username=${username}`, null);
    }

    public getEquipmentDetailsV2(siteId: string, username: string, locationId: string, customerId: string): Observable<EquipmentDetails> {
        return this.httpBaseService.post(DASHBOARD_API_URL, `equipment/v2/${siteId}/${locationId}?customerId=${customerId}&username=${username}`, null);
    }

    public getRSocketClient(): RSocketClient {
        return new RSocketClient({
            serializers: {
                data: JsonSerializer,
                metadata: IdentitySerializer
            },
            setup: {
                // ms btw sending keepalive to server
                keepAlive: 60000,
                // ms timeout if no keepalive response
                lifetime: 180000,
                // format of `data`
                dataMimeType: 'application/json',
                // format of `metadata`
                metadataMimeType: 'message/x.rsocket.routing.v0',
            },
            transport: new RSocketWebSocketClient({
                url: `${environment.ccAPIUrl.replace('https', 'wss')}/core-backend-websocket4`
            }),
        });
    }

    public getTelemetryData(pointUUID: string, from: string, to: string): Observable<TelemetryData> {
        const params = new HttpParams()
            .set('dataPointId', pointUUID)
            .set('from', from)
            .set('to', to);
        return this.httpBaseService.get<TelemetryData>(CC_API_URL, `time-series`, params);
    }

    public entityAddRemovePin(category: string, username: string, selectedSite: {
        siteId: string,
        customerId: string
    }, operation: Operation, entityUri: string) {
        const siteId = selectedSite.siteId;
        const payload = {
            pinnedEntityUri: entityUri,
            operation,
            username
        }
        return this.httpBaseService.patch(DASHBOARD_API_URL, `users/${siteId}/pin-${category}`, null, payload)
    }

    public acceptTermsOfUse(username) {
        return this.httpBaseService.patch(DASHBOARD_API_URL, `users/terms-and-conditions?username=${username}&hasAgreedTermsAndConditions=true`)
    }

    public checkTermsOfUseStatus(username): Observable<any> {
        return this.httpBaseService.get(DASHBOARD_API_URL, `users/terms-and-conditions?username=${username}`);
    }

    public getDataPointWeeklySchedule(pointUUID: string): Observable<any> {
        return this.httpBaseService.get(CC_API_URL, `${DATAPOINT_ENDPOINTS}/${pointUUID}/weekly-schedule`);
    }

    public getDataPointExceptionSchedule(dataPointId: string): Observable<any> {
        return this.httpBaseService.get(CC_API_URL, `${DATAPOINT_ENDPOINTS}/${dataPointId}/exception-schedule`);
    }

    public getDataPointsRollbackInfo(pointId: string) {
        return this.httpBaseService.post(CC_API_URL, `${DATAPOINT_ENDPOINTS}/processes`, null, [pointId]);
    }

    public getDataPointDetails(pointID: string): Observable<Point> {
        const body =
            {
                "includePresentValue": true,
                "checkIsOverridden": true,
                "filter": {
                    "boolean": {
                        "operator": "AND",
                        "expressions": [
                            {
                                "relation": {
                                    "field": "id",
                                    "operator": "is",
                                    "value": pointID
                                }
                            },
                            {
                                "relation": {
                                    "field": "polled",
                                    "operator": "IS",
                                    "value": true
                                }
                            }
                        ]
                    }
                }
            };

        return this.httpBaseService.post<any>(CC_API_URL, `data-points/filter?page=0&size=1000`, null, body).pipe(map(res => res.content[0]));
    }

    public removeOverrideOnP8(pointUUID: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointUUID,
                value: null,
                rollbackTimer: null,
                priority: 'PRIO_8',
            }]
        }
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points`, null, body);
    }

    public removeOverrideWithRunDownTimer(pointUUID: string): Observable<any> {
        const body = {
            setpointUpdates: [{
                dataPointId: pointUUID,
                value: null,
                rollbackTimer: null,
                rundownTimer: true
            }]
        }
        return this.httpBaseService.patch(CC_API_URL, `${DATAPOINT_ENDPOINTS}/set-points-with-rollback`, null, body);
    }

    public checkUserWritePermission() {
        return this.httpBaseService.get(CC_API_URL, `user-account/user-has-write-permission`);
    }

    public getCustomerSpecificationsByCustomerId(customerId: string, username: string): Observable<any> {
        return this.httpBaseService.get(DASHBOARD_API_URL, `customer/${customerId}?username=${username}`)
    }

    public getCustomerListByUser() {
        return this.httpBaseService.post(CC_API_URL, `customers/filter`)
    }

    public addCustomerSpecifications(customerId: string, text: string, username: string) {
        const body = {
            customerId,
            text
        }
        return this.httpBaseService.post(DASHBOARD_API_URL, `customer?username=${username}`, null, body);
    }

    public getUserRolePermissionList(): Observable<RolePermissionListRes> {
        return this.httpBaseService.get(CC_API_URL,`user-account/role-permission-list`);
    }

    public getAlertDetails(alarmInstanceId: string): Observable<CoreSmartAlert> {
        return this.httpBaseService.get(CC_API_URL, `alarm-instance/with-filter-matcher-result?alarmInstanceId=${alarmInstanceId}`);
    }

    public getDataPointsSummary(
        // used for Datapoint tab, for better performance
        filterParams: FilterParams,
        pageNumber: number = PAGINATION.DEFAULT_PAGE_NR,
        pageSize: number = 100,
        withGlobalFilters: boolean = true,
        sortOptions?: SortOptions): Observable<{ content: DataPoint[]; totalElements: number }> {

        let path = `${DATAPOINT_ENDPOINTS}/filter-summary?page=${pageNumber}&size=${pageSize}`;
        return this.getDataPoints(filterParams, path, withGlobalFilters, sortOptions);
    }

    private getDataPoints(
        filterParams: FilterParams, path: string,
        withGlobalFilters: boolean,
        sortOptions?: SortOptions): Observable<{ content: DataPoint[]; totalElements: number }> {

        let body = this.computeDatapointsFilters(filterParams, withGlobalFilters);
        return this.httpBaseService.post<{
            content: DataPointAPI[],
            totalElements: number
        }>(CC_API_URL, this.getSortablePath(path, sortOptions), null, body)
            .pipe(map(value => this.mapResponseToDatapointCoreModel(value)));
    }

    private computeDatapointsFilters(filterParams: FilterParams, withGlobalFilters: boolean) {
        let filterExpressions = [];
        const shouldExcludeSites = JSON.parse(sessionStorage.getItem('excludeSitesFilter'));

        if (!shouldExcludeSites && withGlobalFilters && this.getSelectedSiteList() && this.getSelectedSiteList().length) {
            filterExpressions.push(this.generateSiteRelations(this.getSelectedSiteList()));
        }

        if (filterParams.siteFilter && filterParams.siteFilter.length) {
            filterExpressions.push(this.generateSiteRelations(filterParams.siteFilter));
        }

        if (filterParams.fieldFilter) {
            filterExpressions.push(...this.generateFieldsExpressions(filterParams.fieldFilter));
        }
        if (filterParams.quickFilterText) {
            filterExpressions.push(this.generateQuickFilterTextExpressions(filterParams.quickFilterText, filterParams.tagIdList));
        }

        if (filterParams.deviceFilter && filterParams.deviceFilter.length) {
            filterExpressions.push(this.generateDeviceRelations(filterParams.deviceFilter));
        }

        if (filterParams.gatewayFilter && filterParams.gatewayFilter.length) {
            filterExpressions.push(
                {
                    relation: {
                        field: 'dataPointEntity.device.gateway.id',
                        value: filterParams?.gatewayFilter.map(item => item.id),
                        operator: IN
                    }
                }
            )
        }

        if (filterParams.tagFilter && filterParams.tagFilter.length) {
            filterExpressions.push(this.generateTagsRelations(filterParams.tagFilter, filterParams.tagLogicalOperator));
        }

        const selectedCustomer = this.getSelectedCustomer();
        if (selectedCustomer && withGlobalFilters) {
            filterExpressions.push(this.generateCustomerRelations(selectedCustomer));
        }

        if (filterParams.dataPointIdFilter) {
            filterExpressions.push(
                {
                    relation: {
                        field: 'id',
                        operator: IN,
                        value: filterParams.dataPointIdFilter.map(dataPoint => dataPoint.id)
                    }
                });
        }

        let body = {
            includePresentValue: filterParams.includesPresentValue || false,
            filter: {
                boolean: {
                    operator: 'AND',
                    expressions: [
                        ...filterExpressions
                    ]
                }
            }
        };

        if (filterParams.isPolled) {
            body.filter.boolean.expressions.push(
                {
                    relation: {
                        field: 'polled',
                        operator: IS,
                        value: true
                    }
                });
        }
        return body;
    }

    private getSortablePath(path: string, sortOptions: SortOptions) { //TODO use this function in all the class
        if (sortOptions && sortOptions.sortField) {
            path = `${path}&sort=${sortOptions.sortField},${sortOptions.sortOrder}`;
        }
        if (sortOptions && sortOptions.email) {
            path = `${path}&email=${sortOptions.email}`
        }
        return path;
    }

    private mapResponseToDatapointCoreModel(value: { content: DataPointAPI[]; totalElements: number }): {
        content: DataPoint[];
        totalElements: number
    } {

        let mapCCFields = function (dataPointAPI: DataPointAPI) {
            const datapoint = mapCommonFields(dataPointAPI);
            datapoint.instanceExtension = dataPointAPI.extensions && dataPointAPI.extensions.instance ?
                dataPointAPI.extensions.instance.value : null;
            return datapoint;
        };

        let mapCommonFields = function (dataPointAPI: DataPointAPI) {
            const datapoint: DataPoint = <DataPoint><unknown>dataPointAPI;
            datapoint.selected = false;
            datapoint.sourceId = dataPointAPI.externalId;
            datapoint.presentValue = dataPointAPI.lastRecordedValue;
            datapoint.presentValueTimestamp = moment.tz(dataPointAPI.lastRecordedValueTimestamp, dataPointAPI.site?.timezone || "Europe/London").format('DD.MM.YYYY, HH:mm:ss');
            datapoint.deviceName = dataPointAPI.deviceName ? dataPointAPI.deviceName : dataPointAPI['device']['name'];
            datapoint.objectName = dataPointAPI.name;
            datapoint.deviceSiteName = dataPointAPI?.site?.name;
            datapoint.sourceType = dataPointAPI.sourceType;
            datapoint.isCCSource = ExternalSource.BACKNET === dataPointAPI.source;
            datapoint.isWiseMeterSource = ExternalSource.WISEMETER === dataPointAPI.source;
            return datapoint;
        };
        let mapWiseMeterFields = function (dataPointAPI: DataPointAPI) {
            const datapoint = mapCommonFields(dataPointAPI);
            datapoint.classificationExtension = dataPointAPI.extensions && dataPointAPI.extensions.classification ?
                dataPointAPI.extensions.classification.value : null;
            return datapoint;
        };

        const toDatapointCoreModel = function (dataPointAPI: DataPointAPI): DataPoint {

            if (ExternalSource.BACKNET === dataPointAPI.source) {
                return mapCCFields(dataPointAPI);
            }
            if (ExternalSource.WISEMETER === dataPointAPI.source) {
                return mapWiseMeterFields(dataPointAPI);
            }
        };

        const response = value.content.map(value1 => toDatapointCoreModel(value1));
        return {content: response, totalElements: value.totalElements};
    }


    private generateSiteRelations(siteFilter: Site[]) {
        return {
            relation: {
                field: 'site.id',
                value: siteFilter.map(site => site.id),
                operator: IN
            }
        };
    }

    private generateCustomerRelations(selectedCustomer: Customer) {
        return {
            relation: {
                field: 'site.customer.id',
                value: selectedCustomer.id,
                operator: IS
            }
        };
    }

    private getRelation(field: string, operator: string, values: any) {
        return {
            relation: {
                field: field,
                operator: operator,
                value: values
            }
        };
    }

    private generateDeviceRelations(deviceFilter: ViewDevice[]) {
        return {
            relation: {
                field: 'device.id',
                value: deviceFilter.map(device => device.id),
                operator: IN
            }
        };
    }

    private generateTagsRelations(tagFilter: DataPointTag[], tagLogicalOperator: string) {
        return {
            relation: {
                field: 'tags.name',
                operator: tagLogicalOperator === 'OR' ? IN : 'ALL',
                value: tagFilter.map(tag => tag.name)
            }
        };
    }

    private generateFieldsExpressions(fieldsToFilter: FieldFilter) {
        return Object.keys(fieldsToFilter).map(key => {
            if (fieldsToFilter[key].intervalStart) {
                return {
                    relation: {
                        field: this.mapUiFields(fieldsToFilter[key].field),
                        operator: fieldsToFilter[key].operator,
                        value: {
                            min: parseFloat(fieldsToFilter[key].intervalStart),
                            max: parseFloat(fieldsToFilter[key].intervalEnd)
                        }
                    }
                };
            }

            if (fieldsToFilter[key].field === FieldList.POLLED) {
                return {
                    relation: {
                        field: "polled",
                        value: fieldsToFilter[key].value,
                        operator: fieldsToFilter[key].operator,
                    }
                };
            }

            if (fieldsToFilter[key].field === FieldList.SITE_ASSIGNED) {
                return {
                    relation: {
                        field: "site.id",
                        // @ts-ignore
                        operator: fieldsToFilter[key].value === true ? 'IS_NOT_NULL' : 'IS_NULL',
                    }
                };
            }

            return {
                relation: {
                    field: this.mapUiFields(fieldsToFilter[key].field),
                    operator: fieldsToFilter[key].operator,
                    value: fieldsToFilter[key].value
                }
            };
        });
    }

    private generateQuickFilterTextExpressions(quickFilterText: string, tagIdList: any) {
        const quickFilter = {
            boolean: {
                operator: 'OR',
                expressions: [
                    {
                        relation: {
                            field: dataPointApiSortFieldMapper['objectName'],
                            operator: CONTAINS,
                            value: quickFilterText
                        }
                    },
                    {
                        relation: {
                            field: 'units',
                            operator: CONTAINS,
                            value: quickFilterText
                        }
                    },
                    {
                        relation: {
                            field: 'description',
                            operator: CONTAINS,
                            value: quickFilterText
                        }
                    },
                    {
                        relation: {
                            field: 'userDescription',
                            operator: CONTAINS,
                            value: quickFilterText
                        }
                    }
                ]
            }
        };
        if (tagIdList) {
            quickFilter.boolean.expressions.push(this.getRelation('tags.id', IN, tagIdList));
        } else {
            quickFilter.boolean.expressions.push(this.getRelation('tags.name', CONTAINS, quickFilterText));
        }
        return quickFilter;
    }

    private mapUiFields(field: string) {
        return {
            'Custom Name': 'userDescription',
            'Source Description': 'description',
            'IO-Type': 'type',
            'Signal Type': 'signalType',
            'Source': 'source',
            'Last recorded value': 'lastRecordedValue',
            'Name': 'name',
            'Units': 'units',
            'Datapoint ID': 'id',
            'Instance': 'instanceExtension',
            'ID': 'sourceId',
            'Classification': 'classificationExtension'
        }[field];
    }

    getSelectedCustomer() {
        return JSON.parse(sessionStorage.getItem('selectedCustomer'));
    }

    private getSelectedSiteList() {
        return JSON.parse(sessionStorage.getItem('selectedSiteList'));
    }

}
