<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your equipment</h1>
        <div
            class="sub-navigation flex justify-content-between align-items-center"
        >
            <eon-ui-button [routerLink]="['/equipments']" icon="arrow_left"
                text="Back to Equipment" scheme="turquoise"></eon-ui-button>
        </div>
    </div>
</div>

<div class="main-wrapper">
    <div class="container no-padding">
        <p-tabView styleClass="custom-no-bg">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/live-view.svg" />
                    <span>Live View</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12 entity-full-width" *ngIf="equipmentDetails">
                        <cc-equipment-details-view [username]="username" [selectedSite]="selectedSite"
                            [equipmentDetails]="equipmentDetails" [pointsStatus]="pointsStatus"></cc-equipment-details-view>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/dashboard.svg" />
                    <span>History</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12" *ngIf="(pointsObj | json) != '{}'">
                        <cc-historical-data [pointsObj]="pointsObj"></cc-historical-data>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-confirmDialog #cd [style]="{width: '50vw'}" [breakpoints]="{'640px': '90vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end" *ngIf="userHasWritePermission$ | async as userHasWritePermission">
            <eon-ui-button *ngIf="!isOverrideResetOn || !userHasWritePermission" size="small" scheme="turquoise" text="Close" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" class="margin-right-1" size="small" scheme="turquoise" text="No" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" size="small" scheme="turquoise" text="Yes" (click)="cd.accept()"></eon-ui-button>
        </div>
    </ng-template>
</p-confirmDialog>
