<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your locations</h1>
        <div class="flex align-items-center justify-content-between sub-navigation">
            <div class="grouped-buttons">
                <button class="grouped-button-left button-gray-bg selected" [routerLink]="['/locations']">All
                    <span>{{locations.length}}</span></button>
                    <button class="grouped-button-right button-gray-bg" [routerLink]="['/locations/pinned']">Pinned <span>{{noOfPinnedLocations}}</span></button>
            </div>
            <cc-filter-and-search [dataToFilter]="locations" [filterOptions]="locationFiltersOptions" [category]="category" (onFilterChanged)="onFilterChangedHandler($event)"></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="locations.length">
            <div class="col-12 xl:col-4" *ngFor="let locationCard of locations">
                <div class="entity-card" [routerLink]="['/locations', locationCard.id]">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img src="assets/svg/{{locationCard.classIcon}}.svg" width="48" height="48">
                        </div>
                        <div class="entity-details">
                            <h2
                                class="entity-name"
                                [pTooltip]="locationCard.label"
                                [tooltipDisabled]="
                                    locationCard.label &&
                                    locationCard.label.length <= 30
                                "
                                tooltipPosition="top"
                                [hideDelay]="100"
                            >
                                {{ locationCard.label }}
                            </h2>
                            <p class="entity-class">{{locationCard.classLabel}}</p>
                        </div>
                        <eon-ui-icon *ngIf="locationCard.pinned" class="top-right-position cursor-pointer" name="bookmark"
                            (click)="togglePin($event, locationCard)"></eon-ui-icon>
                        <eon-ui-icon *ngIf="!locationCard.pinned" class="top-right-position cursor-pointer" name="bookmark_outline"
                            (click)="togglePin($event, locationCard)"></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div class="entity-kpi flex align-items-center justify-content-around"
                            *ngIf="locationCard.selectedKpi?.signalType">
                            <ng-container [ngSwitch]="locationCard.selectedKpi.signalType">
                                <ng-container *ngSwitchCase="'Binary'">
                                    <span class="margin-right-05">{{locationCard.selectedKpi.comment ||
                                        locationCard.selectedKpi.classLabel}}</span>
                                    <p-inputSwitch styleClass="default-switch__black" [disabled]="true"
                                        [(ngModel)]="locationCard.selectedKpi.presentValue"></p-inputSwitch>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span class="margin-right-05">{{locationCard.selectedKpi.comment ||
                                        locationCard.selectedKpi.classLabel}}</span>
                                    <span class="kpi-value">
                                        {{locationCard.selectedKpi.presentValue}}
                                        <span style="font-size:1rem" [innerHTML]="locationCard.selectedKpi.htmlSymbol"></span>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid" *ngIf="!locations.length">
            <div class="col-12">
                <h2>No locations found</h2>
            </div>
        </div>

    </div>

    <router-outlet></router-outlet>
</div>
