export type AlarmFilter = {
    condition: string;
    rules: AlarmFilter[];
    field?: string;
    operator?: string;
    value?: string;
    isFreeText?: boolean;
    entityName?: string;
    entityId?: string;
};

export interface AlertData {
    id: string;
    name: string;
    type: string;
    priority: string | object;
    isEnabled: boolean;
    alarmFilter?: AlarmFilter;
    site: {
        id: string;
        name?: string;
        tags?: [];
        customer?: {
            id: string;
            name: string;
            tags?: any;
            tenant?: any;
            siteList?: any;
        };
        readonly?: boolean;
        dashboardEnabled?: boolean;
        timezone?: string;
    };
    activeDateInterval: {
        activeDate?: string;
        activeDateRange?: {
            fromDate: string;
            toDate: string;
        };
        activeTimeRange: {
            fromTime: string;
            toTime: string;
        };
        activeWeekdayList: string[];
    };
    exceptionDateRangeList: any[];
    timeDelay: {
        amount: number;
        unit: string;
    };
    timeExpiry: {
        amount: number;
        unit: string;
    };
    description: string;
    emailRecipients: string[];
}

export interface CoreSmartAlert {
    id: string;
    status: string | object;
    startDate: string | Date;
    endDate: string | Date;
    durationInMinutes: number;
    durationInHours?: string;
    comment: string;
    alarmConfig: AlertData;
    priority: string | object;
    alarmBackendStatus?: string;
    alarmFilterMatcherResult?: AlarmFilterMatcherResult;
}

export interface RolePermissionListRes {
    userRole: string[];
}

export interface AlarmFilterMatcherResult {
    condition: string;
    rules: AlarmFilterMatcherResult[];
    field?: string;
    operator?: string;
    value?: string;
    entityName?: string;
    entityId?: string;
    filterVersion?: string;

    isMatching?: boolean;
    matchWithValue?: string;
    isBinarySignalType?: boolean;
    isLastRecordedValueObsolete?: boolean;
    datapointName?: string;
}

export enum SmartAlertStatusLabel {
    OPEN = "OPEN",
    CLOSED = "CLOSED",
    IN_PROGRESS = "IN PROGRESS",
    EXPIRED = "EXPIRED",
}
export enum AlertStatusCode {
    "IN PROGRESS" = "IN_PROGRESS",
    CLOSED = "CLOSED",
    OPEN = "OPEN",
    EXPIRED = "EXPIRED",
}
export interface IUpdateSmartAlertReq {
    alertId: string;
    alertStatus: string;
}
