<div class="app-header-right">
    <div class="mobile-view">
        <a href="tel:08448227744" class="margin-right-1" style="display: none">
            <eon-ui-icon name="phone" size="small" scheme="white"></eon-ui-icon>
        </a>
        <eon-ui-icon class="cursor-pointer margin-right-1" name="location" size="small" scheme="white"
            (click)="openSiteSelectionOnMobile()">
        </eon-ui-icon>
    </div>

    <div class="grid site-and-support desktop-view justify-content-end">
        <div class="col-8 margin-right-1">
            <span class="p-float-label custom-dd__no-bg">
                <p-dropdown styleClass="w-full" inputId="dropdown" [autoDisplayFirst]="false" [filter]="true"
                    filterBy="label" [options]="groupedSites" [(ngModel)]="selectedSite" optionLabel="label"
                    [group]="true" (onChange)="handleSiteSelection($event)" [disabled]="disableSiteSelection">
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                            <span class="small-text gray-color margin-bottom-default">{{group.label}}</span>
                        </div>
                    </ng-template>
                </p-dropdown>
                <label for="dropdown">Site</label>

            </span>
        </div>
        <!-- <div class="col-4 flex align-items-center">
            <img src="/assets/svg/mobile-phone-icon.svg" title="Call Support" alt="Call Support" class="margin-right-05">
            <span>Call Support</span>
        </div> -->
    </div>
</div>

<div class="slide-in-box-wrapper" [ngClass]="isSiteSeletionActive ? 'isActive' : ''">
    <eon-ui-button class="close-button-top-right" icon="close" size="small" scheme="discreet"
        (click)="closeSiteSelectionOnMobile()"></eon-ui-button>

    <div class="slide-in-box-body slide-in-box-body__no-padding slide-in-box-body__full-height">
        <p-listbox scrollHeight="auto" styleClass="custom-listbox custom-listbox__height-85vh" [options]="groupedSites" [group]="true"
            [(ngModel)]="selectedSite" optionLabel="label" [filter]="true" (onChange)="handleSiteSelection($event)" [disabled]="disableSiteSelection">
            <ng-template pTemplate="header">
                <div class="flex w-full align-items-center">
                    <span>Current site: <b>{{selectedSite?.label}}</b></span>
                </div>
            </ng-template>
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span class="small-text gray-color margin-bottom-default">{{group.label}}</span>
                </div>
            </ng-template>
        </p-listbox>
    </div>
</div>