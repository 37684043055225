<div class="animatedbox animatedbox-one"></div>
<div class="animatedbox animatedbox-two"></div>
<div class="animatedbox animatedbox-three"></div>
<div class="blurr"></div>
<div class="content">
    <p class="eon_optimum_logo"><a href="https://www.eon.com/"><span></span></a></p>
    <div class="apperror-main">
        <h1>User does not have permission to access this page.</h1>
        
        <div class="support">
            <p>Please contact system administrator to request required permissions.</p>
        </div>
    </div>
    <button class="eon-button bg-eon-white logout" [routerLink]="['/equipments/pinned']">Return to Equipment</button>
</div>
<div class="apperror-footer">
    <p>© 2023 E.ON UK</p>
</div>
