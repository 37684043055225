import {Component, OnInit} from "@angular/core";
import {AuthorizationService} from "../../services/authorization.service";

@Component({
    selector: "cbms-error",
    templateUrl: "./error.component.html",
    styleUrls: ["./error.component.scss"],
})
export class ErrorComponent implements OnInit {
    constructor(
        private authorizationService: AuthorizationService,
    ) {
    }

    ngOnInit(): void {
        // this.dataService.currentTabHasChanged('error');
    }

    logout() {
        this.authorizationService.logout();
    }
}
