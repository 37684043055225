import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './views/error/error.component';
import { EquipmentsComponent } from './views/equipments/equipments.component';
import { CustomerSpecificationComponent } from './views/customer-specification/customer-specification.component';
import { NoAccessComponent } from './views/no-access/no-access.component';
import { TermsAndConditionsComponent } from './views/terms-and-conditions/terms-and-conditions.component';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { LocationsComponent } from './views/locations/locations.component';
import { CollectionsComponent } from './views/collections/collections.component';
import { EquipmentsV2Component } from './views/equipments-v2/equipments-v2.component';
import { EquipmentDetailsV2Component } from './views/equipment-details-v2/equipment-details-v2.component';
import { EquipmentDetailsViewWrapperComponent } from './views/equipments/details-view-wrapper/details-view-wrapper.component';
import { EquipmentPinnedViewComponent } from './views/equipments/pinned-view/pinned-view.component';
import { CollectionDetailsViewWrapperComponent } from './views/collections/details-view-wrapper/details-view-wrapper.component';
import { CollectionPinnedViewComponent } from './views/collections/pinned-view/pinned-view.component';
import { LocationDetailsViewComponent } from './views/locations/details-view/details-view.component';
import { LocationDetailsViewWrapperComponent } from './views/locations/details-view-wrapper/details-view-wrapper.component';
import { LocationPinnedViewComponent } from './views/locations/pinned-view/pinned-view.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'equipments',
		pathMatch: 'full'
	},
	{
		path: '',
		children: [
			{
				path: 'equipments',
				component: EquipmentsComponent
			},
			{
				path: 'equipments/pinned',
				pathMatch: 'full',
				component: EquipmentPinnedViewComponent
			},
			{
				path: 'customers/:customerId/sites/:siteId/equipments/:uri',
				pathMatch: 'full',
				component: EquipmentDetailsViewWrapperComponent
			},
			{
				path: 'equipments/:id',
				component: EquipmentDetailsViewWrapperComponent
			},
			{
				path: 'customer-specification',
				component: CustomerSpecificationComponent
			},
			{
				path: 'locations',
				component: LocationsComponent
			},
			{
				path: 'locations/pinned',
				pathMatch: 'full',
				component: LocationPinnedViewComponent
			},
			{
				path: 'locations/:id',
				component: LocationDetailsViewWrapperComponent
			},
			{
				path: 'customers/:customerId/sites/:siteId/locations/:id',
				pathMatch: 'full',
				component: LocationDetailsViewWrapperComponent
			},
			{
				path: 'collections',
				component: CollectionsComponent
			},
			{
				path: 'collections/pinned',
				pathMatch: 'full',
				component: CollectionPinnedViewComponent
			},
			{
				path: 'collections/:id',
				component: CollectionDetailsViewWrapperComponent
			},
			{
				path: 'customers/:customerId/sites/:siteId/collections/:id',
				pathMatch: 'full',
				component: CollectionDetailsViewWrapperComponent
			},
			{
				path: 'equipments-v2',
				component: EquipmentsV2Component
			},
			{
				path: 'equipment-details',
				component: EquipmentDetailsV2Component
			},
		]
	},
	{
		path: 'error',
		component: ErrorComponent
	},
	{
		path: 'no-access',
		component: NoAccessComponent
	},
	{
		path: 'terms-and-conditions',
		component: TermsAndConditionsComponent,
		canDeactivate: [CanDeactivateGuard]
	},
	{
		path: '**',
		redirectTo: 'equipments'
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes, {
    useHash: false,
})],
	exports: [RouterModule]
})

export class AppRoutingModule { }
