<div class="entity-row" *ngFor="let kpiPoint of points; trackBy: trackByFn">
    <ng-container [ngSwitch]="kpiPoint.signalType">
        <ng-container *ngSwitchCase="'Binary'">
            <div
                class="first-col"
                [ngClass]="{
                    'is-overridden':
                        kpiPoint.isWritable && kpiPoint.isOverridden
                }"
            >
                <span>{{ kpiPoint.comment || kpiPoint.sourceDescription || kpiPoint.classLabel }}</span>
            </div>
            <div
                class="mid-col flex align-items-center justify-content-end margin-left-auto"
            >
                <i
                    class="pi pi-lock is-overridden"
                    *ngIf="kpiPoint.isWritable && kpiPoint.isOverridden"
                    pTooltip="Point Overridden"
                    (click)="openResetConfirmationDialog(kpiPoint)"
                ></i>

                <p-inputSwitch
                    styleClass="default-switch__black"
                    [disabled]="!kpiPoint.isWritable || !userHasWritePermission"
                    [(ngModel)]="kpiPoint.presentValue"
                    (onChange)="handleChange(kpiPoint)"
                >
                </p-inputSwitch>
                <span class="kpi-unit" *ngIf="kpiPoint.presentValue">On</span>
                <span class="kpi-unit" *ngIf="!kpiPoint.presentValue">Off</span>
            </div>
        </ng-container>
        <ng-container *ngSwitchDefault>
            <div
                class="first-col"
                [ngClass]="{
                    'is-overridden':
                        kpiPoint.isWritable && kpiPoint.isOverridden
                }"
            >
                <span>{{ kpiPoint.comment || kpiPoint.sourceDescription || kpiPoint.classLabel }}</span>
            </div>

            <div
                class="mid-col flex align-items-center justify-content-end margin-left-auto"
            >
                <i
                    class="pi pi-lock is-overridden"
                    *ngIf="kpiPoint.isWritable && kpiPoint.isOverridden"
                    pTooltip="Point Overridden"
                    (click)="openResetConfirmationDialog(kpiPoint)"
                ></i>

                <div
                    class="form-group"
                    *ngIf="
                        kpiPoint.isWritable && userHasWritePermission;
                        else kpiTextValue
                    "
                >
                    <input
                        #inputRef
                        class="small-input text-right"
                        type="text"
                        pInputText
                        (focus)="onFocus($event, kpiPoint)"
                        [(ngModel)]="kpiPoint.presentValue"
                    />

                    <p-progressSpinner
                        *ngIf="kpiPoint.requestInProgress"
                        [style]="{ width: '30px', height: '30px' }"
                        styleClass="custom-spinner"
                        strokeWidth="3"
                        fill="var(--surface-ground)"
                        animationDuration="1s"
                    >
                    </p-progressSpinner>

                    <ng-container
                        *ngIf="
                            kpiPoint.changeActive && !kpiPoint.requestInProgress
                        "
                    >
                        <p-button
                            styleClass="turquoise"
                            icon="pi pi-check"
                            (click)="confirmWrite(kpiPoint, inputRef)"
                        >
                        </p-button>
                        <p-button
                            styleClass="turquoise"
                            icon="pi pi-times"
                            (click)="cancelWrite(kpiPoint, inputRef)"
                        >
                        </p-button>
                    </ng-container>
                </div>
                <ng-template #kpiTextValue>
                    <span>{{ kpiPoint.presentValue }}</span>
                </ng-template>
                <span class="kpi-unit" [innerHTML]="kpiPoint.htmlSymbol"></span>
            </div>
        </ng-container>
    </ng-container>
</div>
