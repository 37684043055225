@if (alerts.length) {
    @for (alert of alertsToRender; track alert.alertUUID) {
        <div class="flex justify-content-between entity-row px-2">
            <div class="w-2">{{ alert.priority }}</div>
            <div class="w-2">{{ alert.type }}</div>
            <div class="w-4">{{ alert.label }}</div>
            <div class="w-2">
                @if (alert.status === 'OPEN') {
                    Open
                } @else {
                    In Progress
                }
            </div>
            <div>
                <button
                    pButton
                    type="button"
                    icon="pi pi-bell"
                    class="bell-button"
                ></button>
            </div>
        </div>
    }
} @else {
    <div class="mb-3">
        No active Smart Alerts
    </div>
}
