import {Platform} from "@angular/cdk/platform";
import {
    Component,
    Input,
    OnInit,
    Renderer2,
    OnDestroy,
} from "@angular/core";
import {ConfirmationService} from "primeng/api";
import {Subscription, forkJoin} from "rxjs";
import {BINARY, ONE_HOUR_IN_SECONDS} from "src/app/config/constants";
import {ActiveOverrides, DeviceStatus, Point} from "src/app/models/dashboard";
import {BackendService} from "src/app/services/backend/backend.service";
import {NotificationService} from "src/app/services/notification/notification.service";
import {StoreService} from "src/app/services/store.service";

const P8 = "P8";
const RUNDOWN = "rundown";

@Component({
    selector: "cc-entity-points",
    templateUrl: "./entity-points.component.html",
    styleUrls: ["./entity-points.component.scss"],
})
export class EntityPointsComponent implements OnInit, OnDestroy {
    @Input()
    points: Point[];

    @Input()
    pointsStatus?: { [key: string]: DeviceStatus } = {};

    requestInProgress: boolean = false;
    isMobileDevice: boolean = false;
    userHasWritePermission: boolean = true;

    private subs = new Subscription();

    constructor(
        private renderer: Renderer2,
        private backendService: BackendService,
        private notificationService: NotificationService,
        private storeService: StoreService,
        private platform: Platform,
        private confirmationService: ConfirmationService
    ) {
    }

    ngOnInit(): void {
        if (this.platform.IOS || this.platform.ANDROID) {
            this.isMobileDevice = true;
        }

        this.userHasWritePermission =
            this.storeService.getUserWritePermission();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

    handleChange(point: Point) {
        this.storeService.addPointToLock(point);
        this.writeCommandPoint(point);
    }

    trackByFn(index: number, point: Point) {
        return point.pointUUID;
    }

    writeCommandPoint(point: Point, inputRef?: any) {
        point.requestInProgress = true;
        const priority = "PRIO_8";
        let dataToSend = {
            dataPointId: point.uri.replace("urn:uuid:", ""),
            rollbackTimer: ONE_HOUR_IN_SECONDS,
            value: null,
        };

        if (point.signalType === BINARY) {
            dataToSend.value = point.presentValue ? 1 : 0;
        } else {
            dataToSend.value = parseInt(<string>point.presentValue);
        }

        this.backendService.writeCommand(dataToSend, priority).subscribe(
            (response) => {
                if (response[0].success) {
                    point.changeActive = false;
                    point.requestInProgress = false;
                    point.isOverridden = true;

                    this.storeService.removePointFromLock(point);
                    this.storeService.announceCanRemoveOverrideChange(true);

                    if (inputRef) {
                        this.renderer.removeClass(inputRef, "input-on-write");
                    }

                    this.notificationService.addSuccessMessage(
                        "Success",
                        "Change successfully.\n Change will last for 1 hour."
                    );
                } else {
                    point.changeActive = false;
                    point.requestInProgress = false;
                    point.isOverridden = false;
                    this.notificationService.addWarnMessage(
                        "Warning",
                        response[0].result.message
                    );

                    if (point.signalType === BINARY) {
                        point.presentValue = !point.presentValue;
                    } else {
                        point.presentValue = point.onFocusValue;
                    }
                }
            },
            (err) => {
                this.notificationService.addErrorMessage(
                    "Error",
                    "Change failed."
                );
                point.requestInProgress = false;
            }
        );
    }

    onFocus(event: any, point: Point) {
        point.onFocusValue = point.presentValue;
        this.renderer.addClass(event.target, "input-on-write");
        point.changeActive = true;
        this.storeService.addPointToLock(point);
    }

    confirmWrite(point: Point, inputRef: any) {
        this.writeCommandPoint(point, inputRef);
    }

    cancelWrite(point: Point, inputRef: any) {
        point.changeActive = false;
        point.presentValue = point.onFocusValue;
        this.storeService.removePointFromLock(point);
        this.renderer.removeClass(inputRef, "input-on-write");
    }

    computeOverrideMessage(activeOverrides: ActiveOverrides) {
        let message = "";

        if (activeOverrides.physical) {
            message = message + "Physical";
        }

        if (
            !activeOverrides.priority1 &&
            !activeOverrides.priority8 &&
            activeOverrides.timer
        ) {
            message = message ? message + " / Software" : message + "Software";
        }

        if (activeOverrides.priority1) {
            message = message
                ? message + " / Software P1"
                : message + "Software P1";
        }

        if (activeOverrides.priority8) {
            message = message ? message + " / Software" : message + "Software";
        }

        return message || "N/A";
    }

    openResetConfirmationDialog(point: Point) {
        this.subs.add(
            forkJoin([
                this.backendService.getDataPointDetails(point.pointUUID),
                this.backendService.getDataPointsRollbackInfo(point.pointUUID),
            ]).subscribe((responseArray) => {
                const activeOverrides = responseArray[0]?.activeOverrides;
                const rollbackInfo: any = responseArray[1];

                if (activeOverrides) {
                    point.activeOverrides = activeOverrides;
                }

                let rollbackTimer = "Permanent";
                let shouldRemoveOverride = {text: "", value: false};
                let overrideType = P8;

                if (this.userHasWritePermission) {
                    if (
                        (!point.activeOverrides.priority1 &&
                            !point.activeOverrides.priority8 &&
                            point.activeOverrides.timer) ||
                        (!point.activeOverrides.physical &&
                            point.activeOverrides.priority8)
                    ) {
                        shouldRemoveOverride.text =
                            "Do you want to remove override?";
                        shouldRemoveOverride.value = true;
                    }
                }

                this.storeService.announceCanRemoveOverrideChange(
                    shouldRemoveOverride.value
                );

                if (rollbackInfo.length) {
                    rollbackTimer = this.convertMsToHM(
                        rollbackInfo[0].rollbackTimer
                    );
                    overrideType = RUNDOWN;
                }

                this.confirmationService.confirm({
                    message: `Data Point : ${
                        point.comment || point.classLabel
                    } <br />
							Override Time : ${rollbackTimer} <br />
							Override Type : ${this.computeOverrideMessage(point.activeOverrides)} <br />
						  ${shouldRemoveOverride.text}`,
                    header: "Override Reset",
                    icon: "pi pi-exclamation-triangle",
                    accept: () => {
                        this.removeOverride(point, overrideType);
                    },
                });
            })
        );
    }

    padTo2Digits(num) {
        return num.toString().padStart(2, "0");
    }

    convertMsToHM(milliseconds: number) {
        let seconds = Math.floor(milliseconds / 1000);
        let minutes = Math.floor(seconds / 60);
        let hours = Math.floor(minutes / 60);

        seconds = seconds % 60;
        minutes = minutes % 60;

        return `${this.padTo2Digits(hours)}:${this.padTo2Digits(minutes)} mins`;
    }

    public removeOverride(point: Point, type: string) {
        if (type === P8) {
            this.subs.add(
                this.backendService
                    .removeOverrideOnP8(point.pointUUID)
                    .subscribe(
                        (response) => {
                            if (response[0].success) {
                                this.notificationService.addSuccessMessage(
                                    "Success",
                                    "Override has been removed."
                                );
                                point.isOverridden = false;
                            } else {
                                this.notificationService.addWarnMessage(
                                    "Warning",
                                    response[0].result.message
                                );
                            }
                        },
                        (err) => {
                            this.notificationService.addWarnMessage(
                                "Warning",
                                "Override could not be removed. Please try again later."
                            );
                        }
                    )
            );
        }

        if (type === RUNDOWN) {
            this.subs.add(
                this.backendService
                    .removeOverrideWithRunDownTimer(point.pointUUID)
                    .subscribe(
                        (response) => {
                            if (response[0].success) {
                                this.notificationService.addSuccessMessage(
                                    "Success",
                                    "Override has been removed."
                                );
                                point.isOverridden = false;
                            } else {
                                this.notificationService.addWarnMessage(
                                    "Warning",
                                    response[0].result.message
                                );
                            }
                        },
                        (err) => {
                            this.notificationService.addWarnMessage(
                                "Warning",
                                "Override could not be removed. Please try again later."
                            );
                        }
                    )
            );
        }
    }

    isPointOffline(dataPointId: string): boolean {
        return this.pointsStatus[dataPointId] === "OFFLINE";
    }
}
