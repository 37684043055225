'use strict';

Object.defineProperty(exports, '__esModule', {
  value: true
});
exports.ReassemblyDuplexConnection = void 0;
var _LiteBuffer = require('./LiteBuffer');
var _rsocketFlowable = require('rsocket-flowable');
var _RSocketFrame = require('./RSocketFrame');
function _defineProperty(obj, key, value) {
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
class ReassemblyDuplexConnection {
  constructor(source) {
    this._source = source;
  }
  sendOne(frame) {
    this._source.sendOne(frame);
  }
  send(input) {
    this._source.send(input);
  }
  receive() {
    return this._source.receive().lift(actual => new ReassemblySubscriber(actual));
  }
  close() {
    this._source.close();
  }
  connect() {
    this._source.connect();
  }
  connectionStatus() {
    return this._source.connectionStatus();
  }
}
exports.ReassemblyDuplexConnection = ReassemblyDuplexConnection;
class ReassemblySubscriber {
  constructor(actual) {
    _defineProperty(this, '_framesReassemblyMap', new Map());
    this._actual = actual;
  }
  request(n) {
    this._subscription.request(n);
  }
  cancel() {
    this._subscription.cancel();
    this._framesReassemblyMap.clear();
  }
  onSubscribe(s) {
    if (this._subscription == null) {
      this._subscription = s;
      this._actual.onSubscribe(this);
    } else {
      s.cancel();
    }
  }
  onComplete() {
    this._actual.onComplete();
  }
  onError(error) {
    this._actual.onError(error);
  }
  onNext(frame) {
    const streamId = frame.streamId;
    if (streamId !== _RSocketFrame.CONNECTION_STREAM_ID) {
      const hasFollowsFlag = (0, _RSocketFrame.isFollows)(frame.flags);
      const hasCompleteFlag = (0, _RSocketFrame.isComplete)(frame.flags);
      const isCancelOrError = frame.type === _RSocketFrame.FRAME_TYPES.ERROR || frame.type === _RSocketFrame.FRAME_TYPES.CANCEL;
      const storedFrame = this._framesReassemblyMap.get(streamId);
      if (storedFrame) {
        if (isCancelOrError) {
          this._framesReassemblyMap.delete(streamId);
        } else {
          if (storedFrame.metadata && frame.metadata) {
            storedFrame.metadata = concatContent(storedFrame.metadata, frame.metadata);
          }
          if (storedFrame.data && frame.data) {
            storedFrame.data = concatContent(storedFrame.data, frame.data);
          } else if (!storedFrame.data && frame.data) {
            storedFrame.data = frame.data;
          }
          if (!hasFollowsFlag || hasCompleteFlag) {
            if (hasCompleteFlag) {
              storedFrame.flags |= _RSocketFrame.FLAGS.COMPLETE;
            }
            this._framesReassemblyMap.delete(streamId);
            this._actual.onNext(storedFrame);
          }
          return;
        }
      } else if (hasFollowsFlag && !hasCompleteFlag && !isCancelOrError) {
        this._framesReassemblyMap.set(streamId, frame);
        return;
      }
    }
    this._actual.onNext(frame);
  }
}
const concatContent = (a, b) => {
  switch (a.constructor.name) {
    case 'String':
      return a + b;
    case 'Uint8Array':
      const result = new Uint8Array(a.length + b.length);
      result.set(a);
      result.set(b, a.length);
      return result;
    default:
      return _LiteBuffer.LiteBuffer.concat([a, b]);
  }
};