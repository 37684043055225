<div class="content">
    <ng-container *ngIf="!haveSameTimezoneFlag && !isLoading">
        <p>Comparing Data Point with different time zones is not allowed, please assign a customer and site to view
            data.</p>
    </ng-container>

    <ng-container *ngIf="haveSameTimezoneFlag">
        <div class="flex flex-column">
            <div class="flex flex-row justify-content-between p-4">
                <div class="calendar-wrapper" *ngIf="!this.config.data.showPreview">
                    <span class="cbms-margin-right"><strong>Filter by time</strong></span>
                    <p-calendar
                        [styleClass]="'telemetry-dialog'"
                        #calendar
                        [(ngModel)]="rangeDates"
                        selectionMode="range"
                        [yearNavigator]="true"
                        yearRange="2000:{{maxYearRangeLimit}}"
                        dateFormat="dd.mm.yy"
                        [maxDate]="maxDateValue"
                        [readonlyInput]="true"
                        placeholder="Select range"
                        [showTime]="true"
                        (onSelect)="onIntervalSelected($event)"
                    >
                        <p-header>
                            <div
                                style="
                                padding: 0 0 10px 0;
                                margin: 0 0 10px 0;
                                border-bottom: 1px #404040 solid;
                            "
                                class="flex flex-row align-items-center gap-3"
                            >
                                <p-dropdown
                                    [options]="timeOptionList"
                                    [(ngModel)]="selectedTime"
                                    optionLabel="label"
                                    placeholder="Select Time"
                                    (onChange)="onIntervalOptionSelected()"
                                >
                                </p-dropdown>
                                <button
                                    pButton
                                    class="p-button-raised p-button-text button-turquoise-bg"
                                    (click)="onFilter(); calendar.toggle()"
                                >
                                    Apply
                                </button>
                            </div>
                        </p-header>
                    </p-calendar>
                </div>

                <div class="legend">
                    <span class="cbms-margin-right">Display:</span>
                    <p-radioButton
                        class="cbms-margin-right"
                        name="legendField"
                        value="objectName"
                        label="Datapoint Name"
                        [(ngModel)]="selectedlegendField"
                        (onClick)="updateGraphLegend()"
                    ></p-radioButton>
                    <p-radioButton
                        class="cbms-margin-right"
                        name="legendField"
                        value="description"
                        label="Source Description"
                        [(ngModel)]="selectedlegendField"
                        (onClick)="updateGraphLegend()"
                    ></p-radioButton>
                    <p-radioButton
                        name="legendField"
                        value="customName"
                        label="Custom Name"
                        [(ngModel)]="selectedlegendField"
                        (onClick)="updateGraphLegend()"
                    ></p-radioButton>
                    <div>
                        <button
                            pButton
                            class="cbms-margin-right p-button-raised button-turquoise-bg"
                            (click)="selectAllSeries()"
                        >
                            Select All Data Points
                        </button>
                        <button
                            pButton
                            class="cbms-margin-right p-button-raised button-turquoise-bg"
                            (click)="deselectAllSeries()"
                        >
                            Deselect All Data Points
                        </button>

                        <button
                            pButton
                            class="cbms-margin-right p-button-raised button-turquoise-bg"
                            (click)="resetZoom()"
                            [disabled]="shouldDisableZoom()"
                        >
                            Reset Zoom
                        </button>
                        <button
                            pButton
                            class="p-button-raised p-button-text button-turquoise-bg"
                            (click)="zoomOut()"
                            [disabled]="shouldDisableZoom()"
                        >
                            <i class="pi pi-search-minus"></i>
                        </button>
                    </div>
                </div>

            </div>

            <div class="cbms-spinner-wrapper">
            <span *ngIf="timeseries === null && !isLoading"
            >No data in specified time window</span
            >
                <mat-spinner
                    *ngIf="isLoading"
                    [diameter]="30"
                    class="cbms-spinner"
                ></mat-spinner>
            </div>

            <div class="highchart-wrapper px-3">
                <div class="chart-nav">
                    <i class="pi pi-angle-left" (click)="moveChartToLeft()"></i>
                </div>
                <div style="flex-grow: 1;">
                    <cc-timeseries-chart
                        #chart
                        *ngIf="timeseries != null && timeseries.data.length > 0"
                        [chartConfig]="timeseries"
                        [timezone]="siteTimezone"
                    >
                    </cc-timeseries-chart>
                </div>
                <div class="chart-nav">
                    <i class="pi pi-angle-right" (click)="moveChartToRight()"></i>
                </div>
            </div>
        </div>

    </ng-container>
</div>

<div class="footer">
    <button
        pButton
        class="p-button-raised p-button-text button-turquoise-bg"
        (click)="close()"
    >
        Close
    </button>
</div>
