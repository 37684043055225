<div class="flex">
    <div class="mobile-navigation">
        <eon-ui-button
            class="margin-right-05"
            icon="funnel"
            scheme="{{ filterButtonScheme }}"
            (click)="showFiltersMenuOnMobile()"
        ></eon-ui-button>
        <eon-ui-button
            icon="search"
            scheme="{{ filterButtonScheme }}"
            (click)="toggleSearchOnMobile()"
        >
        </eon-ui-button>
    </div>
    <div class="desktop-navigation">
        <span
            class="w-full p-float-label margin-right-26 custom-mm"
            *ngIf="filterOptions.length"
        >
            <p-multiSelect
                inputId="eq-filter-select"
                [group]="true"
                [options]="filterOptions"
                [filter]="true"
                optionLabel="label"
                optionValue="value"
                [(ngModel)]="selectedClassList"
            >
                <ng-template let-group pTemplate="group">
                    <div class="flex align-items-center">
                        <span
                            class="small-text gray-color margin-bottom-default"
                            >{{ group.label }}</span
                        >
                    </div>
                </ng-template>
                <ng-template pTemplate="footer">
                    <eon-ui-button
                        class="margin-right-05"
                        size="small"
                        scheme="turquoise"
                        (click)="filterEquipments()"
                        text="Apply Filters"
                    ></eon-ui-button>
                    <eon-ui-button
                        size="small"
                        scheme="turquoise"
                        (click)="clearFilters()"
                        text="Clear Filters"
                    ></eon-ui-button>
                </ng-template>
            </p-multiSelect>
            <label for="eq-filter-select">All {{ category | titlecase }} types</label>
        </span>

        <div class="search-group">
            <button type="button" class="search" (click)="filterEquipments()">
                <img src="assets/svg/search.svg" width="20" height="25" />
            </button>
            <input
                type="text"
                pInputText
                placeholder="Search by name..."
                [(ngModel)]="searchText"
                (keyup.backspace)="checkForEmptyText()"
                (keyup.enter)="filterEquipments()"
            />
            <button
                *ngIf="searchText"
                type="button"
                class="clear"
                (click)="clearSearchInput()"
            >
                <img src="assets/svg/close.svg" width="14" height="14" />
            </button>
        </div>
    </div>
</div>
<div
    class="search-wrapper"
    [ngClass]="isSearchActive ? 'isActive' : ''"
    id="search"
>
    <div class="search-group">
        <button type="button" class="search" (click)="filterEquipments()">
            <img src="assets/svg/search.svg" width="20" height="25" />
        </button>
        <input
            type="text"
            pInputText
            placeholder="Search by name..."
            [(ngModel)]="searchText"
            (keyup.backspace)="checkForEmptyText()"
            (keyup.enter)="filterEquipments()"
        />
        <button
            *ngIf="searchText"
            type="button"
            class="clear"
            (click)="clearSearchInput()"
        >
            <img src="assets/svg/close.svg" width="14" height="14" />
        </button>
    </div>
</div>
<div
    class="slide-in-box-wrapper"
    [ngClass]="isFilterActive ? 'isActive' : ''"
    id="search"
>
    <eon-ui-button
        class="close-button-top-right"
        icon="close"
        size="small"
        scheme="discreet"
        (click)="closeFilterOnMobile()"
    ></eon-ui-button>

    <div
        class="slide-in-box-body slide-in-box-body__no-padding slide-in-box-body__full-height"
    >
        <p-listbox
            styleClass="custom-listbox custom-listbox__height-55vh"
            scrollHeight="auto"
            [options]="filterOptions"
            [group]="true"
            [(ngModel)]="selectedClassList"
            [multiple]="true"
            [checkbox]="true"
            [filter]="true"
        >
            <ng-template pTemplate="header">
                <div
                    class="flex w-full align-items-center justify-content-between"
                >
                    <span>All Equipment types</span>
                </div>
            </ng-template>
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span class="small-text gray-color margin-bottom-default">{{
                        group.label
                    }}</span>
                </div>
            </ng-template>
        </p-listbox>
    </div>
    <div class="slide-in-box-footer">
        <eon-ui-background color="eon-turquoise" class="hydrated">
            <eon-ui-button
                inner-background="eon-white"
                text="Apply Filters"
                full-width-on-mobile="true"
                rank="secondary"
                (click)="filterEquipments()"
            >
            </eon-ui-button>
            <br />
            <eon-ui-button
                inner-background="eon-turquoise"
                text="Clear Filters"
                full-width-on-mobile="true"
                rank="secondary"
                (click)="clearFilters()"
            >
            </eon-ui-button>
        </eon-ui-background>
    </div>
</div>
