import { Component, OnInit } from "@angular/core";
import moment from "moment";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { DAYS } from "src/app/config/constants";
import { NotificationService } from "src/app/services/notification/notification.service";
import { _ } from "lodash";

const OPEN_TIME = "openTime";
@Component({
    selector: "cc-edit-building-opening-time",
    templateUrl: "./edit-building-opening-time.component.html",
    styleUrl: "./edit-building-opening-time.component.scss",
})
export class EditBuildingOpeningTimeComponent implements OnInit {
    siteSchedule: any;
    origionalSiteSchedule: any;
    days = DAYS;
    readonly defaultOpenTime = "8:00";
    readonly defaultCloseTime = "17:00";
    readonly COMPARE_TIME_ERR = "Closing time must be after opening time.";
    readonly FORMAT_TIME_ERR = "Please select a 15-minute time interval";
    readonly REQUIRED_FIELD_ERR = "This field is required";
    readonly ERR_LIST = [this.FORMAT_TIME_ERR, this.REQUIRED_FIELD_ERR];
    isValidData = false;
    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private notificationServ: NotificationService
    ) {}

    ngOnInit(): void {
        this.siteSchedule = _.cloneDeep(this.config.data?.siteSchedule);
        this.origionalSiteSchedule = _.cloneDeep(
            this.config.data?.siteSchedule
        );
        this.days.forEach((day) => {
            this.siteSchedule[day].isOpen = !!this.siteSchedule[day]?.openTime;
        });
    }

    onSave() {
        if (this.isValidData) {
            this.days.forEach((day) => {
                if (this.siteSchedule[day]?.isOpen === true) {
                    if (
                        this.siteSchedule[day]?.openTime &&
                        typeof this.siteSchedule[day]?.openTime === "object"
                    ) {
                        this.siteSchedule[day].openTime = moment(
                            this.siteSchedule[day]?.openTime
                        ).format("HH:mm");
                    }
                    if (
                        this.siteSchedule[day]?.closeTime &&
                        typeof this.siteSchedule[day]?.closeTime === "object"
                    ) {
                        this.siteSchedule[day].closeTime = moment(
                            this.siteSchedule[day]?.closeTime
                        ).format("HH:mm");
                    }
                } else {
                }
            });
            this.ref.close({ siteSchedule: this.siteSchedule });
        }
    }

    onCancel() {
        this.ref.close();
    }

    handleTimeSwitchOff(day: string) {
        if (!this.siteSchedule[day].isOpen) {
            this.siteSchedule[day].openTime = "";
            this.siteSchedule[day].closeTime = "";
            this.siteSchedule[day].error = null;
        } else {
            this.setError(day, "openTime", this.REQUIRED_FIELD_ERR);
            this.setError(day, "closeTime", this.REQUIRED_FIELD_ERR);
        }
        this.validateSiteSchedule();
    }

    /**
     * @description validate time's format, correct format: HH:MM (MM is one of the following list: 00, 15, 30, 45)
     * @param day
     * @param partOfDay
     * @returns error message if any
     */
    isValidTimeFormat(day: string, partOfDay: string): string {
        let errMsg = "";
        let timeValue = this.siteSchedule[day][partOfDay];
        //validate format
        if (timeValue && typeof timeValue === "object") {
            const timeStr = moment(timeValue).format("HH:mm");
            if (!["00", "15", "30", "45"].includes(timeStr.split(":")[1])) {
                errMsg = this.FORMAT_TIME_ERR;
            }
        }
        return errMsg;
    }

    /**
     * @description if a day is active it must  have valid openTime and closeTime
     * @returns true if site schedule has valid values, else return false
     */
    validateSiteSchedule(): void {
        this.isValidData = true;
        for (const day of this.days) {
            // if there is error message, this means the siteSchedule is invalid
            if (
                this.siteSchedule[day]?.error?.openTimeErr ||
                this.siteSchedule[day]?.error?.closeTimeErr
            )
                this.isValidData = false;
            if (!this.isValidData) break;
        }
    }

    /**
     * @description openTime must before closeTime
     * @param day
     * @returns error message if any
     */
    compareTime(day: string): string {
        let errMsg = "";
        const openTime =
            typeof this.siteSchedule[day]?.openTime === "object"
                ? moment(this.siteSchedule[day]?.openTime).format("HH:mm")
                : this.siteSchedule[day]?.openTime;
        const closeTime =
            typeof this.siteSchedule[day]?.closeTime === "object"
                ? moment(this.siteSchedule[day]?.closeTime).format("HH:mm")
                : this.siteSchedule[day]?.closeTime;
        if (openTime && closeTime) {
            if (openTime >= closeTime) {
                errMsg = this.COMPARE_TIME_ERR;
            }
        }

        return errMsg;
    }

    setError(day: string, partOfDay: string, errMsg: string): void {
        if (this.siteSchedule[day]?.error) {
            switch (partOfDay) {
                case OPEN_TIME:
                    this.siteSchedule[day].error.openTimeErr = errMsg;
                    break;
                default:
                    this.siteSchedule[day].error.closeTimeErr = errMsg;
            }
        } else {
            this.siteSchedule[day].error =
                partOfDay === OPEN_TIME
                    ? { openTimeErr: errMsg }
                    : { closeTimeErr: errMsg };
        }
    }

    /**
     * @description check if openTime and closeTime are in correct format and order
     * @param day
     * @param partOfDay
     * @returns true if format (HH:MM) and order are correct (openTime is before closeTime), else return false
     */
    isValidTime(day: string, partOfDay: string): boolean {
        // validate time format
        let formatErrMsg = this.isValidTimeFormat(day, partOfDay);
        this.setError(day, partOfDay, formatErrMsg);
        if (formatErrMsg) {
            this.isValidData = false;
            return false;
        }
        //compare open time and close time
        let compareErrMsg = this.compareTime(day);
        if (compareErrMsg) {
            this.setError(day, partOfDay, compareErrMsg);
            this.isValidData = false;
            return false;
        }
        if (
            this.ERR_LIST.includes(
                this.siteSchedule[day].error?.openTimeErr || ""
            ) ||
            this.ERR_LIST.includes(
                this.siteSchedule[day].error?.closeTimeErr || ""
            )
        ) {
            this.isValidData = false;
            return false;
        }
        this.siteSchedule[day].error = null;
        this.validateSiteSchedule();
        return true;
    }
}
