<div class="historical-data-wrapper">
    <div class="flex w-full align-items-center justify-content-between">


        <div class="flex flex-row">

            <eon-ui-button class="mobile-view margin-right-05" icon="funnel" scheme="{{filterButtonScheme}}"
                           (click)="showHistoriesSelectionMenuOnMobile()"></eon-ui-button>


            <div class="p-float-label margin-right-26 custom-mm desktop-view">
                <p-multiSelect #historiesDD inputId="historical-data-select" [group]="true" [options]="historiesOptions"
                               [showToggleAll]="false" [(ngModel)]="selectedHistories"
                               (onChange)="onHistoriesSelectionChange()">
                    <ng-template let-group pTemplate="group">
                        <div class="flex align-items-center">
                            <span class="small-text gray-color margin-bottom-default">{{ group.label }}</span>
                        </div>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <eon-ui-button class="margin-right-05" size="small" scheme="turquoise"
                                       (click)="applyHistories($event, historiesDD)" text="Apply">
                        </eon-ui-button>
                        <eon-ui-button size="small" scheme="turquoise" (click)="clearHistories($event, historiesDD)"
                                       text="Clear">
                        </eon-ui-button>
                    </ng-template>
                </p-multiSelect>
                <label for="historical-data-select">Please select histories</label>
            </div>

            <div class="calendar-wrapper custom-calendar-picker">
                <p-calendar #calendar [(ngModel)]="rangeDates" selectionMode="range" [yearNavigator]="true"
                            yearRange="2000:2030" dateFormat="dd.mm.yy" [maxDate]="maxDateValue" [readonlyInput]="true"
                            placeholder="Select range" (onSelect)="onIntervalSelected($event)">
                    <ng-template pTemplate="header">
                        <div class="header flex justify-content-between align-items-center"
                             style="padding:0 0 10px 0; margin:0 0 10px 0; border-bottom: 1px #404040 solid;">
                            <p-dropdown [options]="timeOptionList" [(ngModel)]="selectedTime" optionLabel="label"
                                        placeholder="Select Time" (onChange)="onIntervalOptionSelected()">
                            </p-dropdown>
                            <eon-ui-button class="margin-right-05" size="small" scheme="turquoise" text="Apply"
                                           (click)="onFilterByDate(); calendar.toggle()">
                            </eon-ui-button>
                        </div>
                    </ng-template>
                </p-calendar>
            </div>
        </div>


        <div class="flex align-items-center">
            <eon-ui-button class="desktop-view"
                           scheme="turquoise"
                           (click)="exportToCsv()"
                           text="Export history"
                           [disabled]="!showChart"
            >
            </eon-ui-button>
            <eon-ui-button class="mobile-view"
                           scheme="turquoise"
                           (click)="exportToCsv()"
                           icon="download"
                           [disabled]="!showChart">
            </eon-ui-button>
        </div>


    </div>

    <div class="chart-wrapper" *ngIf="showChart">
        <highcharts-chart [Highcharts]="Highcharts" [options]="chartOptions" [(update)]="updateFlag" [oneToOne]="true"
                          style="display: block;"></highcharts-chart>
    </div>

    <div class="grid" *ngIf="!showChart">
        <div class="col-12 text-center" style="margin-top: 4rem;">
            <p [innerHTML]="noHistoriesMessage"></p>
        </div>
    </div>

</div>

<div class="slide-in-box-wrapper" [ngClass]="isHistorySelectionActive ? 'isActive' : ''">
    <eon-ui-button class="close-button-top-right" icon="close" size="small" scheme="discreet"
                   (click)="closeHistoriesSelectionOnMobile()"></eon-ui-button>

    <div class="slide-in-box-body slide-in-box-body__no-padding slide-in-box-body__full-height">
        <p-listbox styleClass="custom-listbox custom-listbox__height-55vh" [options]="historiesOptions" [group]="true"
                   [showToggleAll]="false" [(ngModel)]="selectedHistories" [multiple]="true" [checkbox]="true"
                   [filter]="true"
                   (onChange)="onHistoriesSelectionChange()">
            <ng-template pTemplate="header">
                <div class="flex w-full align-items-center justify-content-between">
                    <span>Please select histories</span>
                </div>
            </ng-template>
            <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                    <span class="small-text gray-color margin-bottom-default">{{ group.label }}</span>
                </div>
            </ng-template>
        </p-listbox>
    </div>
    <div class="slide-in-box-footer">
        <eon-ui-background color="eon-turquoise" class="hydrated">
            <eon-ui-button inner-background="eon-white" text="Apply Selection" full-width-on-mobile="true"
                           rank="secondary" (click)="applyHistoriesOnMobile()">
            </eon-ui-button>
            <br>
            <eon-ui-button inner-background="eon-turquoise" text="Clear Selection" full-width-on-mobile="true"
                           rank="secondary" (click)="clearHistoriesOnMobile()">
            </eon-ui-button>
        </eon-ui-background>
    </div>
</div>
