import { Injectable } from '@angular/core';
import { Point } from '../models/dashboard';
import { BehaviorSubject, ReplaySubject, Observable, shareReplay } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class StoreService {

	private _equipmentPointsToLock: {[key: string]: Point} = {};
	private _userHasWritePermission: boolean = false;
	
	private canRemoveOverrideSource = new BehaviorSubject<boolean>(null);
	canRemoveOverrideChanged$ = this.canRemoveOverrideSource.asObservable();

	private subject$ = new ReplaySubject<boolean>();

	constructor() { }

	get observable(): Observable<boolean> {
		return this.subject$.asObservable().pipe(shareReplay(1))
	}

	value(value: boolean) {
		this.subject$.next(value);
	}

	complete(): void {
		this.subject$.complete();
	}

	addPointToLock(point: Point) {
        this._equipmentPointsToLock[point.pointUUID] = point;
    }

	removePointFromLock(point: Point) {
		delete this._equipmentPointsToLock[point.pointUUID];
	}

    getLockedPoints() {
		return this._equipmentPointsToLock;
	}

	updateUserWritePermission(writePermission: boolean) {
		this._userHasWritePermission = writePermission;
	}

	getUserWritePermission() {
		return this._userHasWritePermission;
	}

	announceCanRemoveOverrideChange(value: boolean) {
		this.canRemoveOverrideSource.next(value);
	}
}
