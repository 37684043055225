<div class="detailed-entity-card">
    <div class="entity-card-header justify-content-between">
        <div class="entity-icon">
            <img width="48" height="48" src="assets/svg/Fan.svg" />
        </div>
        <div class="entity-details">
            <h2 class="entity-name">{{locationDetails?.label}}</h2>
            <p class="entity-class">{{locationDetails?.objectClassLabel}}</p>
        </div>
        <eon-ui-icon
            *ngIf="locationDetails?.pinned"
            class="top-right-position cursor-pointer"
            name="bookmark"
            (click)="togglePin($event, locationDetails)"
        ></eon-ui-icon>
        <eon-ui-icon
            *ngIf="!locationDetails?.pinned"
            class="top-right-position cursor-pointer"
            name="bookmark_outline"
            (click)="togglePin($event, locationDetails)"
        ></eon-ui-icon>
    </div>
    <div class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Brick Schema Relationships</b></h2>
        </div>
    </div>
    <div class="entity-card-content">
        <div class="entity-top-details">
            <p><b>Sub Locations:</b>&nbsp;
                <ng-container *ngIf="locationDetails?.hasPartRelationsList.length">
                    <a href="" *ngFor="let item of locationDetails?.hasPartRelationsList" [routerLink]="['/locations', item.id]">{{item.name}}</a>
                </ng-container>
                <ng-container *ngIf="!locationDetails?.hasPartRelationsList.length">
                    <span>N/A</span>
                </ng-container>
            </p>
            <p><b>Is Location Of:</b> &nbsp;
                <ng-container *ngIf="locationDetails?.isLocationOfList.length">
                    <a href="" *ngFor="let item of locationDetails?.isLocationOfList" [routerLink]="[item.entityType === 'EQUIPMENT' ? '/equipments' : '/collections', item.id]">{{item.name}}</a>
                </ng-container>
                <ng-container *ngIf="!locationDetails?.isLocationOfList.length">
                    <span>N/A</span>
                </ng-container>
            </p>
        </div>
    </div>

    <div class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Smart Alerts</b></h2>
        </div>
    </div>

    <div class="entity-card-content">
        <cc-smart-alerts [alerts]="locationDetails?.smartAlerts"></cc-smart-alerts>
    </div>

    <div *ngIf="locationDetails?.sensorsAndStatusDatapointsList.length" class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Sensors & Status</b></h2>
        </div>
    </div>
    <div *ngIf="locationDetails?.sensorsAndStatusDatapointsList.length" class="entity-card-content">
        <cc-entity-points [points]="locationDetails.sensorsAndStatusDatapointsList" [pointsStatus]="pointsStatus"></cc-entity-points>
    </div>

    <div *ngIf="locationDetails?.alarmsDatapointsList.length" class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Alarms</b></h2>
        </div>
    </div>
    <div *ngIf="locationDetails?.alarmsDatapointsList.length" class="entity-card-content">
        <cc-entity-points [points]="locationDetails.alarmsDatapointsList" [pointsStatus]="pointsStatus"></cc-entity-points>
    </div>

    <div *ngIf="locationDetails?.commandsDatapointsList.length" class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Commands</b></h2>
        </div>
    </div>
    <div *ngIf="locationDetails?.commandsDatapointsList.length" class="entity-card-content">
        <cc-entity-points [points]="locationDetails.commandsDatapointsList" [pointsStatus]="pointsStatus"></cc-entity-points>
    </div>
    <div *ngIf="locationDetails?.setpointsAndParametersDatapointsList.length" class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Setpoints & Parameters</b></h2>
        </div>
    </div>
    <div *ngIf="locationDetails?.setpointsAndParametersDatapointsList.length" class="entity-card-content">
        <cc-entity-points [points]="locationDetails.setpointsAndParametersDatapointsList" [pointsStatus]="pointsStatus"></cc-entity-points>
    </div>
    <div *ngIf="locationDetails?.schedulesList.length" class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name"><b>Schedules</b></h2>
        </div>
    </div>
    <div *ngIf="locationDetails?.schedulesList.length" class="entity-card-content">
        <div class="entity-row" *ngFor="let schedule of locationDetails?.schedulesList;">
            <div class="first-col">
                <span>{{schedule.comment || schedule.classLabel}}</span>
            </div>
            <div class="mid-col flex align-items-center justify-content-end margin-left-auto">
                <span>{{schedule.presentValue}}</span>
                <span class="icon-btn">
                    <img class="cursor-pointer" src="assets/icons/exception.svg"
                        (click)="showExceptionSchedule(schedule.pointUUID)" />
                </span>
                <span class="icon-btn">
                    <img class="cursor-pointer" src="assets/icons/schedule.svg"
                        (click)="showSchedule(schedule.pointUUID)" />
                </span>
            </div>
        </div>
    </div>
    <div
        class="entity-card-footer flex align-items-center justify-content-between"
    >
        <eon-ui-icon
            name="more"
            aria-label="more"
            class="hydrated"
        ></eon-ui-icon
        >
        <cc-share-link *ngIf="locationLink" [link]="locationLink"></cc-share-link
        >
    </div>
</div>

<p-dialog
    header="Weekly Schedule"
    *ngIf="showScheduleDialog"
    [(visible)]="showScheduleDialog"
    [modal]="true"
    [breakpoints]="{ '640px': '90vw' }"
    [style]="{ width: '90vw', height: '95%' }"
>
    <cc-schedule-calendar
        *ngIf="showScheduleDialog"
        [scheduleUUID]="selectedScheduleUUID"
    ></cc-schedule-calendar>
</p-dialog>

<p-dialog
    header="Exception Schedule"
    *ngIf="showExceptionScheduleDialog"
    [(visible)]="showExceptionScheduleDialog"
    [modal]="true"
    [breakpoints]="{ '640px': '90vw' }"
    [style]="{ width: '90vw', height: '95%' }"
>
    <cc-exception-schedule-table
        *ngIf="showExceptionScheduleDialog"
        [exceptionScheduleUUID]="selectedExceptionScheduleUUID"
    ></cc-exception-schedule-table>
</p-dialog>
