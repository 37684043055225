import { Component } from '@angular/core';

@Component({
  selector: 'cc-equipments-v2',
  templateUrl: './equipments-v2.component.html',
  styleUrls: ['./equipments-v2.component.scss']
})
export class EquipmentsV2Component {

}
