import {
    Component,
    EventEmitter,
    Input,
    Output,
    Renderer2,
} from "@angular/core";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { EventNotificationService, EventType } from "src/app/services/notification/event-notification.service";

@Component({
    selector: "cc-filter-and-search",
    templateUrl: "./filter-and-search.component.html",
    styleUrls: ["./filter-and-search.component.scss"],
})
export class FilterAndSearchComponent {
    @Input() category: string;
    @Input() dataToFilter: any[];
	@Input() filterOptions: any[];
    @Output() onFilterChanged = new EventEmitter<{action: string, selectedClassList: string[], searchText: string}>();

    isSearchActive: boolean = false;
    isFilterActive: boolean = false;
    selectedValues: string[] = [];

    filterButtonScheme: string = "discreetDark";
	selectedSite: { siteId: string, customerId: string };
    searchText: string = "";
    selectedClassList: string[] = [];
    selectedFilters: string[] = [];
    firstSiteLoad = true;

    constructor(
        private renderer: Renderer2,
		private siteFilterService: SiteFilterService,
        private eventNotificationService: EventNotificationService
    ) {}

    ngOnInit() {
        this.siteFilterService.siteFilterChanged$.subscribe((selectedSite) => {
            if (selectedSite === null) {
                return;
            }
            if (this.firstSiteLoad) {			
				this.restoreFilters();
			} 
			if (!this.firstSiteLoad) {
				this.resetFilters();
			}
			
			this.firstSiteLoad = false;
        });
    }

    toggleSearchOnMobile() {
        this.isSearchActive = !this.isSearchActive;
        this.eventNotificationService.emittEvent({
            type: EventType.MOBILE_VIEW_SELECTED,
            payload: this.isSearchActive,
        });
    }

    showFiltersMenuOnMobile() {
        this.isFilterActive = true;
        this.renderer.addClass(document.body, "noscroll");
        this.renderer.addClass(
            document.getElementById("app-wrapper"),
            "noscroll"
        );
    }

    closeFilterOnMobile() {
        this.isFilterActive = false;
        this.renderer.removeClass(document.body, "noscroll");
        this.renderer.removeClass(
            document.getElementById("app-wrapper"),
            "noscroll"
        );
    }

    filterEquipments() {
        sessionStorage.setItem(`${this.category}-filters`, JSON.stringify({ searchText: this.searchText, selectedClassList: this.selectedClassList }));

		if (!this.selectedClassList.length) {
            this.onFilterChanged.emit({'action': 'apply', 'selectedClassList': [], 'searchText': this.searchText});
            return;
        }
        this.onFilterChanged.emit({'action': 'apply', 'selectedClassList': this.selectedClassList, 'searchText': this.searchText});
    }

	restoreFilters() {
		const filters = JSON.parse(sessionStorage.getItem(`${this.category}-filters`)) || null;

		if (filters) {
			this.searchText = filters.searchText;
			this.selectedClassList = filters.selectedClassList;
			this.filterButtonScheme = 'turquoise';
		}
	}

    resetFilters() {
        this.searchText = '';
		this.selectedClassList = [];
		this.filterButtonScheme = 'turquoise';
        sessionStorage.setItem(`${this.category}-filters`, JSON.stringify({ searchText: this.searchText, selectedClassList: this.selectedClassList }));
    }

	clearFilters() {
        this.selectedClassList = [];
        sessionStorage.setItem(`${this.category}-filters`, JSON.stringify({ searchText: this.searchText, selectedClassList: this.selectedClassList }));
        this.onFilterChanged.emit({'action': 'clearFilter', 'selectedClassList': [], 'searchText': this.searchText});
	}

	clearSearchInput() {
		this.searchText = '';
		this.filterButtonScheme = "discreetDark";
        sessionStorage.setItem(`${this.category}-filters`, JSON.stringify({ searchText: this.searchText, selectedClassList: this.selectedClassList }));
        this.onFilterChanged.emit({'action': 'clearSearch', 'selectedClassList': this.selectedClassList, 'searchText': this.searchText});
	}

	checkForEmptyText() {
		if (this.searchText) {
			return
		}
		this.filterEquipments();
	}
}
