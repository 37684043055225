<p-overlayPanel #showTooltipSmartAlertDescription [style]="{ width: '550px' }">
    <div class="smart-alert-info">
        <strong>Smart Alert</strong>
        <p>
            This section covers the detailed alert information as seen on
            summary card. <br />The number of active alerts would determine the
            number shown on icon. <br />For example, 2 would be shown on summary
            card if there are 2 active alerts. <br />The alert priority would
            determine the icon color. <br />If there is a combination of
            different alert priority, the higher priority would be chosen.
            <br />For example, High would be chosen if there is a combination of
            High & Low alert priority.
        </p>
        <div class="alert-type">
            <ng-container *ngFor="let bell of bellList">
                <div class="alert-type-detail">
                    <div class="bell-container">
                        <div
                            class="flex align-items-center alert-badge cursor-auto"
                            [ngClass]="bell.className"
                        >
                            <i
                                class="pi pi-bell"
                                pTooltip="This determines the priority and number of active smart alerts. Please check in detailed view, under Smart Alerts sub section."
                            ></i>
                            <strong> &nbsp; {{ bell.alertNum }} </strong>
                        </div>
                    </div>
                    {{ bell.description }}
                </div>
            </ng-container>
        </div>
    </div>
</p-overlayPanel>

<div class="smart-alert-container">
    <div class="entity-card-header justify-content-between">
        <div class="entity-details">
            <h2 class="entity-name">
                <b>Smart Alerts</b>
            </h2>
            <button
                (mouseover)="showTooltipSmartAlertDescription.show($event)"
                (mouseleave)="showTooltipSmartAlertDescription.hide()"
                pButton
                class="p-button-raised cbms-row-button btn-info cbms-margin-left"
            >
                <i class="pi pi-info"></i>
            </button>
        </div>
    </div>
    <div class="alert-list pt-3">
        @if (alertsToRender.length) { @for (alert of alertsToRender; track alert.alertUUID)
        {
        <div class="flex justify-content-between entity-row px-4">
            <div class="w-2">{{ alert.priority }}</div>
            <div class="w-2">{{ alert.type }}</div>
            <div class="w-2">{{ alert.label }}</div>
            <div class="w-2">
                @if (userHasEditPermission) {
                <p-dropdown
                    class="custom-dropdown"
                    [options]="alertStatusList"
                    [filter]="true"
                    [(ngModel)]="alert.statusLabel"
                    (onChange)="updateSmartAlertStatus(alert, $event)"
                ></p-dropdown>
                } @else {
                {{ alert.status | alertStatusLabel }}
                }
            </div>
            <div>
                <button
                    pButton
                    type="button"
                    icon="pi pi-bell"
                    class="bell-button"
                    (click)="showAlertDetails(alert.alertUUID)"
                ></button>
            </div>
        </div>
        } } @else {
        <div class="mb-3 p-3">No active Smart Alerts</div>
        }
    </div>
</div>
