import {Injectable} from '@angular/core';
import {DataPoint} from '../../models/data-point';

import moment from 'moment-timezone';
import {ChartConfig} from '../../components/highcharts/timeseries-chart/interfaces/timeseries-chart-config.interface';
import {TelemetryData} from '../../models/telemetry-data';
import {COLOR_LIST_EXTENDED} from '../../config/constants';
import {forkJoin} from 'rxjs';
import * as Highcharts from 'highcharts';
import _ from 'lodash';
import {BINARY, MULTISTATE} from '../../config/constants';
import {BackendService} from "../../services/backend/backend.service";


@Injectable()
export class TelemetryDialogService {
    private dashStyle = [
        "solid",
        "shortdash",
        "longdash",
        "dashdot",
        "shortdot",
        "longdashdot",
        "shortdashdot",
        "shortdashdotdot",
        "dot",
        "dash",
        "longdashdotdot"
    ];

    constructor(private backendService: BackendService) {
    }

    public getTelemetryData(dataPoints: DataPoint[], start?: string, end?: string) {
        const startDate = start || moment.utc().subtract(30, 'days').format();
        const endDate = end || moment.utc().format();
        let requestsArray = dataPoints.map(dataPoint => {
            return this.backendService.getTelemetryData(dataPoint.id, startDate, endDate);
        });

        return forkJoin(requestsArray);
    }

    public formatTelemetryData(responseArray: TelemetryData[],
                               dataPoints: DataPoint[],
                               showPreview: boolean,
                               legendField: string = 'description', zoomValues?: Highcharts.ExtremesObject) {

        let chartConfig: ChartConfig = {data: [], yAxis: [], zoomValues: zoomValues};

        dataPoints.forEach((dataPoint, index) => {
            if (responseArray[index] == null) {
                return;
            }
            responseArray[index].objectName = dataPoint.objectName || 'NA';
            responseArray[index].description = dataPoint.description || 'NA';
            responseArray[index].customName = dataPoint.customName || 'NA';
            responseArray[index].signalType = dataPoint.signalType;
        })
        let filterdByNull = responseArray.filter(telemetryData => telemetryData !== null);

        if (filterdByNull.length === 0) {
            return null;
        }

        const groupedByUnit = _.groupBy(filterdByNull, (telemetryData) => telemetryData.units);
        let colorIndex = 0;

        Object.keys(groupedByUnit).map((unit, index) => {
            let unitType = unit === "null" || unit === "noUnits" ? '' : unit;
            let color = '#000000';
            let legend = '';

            chartConfig.yAxis.push(
                {
                    title: {
                        text: unitType,
                        style: {
                            color: <string>color
                        }
                    },
                    labels: {
                        style: {
                            color: <string>color
                        }
                    },
                    opposite: false
                }
            )

            groupedByUnit[unit].forEach((telemetryData, i) => {
                if (telemetryData != null) {
                    legend = telemetryData[legendField];
                    if (unitType) {
                        legend = `${legend} [${unitType}]`;
                    }

                    chartConfig.data.push({
                        name: legend,
                        type: 'line',
                        dashStyle: this.getDashStyle(0),
                        yAxis: index,
                        step: (telemetryData.signalType === MULTISTATE || telemetryData.signalType === BINARY) ? 'left' : undefined,
                        color: this.getColorByIndex(colorIndex++),
                        data: telemetryData.samples.sort((a, b) => {
                            return a.timestamp - b.timestamp
                        }).map(entry => {
                            return [entry.timestamp, entry.value];
                        })
                    });
                }
                ;
            })
        });

        return chartConfig;
    }

    private getDashStyle(index: number): string {
        return this.dashStyle[index];
    }

    private getColorByIndex(index: number): string {
        return COLOR_LIST_EXTENDED[index];
    }
}
