import {Component, Input} from '@angular/core';
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {AlarmFilterMatcherResult} from "../../models/core/alert-rule.model";

@Component({
    selector: 'cc-query-builder',
    standalone: true,
    imports: [
        FormsModule,
        DropdownModule,
        NgClass,
        NgIf,
        NgForOf
    ],
    templateUrl: './query-builder.component.html',
    styleUrl: './query-builder.component.scss'
})
export class QueryBuilderComponent {
    @Input() isReadOnly: true = true;
    @Input() parentValue: AlarmFilterMatcherResult;
    @Input() query: AlarmFilterMatcherResult;

    constructor() {
    }
}
