import {Component, OnDestroy, OnInit} from "@angular/core";
import {OAuthService} from 'angular-oauth2-oidc';
import {BINARY, Operation} from "src/app/config/constants";
import {
    DeviceStatus,
    EntityFilters, EntityStatusResponse,
    EquipmentV2, SmartAlert,
} from "src/app/models/dashboard";
import {BackendService} from "src/app/services/backend/backend.service";
import {
    EventNotificationService,
    EventType,
} from "src/app/services/notification/event-notification.service";
import {SiteFilterService} from "src/app/services/site-filter.service";
import {RSocket, RSocketClient} from "rsocket-core";
import {UtilsService} from "src/app/services/utils/util.service";
import {Subscription} from "rxjs";
import {EntityType} from "../../models/api/entity-type";

@Component({
    selector: "cc-equipments",
    templateUrl: "./equipments.component.html",
    styleUrls: ["./equipments.component.scss"],
})
export class EquipmentsComponent implements OnInit, OnDestroy {
    equipments: EquipmentV2[] = [];
    equipmentStatus: { [key: string]: DeviceStatus } = {};
    mobileViewSelected = false;

    polledDatapointsValues: Subscription;
    polledKPIPointsValuesSubscription: Subscription;

    isSearchActive: boolean = false;
    isFilterActive: boolean = false;
    selectedValues: string[] = [];

    equipmentFiltersOptions: EntityFilters[] = [];
    selectedEquipmentClassList: string[] = [];
    selectedFilters: string[] = [];

    selectedSite: { siteId: string; customerId: string };
    searchText: string = "";
    username: string = "";
    category = "equipment";
    firstSiteLoad = true;
    noOfPinnedEquipments: number = null;

    private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private authService: OAuthService,
        private utilsService: UtilsService,
        private siteFilterService: SiteFilterService,
        private eventNotificationService: EventNotificationService
    ) {
    }

    ngOnInit() {
        const claims = this.authService.getIdentityClaims();
        this.username = claims ? claims['email'] : '';

        this.siteFilterService.siteFilterChanged$.subscribe((selectedSite) => {
            if (selectedSite === null) {
                return;
            }
            if (this.firstSiteLoad) {
                this.restoreFilters();
            }
            if (!this.firstSiteLoad) {
                this.resetFilters();
            }

            this.selectedSite = selectedSite;
            this.firstSiteLoad = false;
            this.clearPreviousData();
            this.getEquipments();
        });

        this.eventNotificationService
            .getEventDispatcher$()
            .subscribe((event) => {
                if (event.type === EventType.MOBILE_VIEW_SELECTED) {
                    this.mobileViewSelected = <boolean>event.payload;
                }
            });
    }

    restoreFilters() {
        const filters =
            JSON.parse(sessionStorage.getItem(`${this.category}-filters`)) ||
            null;

        if (filters) {
            this.searchText = filters.searchText;
            this.selectedEquipmentClassList = filters.selectedClassList;
        }
    }

    resetFilters() {
        this.searchText = "";
        this.selectedEquipmentClassList = [];
        sessionStorage.setItem(
            `${this.category}-filters`,
            JSON.stringify({
                searchText: this.searchText,
                selectedClassList: this.selectedEquipmentClassList,
            })
        );
    }

    onFilterChangedHandler($event: any) {
        this.searchText = $event.searchText;
        this.selectedEquipmentClassList = $event.selectedClassList;

        if ($event.action === "apply") {
            this.filterEquipments();
        }

        if ($event.action === "clearFilter") {
            this.clearEquipmentsFilters();
        }

        if ($event.action === "clearSearch") {
            this.clearSearchInput();
        }
    }

    getEquipments() {
        this.backendService
            .getEquipmentsV2(
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedEquipmentClassList
            )
            .subscribe((response: EquipmentV2[]) => {
                this.equipments = response;
                this.loadEquipmentStatus(this.equipments);
                this.noOfPinnedEquipments = this.equipments.filter(item => item.pinned).length;
                this.generateEquipmentsFilters();
                this.callKpiPolling(response
                    .flatMap((value) => value.selectedKpi)
                    .filter((value) => value !== null)
                    .map((value: any) => value.pointUUID))
            });
    }

    generateEquipmentsFilters() {
        this.backendService
            .getEquipmentsClassificationsV2(this.selectedSite.siteId)
            .subscribe((response: any[]) => {
                let equipmentsClassification = {};

                response.map((item) => {
                    let equipmentCategory = item.category.split("#")[1];
                    if (!equipmentsClassification[equipmentCategory]) {
                        equipmentsClassification[equipmentCategory] = [];
                    }
                    equipmentsClassification[equipmentCategory].push(
                        item.entityClass.split("#")[1].replaceAll("_", " ")
                    );
                });

                this.equipmentFiltersOptions = Object.keys(
                    equipmentsClassification
                ).map((category) => {
                    return {
                        label: category.replaceAll("_", " "),
                        value: category,
                        items: equipmentsClassification[category].map(
                            (eqClass: string) => {
                                return {
                                    label: eqClass.replaceAll("_", " "),
                                    value: eqClass,
                                };
                            }
                        ),
                    };
                });
            });
    }

    filterEquipments() {
        this.backendService
            .getEquipmentsV2(
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedEquipmentClassList
            )
            .subscribe((response: EquipmentV2[]) => {
                this.equipments = response;
            });
    }

    clearFilters(searchText: string, equipmentClassList: string[]) {
        this.backendService
            .getEquipmentsV2(
                this.username,
                this.selectedSite,
                searchText,
                equipmentClassList
            )
            .subscribe((response: EquipmentV2[]) => {
                this.equipments = response;
                this.loadEquipmentStatus(this.equipments);
            });
    }

    clearSearchInput() {
        this.searchText = "";
        this.clearFilters(this.searchText, this.selectedEquipmentClassList);
    }

    clearEquipmentsFilters() {
        this.selectedEquipmentClassList = [];
        this.clearFilters(this.searchText, this.selectedEquipmentClassList);
    }

    clearPreviousData() {
        if (this.polledKPIPointsValuesSubscription) {
            this.polledKPIPointsValuesSubscription.unsubscribe();
        }
        this.closeRsocket();
        this.equipments = [];
    }

    callKpiPolling(datapointIdListFilter: string[]) {
        ////////
        // Create an instance of a rSocketClient
        this.rSocketClient = this.backendService.getRSocketClient();
        // Open the connection
        this.polledKPIPointsValuesSubscription = this.rSocketClient.connect().subscribe({
            onComplete: (socket: RSocket) => {
                let requestStream = 'request-stream-get-datapoint-list2';
                socket
                    .requestStream({
                        data: {
                            'siteId': this.selectedSite.siteId,
                            'datapointIdListFilter': datapointIdListFilter.filter(datapointId => datapointId !== null),
                            'jwtToken': this.authService.getIdToken(),
                            'interaction': 'Request'
                        }, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
                        metadata: String.fromCharCode(requestStream.length) + requestStream
                    })
                    .subscribe({
                        onComplete: () => console.log('complete'),
                        onError: error => {
                            console.log("Connection has been closed due to:: " + error);
                        },
                        onNext: payload => {
                            this.mapEquipmentKPI(payload.data);
                        },
                        onSubscribe: subscription => {
                            subscription.request(1000000);
                        },
                    });
            },
            onError: error => {
                console.log("Connection has been refused due to:: " + error);
            },
            onSubscribe: cancel => {
                /* call cancel() to abort */
            }
        });
    }

    mapEquipmentKPI(point: any) {
        const mappedPointsPerId = {};

        this.equipments.forEach(equipment => {
            const pointObjectReference = equipment.selectedKpi;
            if (pointObjectReference) {
                mappedPointsPerId[pointObjectReference.pointUUID] = pointObjectReference;
            }
        })

        const existingPointObjectReference = mappedPointsPerId[point.id];
        if (existingPointObjectReference) {
            existingPointObjectReference.presentValue = point.lastRecordedValue;
            existingPointObjectReference.signalType = point.signalType;
            existingPointObjectReference.type = point.type;
            existingPointObjectReference.units = point.units;

            if (point.signalType === BINARY) {
                existingPointObjectReference.presentValue = point.lastRecordedValue ? true : false;
            }
        }
    }

    ngOnDestroy() {
        if (this.polledKPIPointsValuesSubscription) {
            this.polledKPIPointsValuesSubscription.unsubscribe();
        }

        this.closeRsocket();
    }

    private closeRsocket() {
        if (this.rSocketClient) {
            this.rSocketClient.close();
        }
    }

    private loadEquipmentStatus(equipments: EquipmentV2[]) {
        const params = {
            ...this.selectedSite,
            entityIds: equipments.map((equipment) => equipment.id),
            entityType: EntityType.EQUIPMENT,
        }
        this.backendService.getEntitiesStatus(params).subscribe((response: EntityStatusResponse) => this.equipmentStatus = response.entityStatus);
    }

    onCardPinToggle(pinnedStatus: "PINNED" | "UNPINNED") {
        if (pinnedStatus === "PINNED") {
            this.noOfPinnedEquipments++;
        } else {
            this.noOfPinnedEquipments--;
        }
    }

    protected readonly EntityType = EntityType;
}
