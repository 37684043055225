import { Component } from "@angular/core";
import { DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { IUpdateSmartAlertReq } from "src/app/models/core/alert-rule.model";
import { BackendService } from "src/app/services/backend/backend.service";
import { UtilsService } from "src/app/services/utils/util.service";

@Component({
    selector: "cc-confirmation-popup",
    standalone: true,
    imports: [],
    templateUrl: "./close-alert-confirmation-popup.component.html",
    styleUrl: "./close-alert-confirmation-popup.component.scss",
})
export class CloseAlertConfirmationPopupComponent {
    oldSmartAlertStatusCode: string;
    newSmartAlertStatusValue: string;

    constructor(
        private ref: DynamicDialogRef,
        private config: DynamicDialogConfig,
        private backendService: BackendService,
        private utilServ: UtilsService
    ){}

    ngOnInit(): void {
        this.newSmartAlertStatusValue = this.config.data.param;
        this.oldSmartAlertStatusCode = this.config.data.oldStatusCode;
    }

    handleConfirmation() {
        const param: IUpdateSmartAlertReq = this.config.data.param;
        this.backendService.updateStatusForSmartAlert(param).subscribe(
            (res) => {
                this.utilServ.notifyUpdateSmartAlertSuccessfully(param.alertStatus);
                this.ref.close({currentAlertStatus: param.alertStatus});
            },
            (err) => {
                this.utilServ.notifyUpdateSmartAlertFailed();
                this.ref.close({currentAlertStatus: this.oldSmartAlertStatusCode});
            }
        );
    }

    onCancel() {
        this.ref.close({currentAlertStatus: this.oldSmartAlertStatusCode});
    }
}
