import { Component, Input, OnInit } from '@angular/core';
import moment from 'moment';
import { COLORS } from 'src/app/config/constants';
import { BackendService } from 'src/app/services/backend/backend.service';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import momentPlugin from '@fullcalendar/moment';


@Component({
	selector: 'cc-schedule-calendar',
	templateUrl: './schedule-calendar.component.html',
	styleUrls: ['./schedule-calendar.component.scss']
})
export class ScheduleCalendarComponent implements OnInit {

	@Input() public scheduleUUID: string;

	calendarOptions: any;
	scheduleData: any;
	isLoading: boolean = false;

	constructor(private backendService: BackendService) { }

	ngOnInit(): void {
		this.getScheduleData();
	}

	private getScheduleData() {
		this.isLoading = true;

		this.backendService.getDataPointWeeklySchedule(this.scheduleUUID).subscribe( (response) => {
			this.scheduleData = response.weeklyScheduleWithInterval;
			this.initCalendar();
			this.isLoading = false;
		}, (err) => {
			this.isLoading = false;
			console.log('err: ', err);
		})
	}

	private generateEvents() {
		const SUNDAY = 0;
		const currentDay = moment().day() === SUNDAY ? 6 : moment().day() - 1;
		const events = this.scheduleData.map((scheduleObj: any, index: number): any[] => {
			return scheduleObj.map( (scheduleItem: any) => {
				if (scheduleItem.default) {
					return;
				}
				let color = COLORS.LIME_YELLOW;
				let textColor = COLORS.BLACK;

				//don't use strict comparison, value can be number or string or anything :)
				if (scheduleItem.value == '0') {
					color = COLORS.RED;
					textColor = COLORS.WHITE;
				}
				if (scheduleItem.value == '1') {
					color = COLORS.TURQOISE;
					textColor = COLORS.WHITE;
				}

				if (scheduleItem.value === null) {
					color = "transparent";
				}

				return {
					title: `Value: ${scheduleItem.value}`,
					value: `${scheduleItem.value}`,
					start: moment(scheduleItem.start, 'HH:mm:ss').add(index - currentDay, 'days').format('YYYY-MM-DDTHH:mm:ss'),
					end: moment(scheduleItem.end, 'HH:mm:ss').add(index - currentDay, 'days').format('YYYY-MM-DDTHH:mm:ss'),
					backgroundColor: color,
					borderColor: color,
					textColor: textColor,
					id: scheduleItem.id
				}
			}).filter(event => event !== undefined);
		}).flat();
		return events;
	}

	private initCalendar() {
		this.calendarOptions = {
			plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, momentPlugin],
			headerToolbar: false,
			initialView: 'timeGridWeek',
			slotLabelFormat: ['H:mm'],
			height: '100%',
			firstDay: 1,
			allDaySlot: false,
			editable: false,
			eventOverlap: false,
			locale: 'en-GB',
			eventTimeFormat: {
				hour: '2-digit',
				minute: '2-digit',
				hour12: false
			},
			dayHeaderFormat: 'ddd',
			events: this.generateEvents()
		};
	}
}
