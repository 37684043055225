import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BaseEntity, DeviceStatus, SmartAlert} from "../../models/dashboard";
import {Operation} from "../../config/constants";
import {BackendService} from "../../services/backend/backend.service";
import {OAuthService} from "angular-oauth2-oidc";
import {EntityType} from "../../models/api/entity-type";

@Component({
    selector: 'cc-entity-card',
    templateUrl: './entity-card.component.html',
    styleUrl: './entity-card.component.scss'
})
export class EntityCardComponent implements OnInit {

    @Input()
    entity: BaseEntity;

    @Input()
    entityType: EntityType;

    @Input()
    entityStatus: { [key: string]: DeviceStatus } = {};

    @Input()
    selectedSite: { siteId: string; customerId: string };

    @Output()
    cardPinToggle: EventEmitter<'PINNED' | 'UNPINNED'> = new EventEmitter<'PINNED' | 'UNPINNED'>();

    filteredSmartAlerts: SmartAlert[] = [];
    private username: string = "";


    constructor(private backendService: BackendService, private authService: OAuthService) {
    }

    ngOnInit(): void {
        const claims = this.authService.getIdentityClaims();
        this.username = claims ? claims['email'] : '';
        this.filteredSmartAlerts = this.getFilteredAlerts(this.entity.smartAlerts);
    }

    isEntityOffline(entityId: string): boolean {
        if (!this.entityStatus) {
            return false;
        }
        return this.entityStatus[entityId] === "OFFLINE";
    }

    getAlertBadgeClass(smartAlerts: SmartAlert[]): string {
        if (smartAlerts.length === 0) {
            return "no-alerts";
        } else if (smartAlerts.some(alert => alert.priority === "High")) {
            return "alert-high-priority";
        } else if (smartAlerts.some(alert => alert.priority === "Medium")) {
            return "alert-medium-priority";
        } else if (smartAlerts.some(alert => alert.priority === "Low")) {
            return "alert-low-priority";
        }

        return '';
    }

    togglePin(event: MouseEvent, equipment: BaseEntity): void {
        event.stopPropagation();
        const operation = equipment.pinned ? Operation.Delete : Operation.Add
        this.backendService
            .entityAddRemovePin(this.entityType.toLowerCase(), this.username, this.selectedSite, operation, `urn:uuid:${equipment.id}`)
            .subscribe((response) => {
                equipment.pinned = !equipment.pinned;
                this.cardPinToggle.emit(equipment.pinned ? 'PINNED' : 'UNPINNED');
            }, (err) => {
                console.log(err);
            });
    }

    private getFilteredAlerts(alerts?: SmartAlert[]): SmartAlert[] {
        return alerts?.filter(alert => ['OPEN', 'IN_PROGRESS'].includes(alert.status)) || [];
    }

    protected readonly EntityType = EntityType;
}
