import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SelectedSite } from '../models/site';


@Injectable({
	providedIn: 'root'
})
export class SiteFilterService {

	constructor() { }

	// Observable string sources
	private siteFilterSource = new BehaviorSubject<{siteId: string, customerId: string}>(null);

	// Observable string streams
	siteFilterChanged$ = this.siteFilterSource.asObservable();

	// Service message commands
	announceSiteChange(selectedSite: SelectedSite) {
		this.siteFilterSource.next(selectedSite);
	}

}
