<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your equipment</h1>
        <div
            class="sub-navigation flex justify-content-between align-items-center"
        ></div>
    </div>
</div>

<div class="main-wrapper">
    <div class="container no-padding">
        <p-tabView styleClass="custom-no-bg">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/live-view.svg" />
                    <span>Live View</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12 entity-full-width"></div>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/dashboard.svg" />
                    <span>History</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12"></div>
                </div>
            </p-tabPanel>
        </p-tabView>

        <div class="detailed-entity-card">
            <div class="entity-card-header justify-content-between">
                <div class="entity-icon">
                    <img width="48" height="48" src="assets/svg/Fan.svg" />
                </div>
                <div class="entity-details">
                    <h2 class="entity-name">Sales Air Handling Unit</h2>
                    <p class="entity-class">Air Handling Unit</p>
                </div>
                <eon-ui-icon
                    name="bookmark"
                    class="cursor-pointer ng-star-inserted hydrated"
                    aria-label="bookmark"
                ></eon-ui-icon>
            </div>
            <div class="entity-card-content">
                <div class="entity-top-details">
                    <p><b>Physical Location:</b> <a href="#">Rooftop</a></p>
                    <p><b>Is Part Of:</b> N/A</p>
                    <p><b>Feeds Location:</b> Sales Floor</p>
                    <p><b>Feeds Equipment:</b> N/A</p>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Smart Alerts</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row justify-content-between">
                    <span class="col-2">High</span>
                    <span class="col-2">Maintenance</span>
                    <span class="col-4">Sales Floor Supply Fan Fail</span>
                    <span class="col-2">Open</span>
                    <span class="col-2 flex justify-content-end">
                        <button
                            pButton
                            type="button"
                            icon="pi pi-bell"
                            class="ui-button-info cbms-margin-left"
                        ></button>
                    </span>
                </div>
                <div class="entity-row justify-content-between">
                    <span class="col-2">Medium</span>
                    <span class="col-2">Energy</span>
                    <span class="col-4"
                        >Heater Battery Running Outswitch Spec</span
                    >
                    <span class="col-2">Open</span>
                    <span class="col-2 flex justify-content-end">
                        <button
                            pButton
                            type="button"
                            icon="pi pi-bell"
                            class="ui-button-info cbms-margin-left"
                        ></button>
                    </span>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Sensors & Status</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Temperature A</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto"
                    >
                        <span>17.07</span><span class="kpi-unit">°C</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Temperature B</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto"
                    >
                        <span>18.85</span><span class="kpi-unit">°C</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Supply Air Temperature A</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto"
                    >
                        <span>36.38</span><span class="kpi-unit">°C</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Supply Air Temperature B</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto"
                    >
                        <span>32.82</span><span class="kpi-unit">°C</span>
                    </div>
                </div>
                <div class="entity-row ng-star-inserted">
                    <div class="first-col ng-star-inserted">
                        <span>Sales Zone Air Flow Status</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <p-inputSwitch
                            styleClass="default-switch__black"
                            [(ngModel)]="switchOn"
                        ></p-inputSwitch>
                        <span class="kpi-unit ng-star-inserted">On</span>
                    </div>
                </div>
                <div class="entity-row ng-star-inserted">
                    <div class="first-col ng-star-inserted">
                        <span>Sales Zone Air Alarm Status</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <p-inputSwitch
                            styleClass="default-switch__black"
                            [(ngModel)]="switchOn"
                        ></p-inputSwitch>
                        <span class="kpi-unit ng-star-inserted">On</span>
                    </div>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Commands & Setpoints</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row ng-star-inserted">
                    <div class="first-col ng-star-inserted">
                        <span>Sales Zone Supply Fan Enabled</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <p-inputSwitch
                            styleClass="default-switch__black"
                            [(ngModel)]="switchOn"
                        ></p-inputSwitch>
                        <span class="kpi-unit ng-star-inserted">On</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Gas Heater 1 Enable</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                pinputtext=""
                                class="p-inputtext p-component p-element small-input text-right"
                                value="3"
                            />
                        </div>
                        <span class="kpi-unit">%</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Gas Heater 2 Enable</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                pinputtext=""
                                class="p-inputtext p-component p-element small-input text-right"
                                value="7"
                            />
                        </div>
                        <span class="kpi-unit">%</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Sales Zone Heating Setpoint</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                pinputtext=""
                                class="p-inputtext p-component p-element small-input text-right"
                                value="18"
                            />
                        </div>
                        <span class="kpi-unit">°C</span>
                    </div>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Parameters</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row">
                    <div class="first-col">
                        <span>Outside Heating Hold Off</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                pinputtext=""
                                class="p-inputtext p-component p-element small-input text-right"
                                value="18"
                            />
                        </div>
                        <span class="kpi-unit">°C</span>
                    </div>
                </div>
                <div class="entity-row">
                    <div class="first-col">
                        <span>Outside Cooling Hold Off</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <div class="form-group">
                            <input
                                type="text"
                                pinputtext=""
                                class="p-inputtext p-component p-element small-input text-right"
                                value="10"
                            />
                        </div>
                        <span class="kpi-unit">°C</span>
                    </div>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Alarms</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row ng-star-inserted">
                    <div class="first-col ng-star-inserted">
                        <span>Sales Zone Plant Fault</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto ng-star-inserted"
                    >
                        <p-inputSwitch
                            styleClass="default-switch__black"
                            [disabled]="true"
                        ></p-inputSwitch>
                        <span class="kpi-unit ng-star-inserted">Off</span>
                    </div>
                </div>
            </div>

            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Schedules</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row ng-star-inserted">
                    <div class="first-col">
                        <span>Sales Zone Time Schedule</span>
                    </div>
                    <div
                        class="mid-col flex align-items-center justify-content-end margin-left-auto"
                    >
                        <span></span
                        ><span class="icon-btn"
                            ><img
                                src="assets/icons/exception.svg"
                                class="cursor-pointer" /></span
                        ><span class="icon-btn"
                            ><img
                                src="assets/icons/schedule.svg"
                                class="cursor-pointer"
                        /></span>
                    </div>
                </div>
            </div>
            <div class="entity-card-header justify-content-between">
                <div class="entity-details">
                    <h2 class="entity-name"><b>Sub-equipment</b></h2>
                </div>
            </div>
            <div class="entity-card-content">
                <div class="entity-row justify-content-start">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon" aria-hidden="true"><path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path></svg>
                    <span>Sales Zone Cooling</span>
                </div>
                <div class="entity-row justify-content-start">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon" aria-hidden="true"><path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path></svg>
                    <span>Sales Zone Gas Heater 1</span>
                </div>
                <div class="entity-row justify-content-start">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon" aria-hidden="true"><path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path></svg>
                    <span>Sales Zone Gas Heater 2</span>
                </div>
                <div class="entity-row justify-content-start">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" class="p-icon" aria-hidden="true"><path d="M4.38708 13C4.28408 13.0005 4.18203 12.9804 4.08691 12.9409C3.99178 12.9014 3.9055 12.8433 3.83313 12.7701C3.68634 12.6231 3.60388 12.4238 3.60388 12.2161C3.60388 12.0084 3.68634 11.8091 3.83313 11.6622L8.50507 6.99022L3.83313 2.31827C3.69467 2.16968 3.61928 1.97313 3.62287 1.77005C3.62645 1.56698 3.70872 1.37322 3.85234 1.22959C3.99596 1.08597 4.18972 1.00371 4.3928 1.00012C4.59588 0.996539 4.79242 1.07192 4.94102 1.21039L10.1669 6.43628C10.3137 6.58325 10.3962 6.78249 10.3962 6.99022C10.3962 7.19795 10.3137 7.39718 10.1669 7.54416L4.94102 12.7701C4.86865 12.8433 4.78237 12.9014 4.68724 12.9409C4.59212 12.9804 4.49007 13.0005 4.38708 13Z" fill="currentColor"></path></svg>
                    <span>Sales Zone Supply Fan</span>
                </div>
            </div>

            <div
                class="entity-card-footer flex align-items-center justify-content-between"
            >
                <eon-ui-icon
                    name="more"
                    aria-label="more"
                    class="hydrated"
                ></eon-ui-icon
                ><cc-share-link ng-reflect-link="http://localhost:4200/customer"
                    ><eon-ui-icon
                        name="share"
                        class="cursor-pointer hydrated"
                        aria-label="share"
                    ></eon-ui-icon>
                    ></cc-share-link
                >
            </div>
        </div>
    </div>
</div>
