import {Component, OnInit, Inject, OnDestroy, Renderer2} from "@angular/core";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {BackendService} from "./services/backend/backend.service";
import {StoreService} from "./services/store.service";
import {AuthConfig, OAuthEvent, OAuthService} from "angular-oauth2-oidc";
import {AUTH0_CONFIG, IDP_PROVIDER, LOGIN_IDP, TESCO_CONFIG} from "./config/constants";
import {AuthorizationService} from "./services/authorization.service";
import {CookieService} from "ngx-cookie-service";
import { SiteFilterService } from "./services/site-filter.service";
import { SelectedSite } from "./models/site";

export function getEnvironmentFromUrl(): string {
    switch (window.location.hostname) {
        case "localhost":
        case "dev.cbms.eon-optimum.com":
        case "dashboard.dev.cbms.eon-optimum.com":
            return "DEV";
        default:
            return "PROD";
    }
}

const ENV = getEnvironmentFromUrl();

@Component({
    selector: "cc-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
    title = "optimum-cc-dashboard-ui";

    public user: string | undefined = "";
    public loading = false;
    public shouldShowSiteFilter = true;
    public shouldHideHeader = false;
    public userIsPresent = false;
    public isBurgerMenuActive = false;

    public isIframe = false;
    private readonly _destroying$ = new Subject<void>();
    isSiteOverviewEnable: boolean;
    siteOverviewUrl: string = '';

    constructor(
        private authService: OAuthService,
        private router: Router,
        private backendService: BackendService,
        private storeService: StoreService,
        private renderer: Renderer2,
        private authorizationService: AuthorizationService,
        private cookieService: CookieService,
        private siteFilterService: SiteFilterService
    ) {

        const loginIdp = this.cookieService.get(LOGIN_IDP) || IDP_PROVIDER.AUTH0;
        let authConfig: AuthConfig = AUTH0_CONFIG[ENV];

        if (loginIdp.startsWith('tesco-')) {
            authConfig = TESCO_CONFIG[ENV];
        }

        this.authService.events.subscribe((event: OAuthEvent) => {
            if (event.type === 'invalid_nonce_in_state') {
                window.location.href = '/equipments';
            } else if (event.type === 'token_received') {
                this.loadUserSettings();
            }
        });


        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop as string),
        });

        if (params && params['invitation'] && params['organization'] && params['organization_name']) {
            authConfig.customQueryParams = {
                invitation: params['invitation'],
                organization: params['organization'],
                organization_name: params['organization_name'],
            };
        }

        this.authService.configure(authConfig);
        this.authService.setupAutomaticSilentRefresh();
        this.authService.loadDiscoveryDocumentAndLogin().then((value) => {
            if (value) {
                this.authorizationService.init();
                this.user = this.authorizationService.getUsername();
            }
        });
    }

    ngOnInit(): void {
        this.siteFilterService.siteFilterChanged$.subscribe((selectedSite) => {
            if (selectedSite) {
                this.handleSiteOverviewTab(selectedSite);
            }
        });
        this.isIframe = window !== window.parent && !window.opener;
        this.loadUserSettings();
    }

    handleSiteOverviewTab(selectedSite: SelectedSite) {
        this.siteOverviewUrl = `site-overview/site/${selectedSite.siteId}`;
        this.isSiteOverviewEnable = JSON.parse(sessionStorage.getItem('selectedSite'))?.siteOverviewEnabled;
    }


    logout() {
        this.authorizationService.logout();
    }

    openBurgerMenuOnMobile() {
        this.isBurgerMenuActive = true;
        this.renderer.addClass(document.body, "noscroll");
        this.renderer.addClass(
            document.getElementById("app-wrapper"),
            "noscroll"
        );
    }

    closeBurgerMenuOnMobile() {
        this.isBurgerMenuActive = false;
        this.renderer.removeClass(document.body, "noscroll");
        this.renderer.removeClass(
            document.getElementById("app-wrapper"),
            "noscroll"
        );
    }

    ngOnDestroy(): void {
        this._destroying$.next();
        this._destroying$.complete();
    }

    private loadUserSettings(): void {
        if (this.authService.hasValidAccessToken()) {
            this.backendService
                .checkUserNamePresence(
                    this.authorizationService.getUsername()
                )
                .subscribe((response: any) => {
                    this.userIsPresent = response.isPresent;
                    if (response.isPresent) {
                        this.user = this.authorizationService.getUsername();

                        this.backendService
                            .checkTermsOfUseStatus(this.user)
                            .subscribe((response) => {
                                if (!response.hasAgreedTermsAndConditions) {
                                    this.router.navigate([
                                        `/terms-and-conditions`,
                                    ]);
                                }
                            });
                    }
                });

            this.backendService
                .checkUserWritePermission()
                .subscribe((response: any) => {
                    this.storeService.updateUserWritePermission(
                        response.hasWritePermission
                    );

                    this.storeService.value(response.hasWritePermission);
                });
        }
    }
}
