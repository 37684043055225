import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import moment from "moment-timezone";
import {TimeFramesValues} from "../../config/constants";
import {SelectItem} from "primeng/api";
import {MatProgressSpinner} from "@angular/material/progress-spinner";
import {CalendarModule} from "primeng/calendar";
import {FormsModule} from "@angular/forms";
import {DropdownModule} from "primeng/dropdown";
import {RadioButtonModule} from "primeng/radiobutton";
import {DataPoint} from "../../models/data-point";
import {TelemetryData} from "../../models/telemetry-data";
import {FilterParams} from "../../models/filter-param";
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {BackendService} from "../../services/backend/backend.service";
import {HighchartsDataService} from "../../services/data/highcharts-data.service";
import {TelemetryDialogService} from "./telemetry-dialog.service";
import {ChartConfig} from "../../components/highcharts/timeseries-chart/interfaces/timeseries-chart-config.interface";
import {NgIf} from "@angular/common";
import {TimeseriesChartComponent} from "../../components/highcharts/timeseries-chart/timeseries-chart.component";
import {provideAnimations} from "@angular/platform-browser/animations";

@Component({
    selector: 'cc-telemetry-dialog',
    standalone: true,
    imports: [
        MatProgressSpinner,
        CalendarModule,
        FormsModule,
        DropdownModule,
        RadioButtonModule,
        NgIf,
        TimeseriesChartComponent
    ],
    providers: [TelemetryDialogService, provideAnimations()],
    templateUrl: './telemetry-dialog.component.html',
    styleUrl: './telemetry-dialog.component.scss',
    encapsulation: ViewEncapsulation.None
})
export class TelemetryDialogComponent implements OnInit{
    public start: Date = moment.utc().subtract(7, 'days').toDate();
    public end: Date = moment.utc().toDate();
    public maxDateValue: Date = moment.utc().toDate();
    public minDateValue: Date = moment.utc().subtract(90, 'days').toDate();
    public rangeDates: Date[];
    public timeOptionList: SelectItem[] = [
        {label: 'Select time', value: ''},
        {label: 'Today', value: TimeFramesValues.TODAY},
        {label: 'Yesterday', value: TimeFramesValues.YESTERDAY},
        {label: 'Last 7 days', value: TimeFramesValues.LAST_7_DAYS},
        {label: 'Last 30 days', value: TimeFramesValues.LAST_30_DAYS},
        {label: 'Last 365 days', value: TimeFramesValues.LAST_365_DAYS},
    ];
    public selectedTime: SelectItem = {label: 'Last 7 days', value: 7};
    public timeseries: ChartConfig;
    public isLoading: boolean = false;
    public selectedlegendField: string = 'objectName';
    public dataPoints: DataPoint[] = [];
    public maxYearRangeLimit: number = new Date().getFullYear() + 2;
    public siteTimezone: string;
    public haveSameTimezoneFlag = false;
    private telemetryDataResponse: TelemetryData[];
    private intervalValue: number;

    private filterParams: FilterParams = {
        includesPresentValue: true,
        dataPointIdFilter: this.config.data.dataPoints.map(
            (dataPoint: DataPoint) => {
                return {id: dataPoint.id};
            }
        ),
    };

    @ViewChild('chart') chartWrapper: any;

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private telemetryService: TelemetryDialogService,
        private backendService: BackendService,
        private highchartsDataService: HighchartsDataService
    ) {
    }

    public ngOnInit() {
        this.isLoading = true;
        const pageSize = this.filterParams.dataPointIdFilter.length;
        const filterParams = Object.assign({}, this.filterParams, {
            includesPresentValue: false,
        });

        this.backendService
            .getDataPointsSummary(filterParams, 0, pageSize, false)
            .subscribe((response) => {
                this.dataPoints = response.content;
                this.isLoading = false;
                this.siteTimezone = this.dataPoints[0].site.timezone;
                this.haveSameTimezoneFlag = this.haveSameTimezone(
                    this.dataPoints
                );

                this.setDefaultTimeframe();
                this.getTelemetryData();
            });

        this.highchartsDataService.zoomIn$.subscribe((range) => {
            if (range !== null) {
                const start = moment(range.start).toDate();
                const end = moment(range.end).toDate();
                this.rangeDates = [start, end];
                this.resetSelectedTime();
            }
        });
    }

    public updateGraph() {
        this.getTelemetryData();
    }

    public selectAllSeries() {
        this.chartWrapper.chart.series.forEach((item) => {
            item.setVisible(true);
        });
    }

    public deselectAllSeries() {
        this.chartWrapper.chart.series.forEach((item) => {
            item.setVisible(false);
        });
    }

    public resetZoom() {
        this.setDefaultTimeframe();
        this.getTelemetryData();
        this.highchartsDataService.clearZoomLevelList();
    }

    public zoomOut() {
        if (!this.highchartsDataService.getPreviousZoomLevel()) {
            this.resetZoom();
            return;
        }
        const zoomValues: Highcharts.ExtremesObject =
            this.highchartsDataService.getPreviousZoomLevel();

        this.highchartsDataService.removeLastZoomLevel();
        const showPreview = this.config.data.showPreview;

        const start = zoomValues.min;
        const end = zoomValues.max;

        this.telemetryService
            .getTelemetryData(
                this.dataPoints,
                moment(start).format(),
                moment(end).format()
            )
            .subscribe((response) => {
                this.telemetryDataResponse = response;
                this.timeseries = this.telemetryService.formatTelemetryData(
                    response,
                    this.dataPoints,
                    showPreview,
                    this.selectedlegendField
                );
                this.isLoading = false;
            });
    }

    public moveChartToRight() {
        let intervalValue = moment(this.rangeDates[1]).diff(this.rangeDates[0]);
        const start = this.rangeDates[1];
        const end = moment(this.rangeDates[1]).add(intervalValue, 'ms').toDate();
        this.moveChart(start, end);
    }

    public moveChartToLeft() {
        let intervalValue = moment(this.rangeDates[1]).diff(this.rangeDates[0]);

        const end = this.rangeDates[0];
        const start = moment(this.rangeDates[0]).subtract(intervalValue, 'ms').toDate();
        this.moveChart(start, end);
    }

    public shouldDisableZoom() {
        return this.highchartsDataService.getZoomLevelList().length === 0;
    }

    public updateGraphLegend() {
        const zoomValues: Highcharts.ExtremesObject =
            this.chartWrapper.chart.xAxis[0].getExtremes();
        const showPreview = this.config.data.showPreview;
        this.isLoading = true;
        this.timeseries = this.telemetryService.formatTelemetryData(
            this.telemetryDataResponse,
            this.dataPoints,
            showPreview,
            this.selectedlegendField,
            zoomValues
        );
        this.isLoading = false;
    }

    public close() {
        this.ref.close();
    }

    public onIntervalSelected(event: any) {
        this.resetSelectedTime();
    }

    public onFilter() {
        this.setCalendarInterval();
        this.getTelemetryData();
        this.highchartsDataService.clearZoomLevelList();
    }

    public onIntervalOptionSelected() {
        this.setCalendarInterval();
    }

    private setCalendarInterval() {
        if (this.selectedTime.value === '') {
            return;
        }

        let start = moment('00:00:00', 'HH:mm:ss')
            .utc()
            .subtract(this.selectedTime.value, 'days')
            .toDate();
        let end = moment('23:59:00', 'HH:mm:ss').utc().toDate();

        if (this.selectedTime.value === TimeFramesValues.YESTERDAY) {
            end = moment(start)
                .endOf('day')
                .seconds(0)
                .milliseconds(0)
                .toDate();
        }

        this.rangeDates = [start, end];
    }

    private resetSelectedTime() {
        this.selectedTime = {label: 'Select time', value: ''};
    }

    private setDefaultTimeframe() {
        this.selectedTime = {
            label: 'Last 7 days',
            value: TimeFramesValues.LAST_7_DAYS,
        };
        this.setCalendarInterval();
    }

    private getTelemetryData() {
        const showPreview = this.config.data.showPreview;
        this.isLoading = true;

        let start = moment(this.rangeDates[0]).utc().format();
        let end = '';

        if (!this.rangeDates[1]) {
            end = moment(this.rangeDates[0]).endOf('day').utc().format();
        } else {
            end = moment(this.rangeDates[1]).endOf('day').utc().format();
        }

        if (showPreview) {
            start = moment.utc().subtract(3, 'days').format();
            end = moment.utc().format();
        }
        this.telemetryService
            .getTelemetryData(this.dataPoints, start, end)
            .subscribe((response) => {
                this.telemetryDataResponse = response;
                this.timeseries = this.telemetryService.formatTelemetryData(
                    response,
                    this.dataPoints,
                    showPreview,
                    this.selectedlegendField
                );
                this.isLoading = false;
            });
    }

    private haveSameTimezone(dataPoints: any[]) {
        const firstDataPointTimezone = dataPoints[0].site.timezone;

        for (let i = 1; i < dataPoints.length; i++) {
            if (dataPoints[i].site.timezone !== firstDataPointTimezone) {
                return false;
            }
        }

        return true;
    }

    private moveChart(start, end) {
        this.rangeDates = [start, end];
        this.resetSelectedTime();

        this.telemetryService
            .getTelemetryData(
                this.dataPoints,
                moment(start).format(),
                moment(end).format()
            )
            .subscribe((response) => {
                this.telemetryDataResponse = response;
                this.timeseries = this.telemetryService.formatTelemetryData(
                    response,
                    this.dataPoints,
                    this.config.data.showPreview,
                    this.selectedlegendField
                );
                this.isLoading = false;
            });
    }
}
