<div class="entity-row" *ngFor="let kpiPoint of points; trackBy: trackByFn">

    <ng-container>

        <!--point name-->
        <div class="first-col"
             [ngClass]="{'is-overridden':kpiPoint?.isWritable && kpiPoint?.isOverridden}">
            <span>{{ kpiPoint?.comment || kpiPoint?.sourceDescription || kpiPoint?.classLabel }}</span>
        </div>

        <div class="mid-col flex align-items-center justify-content-end margin-left-auto">


            <div class="flex flex-row align-items-center gap-3">
                <!--OFFLINE device-->
                <div class="flex align-items-center">
                    <img ngSrc="assets/svg/DQ.svg" height="22" width="22" *ngIf="isPointOffline(kpiPoint?.pointUUID)"
                         alt="device offline"
                         pTooltip="This determines the data points are missing and / or flatlining. Please check in detailed view and history graph."/>
                </div>

                <div>
                    <!--overridden point - lock button/icon -->
                    <i class="pi pi-lock is-overridden" *ngIf="kpiPoint?.isWritable && kpiPoint?.isOverridden"
                       pTooltip="Point Overridden" (click)="openResetConfirmationDialog(kpiPoint)"></i>
                </div>
            </div>

            <!--BINARY POINT controls-->
            <ng-container *ngIf="kpiPoint?.signalType === 'Binary' else nonBinaryPoint">

                <p-inputSwitch styleClass="default-switch__black"
                               [disabled]="!kpiPoint?.isWritable || !userHasWritePermission"
                               [(ngModel)]="kpiPoint.presentValue" (onChange)="handleChange(kpiPoint)">
                </p-inputSwitch>
                <span class="kpi-unit" *ngIf="kpiPoint?.presentValue">On</span>
                <span class="kpi-unit" *ngIf="!kpiPoint?.presentValue">Off</span>
            </ng-container>

            <!--NON-BINARY POINT controls-->
            <ng-template #nonBinaryPoint>
                <div class="form-group" *ngIf="kpiPoint?.isWritable && userHasWritePermission else kpiTextValue">

                    <input #inputRef class="small-input text-right" type="text" pInputText
                           (focus)="onFocus($event, kpiPoint)" [(ngModel)]="kpiPoint.presentValue"/>

                    <p-progressSpinner *ngIf="kpiPoint?.requestInProgress" [style]="{ width: '30px', height: '30px' }"
                                       styleClass="custom-spinner" strokeWidth="3"
                                       fill="var(--surface-ground)" animationDuration="1s">
                    </p-progressSpinner>

                    <ng-container *ngIf="kpiPoint?.changeActive && !kpiPoint?.requestInProgress">
                        <p-button styleClass="turquoise" icon="pi pi-check"
                                  (click)="confirmWrite(kpiPoint, inputRef)"></p-button>
                        <p-button styleClass="turquoise" icon="pi pi-times"
                                  (click)="cancelWrite(kpiPoint, inputRef)"></p-button>
                    </ng-container>
                </div>

                <ng-template #kpiTextValue><span>{{ kpiPoint?.presentValue }}</span></ng-template>

                <span class="kpi-unit" [innerHTML]="kpiPoint?.htmlSymbol"></span>
            </ng-template>

        </div>
    </ng-container>
</div>
