import {Component, OnInit} from '@angular/core';
import {DialogService, DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {BackendService} from "../../services/backend/backend.service";
import {AlarmFilter, AlarmFilterMatcherResult, CoreSmartAlert} from "../../models/core/alert-rule.model";
import {DatePipe} from "@angular/common";
import {ButtonDirective} from "primeng/button";
import {QueryBuilderComponent} from "../../components/query-builder/query-builder.component";
import {TelemetryDialogComponent} from "../telemetry-dialog/telemetry-dialog.component";


@Component({
    selector: 'cc-smart-alert-details',
    standalone: true,
    imports: [
        DatePipe,
        ButtonDirective,
        QueryBuilderComponent
    ],
    templateUrl: './smart-alert-details.component.html',
    styleUrl: './smart-alert-details.component.scss'
})
export class SmartAlertDetailsComponent implements OnInit {
    alertData: CoreSmartAlert;
    isLoading = true;
    dataPointIdList: { id: string }[] = [];

    constructor(private ref: DynamicDialogRef,
                private config: DynamicDialogConfig,
                private backendService: BackendService,
                private dialogService: DialogService
    ) {
    }

    ngOnInit(): void {
        this.alertData = this.config.data;

        this.backendService.getAlertDetails(this.alertData.id).subscribe((response: any) => {
            this.alertData = {...this.alertData, ...response};
            this.iterateRulesToGetDataPointIdList(response.alarmFilterMatcherResult.rules);
            this.isLoading = false;
        })
    }

    close(): void {
        this.ref.close();
    }

    showTelemetryDialog(): void {
        this.dialogService.open(TelemetryDialogComponent, {
            header: 'Data Point Analysis',
            contentStyle: {'padding': '0'},
            width: '90%',
            closable: false,
            data: {
                dataPoints: this.dataPointIdList,
                showPreview: false
            }
        });
    }

    private iterateRulesToGetDataPointIdList(rules: AlarmFilter[]): void {
        if (!rules || !Array.isArray(rules)) {
            return;
        }

        for (const rule of rules) {
            // Process the current rule here
            if (rule.entityId) {
                this.dataPointIdList.push({id: rule.entityId});
            }

            // Recursively process nested rules
            if (this.ruleHasChildren(rule)) {
                this.iterateRulesToGetDataPointIdList(rule.rules);
            }
        }
    }

    private ruleHasChildren(rule: AlarmFilterMatcherResult): boolean {
        return rule.rules && Array.isArray(rule.rules);
    }
}
