import { Component, Input, OnInit } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { UtilsService } from 'src/app/services/utils/util.service';

@Component({
	selector: 'cc-exception-schedule-table',
	templateUrl: './exception-schedule-table.component.html',
	styleUrls: ['./exception-schedule-table.component.scss']
})
export class ExceptionScheduleTableComponent implements OnInit {

	@Input() public exceptionScheduleUUID: string;
	
	public mainTableCols = [
		{ field: 'name', header: 'Name', sortable: false },
		{ field: 'type', header: 'Type', sortable: false },
		{ field: 'summary', header: 'Summary', sortable: false },
		{ field: 'priority', header: 'Priority', sortable: true }
	];

	public subTableCols = [
		{ field: 'start', header: 'Start', sortable: true },
		{ field: 'end', header: 'Stop', sortable: true },
		{ field: 'value', header: 'Value', sortable: true },
		{ field: 'type', header: 'Type', sortable: true }
	];

	public exceptionScheduleObject: any;
	public isLoading: boolean = false;

	constructor(private utilsService: UtilsService, private backendService: BackendService) { }

	ngOnInit(): void {
		this.getExceptionScheduleData();
	}

	private getExceptionScheduleData() {
		this.isLoading = true;

		this.backendService.getDataPointExceptionSchedule(this.exceptionScheduleUUID).subscribe( (response) => {
			this.exceptionScheduleObject = this.utilsService.mapExceptionSchedule(response);
			this.isLoading = false;
		}, (err: any) => {
			this.isLoading = false;
			console.log('err: ', err);
		})
	}

}
