import {
    APP_INITIALIZER,
    ErrorHandler,
    NgModule,
    CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { Router } from "@angular/router";

import { BrowserModule } from "@angular/platform-browser";
import { PlatformModule } from "@angular/cdk/platform";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { environment } from "../environments/environment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ErrorComponent } from "./views/error/error.component";
import { EquipmentsComponent } from "./views/equipments/equipments.component";

import { BackendService } from "./services/backend/backend.service";
import { SvgService } from "./services/svg/svg.service";
import { NotificationService } from "./services/notification/notification.service";
import { HttpBaseService } from "./services/http/http-base.service";

import {
    provideHttpClient,
    withInterceptorsFromDi,
} from "@angular/common/http";

import { MatIconModule } from "@angular/material/icon";
import { MessageService } from "primeng/api";
import { ToastModule } from "primeng/toast";
import { TooltipModule } from "primeng/tooltip";
import { DialogModule } from "primeng/dialog";
import { FullCalendarModule } from "@fullcalendar/angular"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import momentPlugin from "@fullcalendar/moment";

import { SiteFilterComponent } from "./components/site-filter/site-filter.component";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";

import { ProgressSpinnerModule } from "primeng/progressspinner";
import { TableModule } from "primeng/table";
import { LayoutModule } from "@angular/cdk/layout";
import { ListboxModule } from "primeng/listbox";
import { DropdownModule } from "primeng/dropdown";
import { CheckboxModule } from "primeng/checkbox";
import { CalendarModule } from "primeng/calendar";
import { InputSwitchModule } from "primeng/inputswitch";
import { MultiSelectModule } from "primeng/multiselect";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { ConfirmationService } from "primeng/api";

import { ZonesComponent } from "./views/zones/zones.component";
import { VhMobileFixDirective } from "./directives/vh-mobile-fix.directive";
import { InputTextModule } from "primeng/inputtext";
import { InputTextareaModule } from "primeng/inputtextarea";

import { TabViewModule } from "primeng/tabview";
import { TreeModule } from "primeng/tree";
import { AccordionModule } from "primeng/accordion";
import { HistoricalDataComponent } from "./components/historical-data/historical-data.component";
import { HighchartsChartModule } from "highcharts-angular";
import { OverlayPanelModule } from "primeng/overlaypanel";
import { ClipboardModule } from "@angular/cdk/clipboard";
import { ShareLinkComponent } from "./components/share-link/share-link.component";
import { QrCodeModule } from "ng-qrcode";
import { NoAccessComponent } from "./views/no-access/no-access.component";
import { ScheduleCalendarComponent } from "./components/schedule-calendar/schedule-calendar.component";
import { ExceptionScheduleTableComponent } from "./components/exception-schedule-table/exception-schedule-table.component";
import { TermsAndConditionsComponent } from "./views/terms-and-conditions/terms-and-conditions.component";
import { CanDeactivateGuard } from "./services/can-deactivate-guard.service";
import { CustomerSpecificationComponent } from "./views/customer-specification/customer-specification.component";

import { EditorModule } from "@tinymce/tinymce-angular";
import { SafeHtmlPipe } from "./pipes/safe-html.pipe";

import * as Sentry from "@sentry/angular";
import { LocationsComponent } from "./views/locations/locations.component";
import { CollectionsComponent } from "./views/collections/collections.component";
import { EquipmentsV2Component } from "./views/equipments-v2/equipments-v2.component";
import { EquipmentDetailsV2Component } from "./views/equipment-details-v2/equipment-details-v2.component";
import { FilterAndSearchComponent } from "./components/filter-and-search/filter-and-search.component";
import { EntityPointsComponent } from "./components/entity-points/entity-points.component";
import { EquipmentDetailsViewWrapperComponent } from "./views/equipments/details-view-wrapper/details-view-wrapper.component";
import { EquipmentDetailsViewComponent } from "./views/equipments/details-view/details-view.component";
import { EquipmentPinnedViewComponent } from "./views/equipments/pinned-view/pinned-view.component";
import { CollectionDetailsViewComponent } from "./views/collections/details-view/details-view.component";
import { CollectionDetailsViewWrapperComponent } from "./views/collections/details-view-wrapper/details-view-wrapper.component";
import { CollectionPinnedViewComponent } from "./views/collections/pinned-view/pinned-view.component";
import { LocationDetailsViewComponent } from "./views/locations/details-view/details-view.component";
import { LocationDetailsViewWrapperComponent } from "./views/locations/details-view-wrapper/details-view-wrapper.component";
import { LocationPinnedViewComponent } from "./views/locations/pinned-view/pinned-view.component";
import { OAuthModule } from "angular-oauth2-oidc";
import {SmartAlertsComponent} from "./components/smart-alerts/smart-alerts.component";
import {DialogService} from "primeng/dynamicdialog";
import {NgOptimizedImage} from "@angular/common";
import {EntityCardComponent} from "./components/entity-card/entity-card.component";
import { ButtonDirective } from "primeng/button";
import { SmartAlertCodePipe } from "./pipes/smart-alert-code.pipe";
import { AlertStatusLabelPipe } from "./pipes/alert-status-label.pipe";
import { CloseAlertConfirmationPopupComponent } from "./popups/confirmation-popup/close-alert-confirmation-popup.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations: [
        AppComponent,
        ErrorComponent,
        EquipmentsComponent,
        EntityCardComponent,
        SiteFilterComponent,
        ZonesComponent,
        VhMobileFixDirective,
        HistoricalDataComponent,
        ShareLinkComponent,
        NoAccessComponent,
        ScheduleCalendarComponent,
        ExceptionScheduleTableComponent,
        TermsAndConditionsComponent,
        CustomerSpecificationComponent,
        SafeHtmlPipe,
        AlertStatusLabelPipe,
        SmartAlertCodePipe,
        LocationsComponent,
        CollectionsComponent,
        EquipmentsV2Component,
        EquipmentDetailsV2Component,
        FilterAndSearchComponent,
        EntityPointsComponent,
        EquipmentDetailsViewWrapperComponent,
        EquipmentDetailsViewComponent,
        EquipmentPinnedViewComponent,
        CollectionDetailsViewWrapperComponent,
        CollectionDetailsViewComponent,
        CollectionPinnedViewComponent,
        LocationDetailsViewComponent,
        LocationDetailsViewWrapperComponent,
        LocationPinnedViewComponent,
        SmartAlertsComponent
    ],
    imports: [
        LayoutModule,
        FormsModule,
        ButtonDirective,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        NoopAnimationsModule,
        ListboxModule,
        DropdownModule,
        MatIconModule,
        CheckboxModule,
        InputSwitchModule,
        MultiSelectModule,
        InputTextModule,
        TabViewModule,
        TreeModule,
        AccordionModule,
        CalendarModule,
        HighchartsChartModule,
        OverlayPanelModule,
        ClipboardModule,
        QrCodeModule,
        ToastModule,
        TooltipModule,
        ProgressSpinnerModule,
        DialogModule,
        FullCalendarModule,
        TableModule,
        PlatformModule,
        ConfirmDialogModule,
        InputTextareaModule,
        EditorModule,
        CloseAlertConfirmationPopupComponent,
        MatDialogModule,
        BrowserAnimationsModule,
        OAuthModule
            .forRoot
            // {
            //     resourceServer: {
            //         allowedUrls: ['https://api.dev.cbms.eon-optimum.com', 'https://api.dev.cbms.eon-optimum.com/admin-be'],
            //         sendAccessToken: true,
            //     },
            // }
            (),
        NgOptimizedImage,
    ],
    providers: [
        AlertStatusLabelPipe,
        SmartAlertCodePipe,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
        BackendService,
        SvgService,
        HttpBaseService,
        MessageService,
        ConfirmationService,
        NotificationService,
        CanDeactivateGuard,
        provideHttpClient(withInterceptorsFromDi()),
        DialogService,
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
