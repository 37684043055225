import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable, retry, throwError} from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { map, catchError, mergeMap, retryWhen } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';


enum BackendException {
    GATEWAY_NOT_REACHABLE = 'GatewayNotReachable',
    DEVICE_NOT_REACHABLE = 'DeviceNotReachable'
}

@Injectable()
export class HttpBaseService {
    // TODO: thats the simple version now, later: store requests and finish if all are done
    protected API_TO_USE = {
        'ccAPIUrl': environment.ccAPIUrl,
        'dashboardAPIUrl': environment.dashboardAPIUrl
    };

    constructor(private http: HttpClient,
        private notificationService: NotificationService,
        private authService: OAuthService) {
    }

    public get<T>(apiUrl: string, path: string, params?: HttpParams, handleError: boolean = true): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http.get(this.API_TO_USE[apiUrl] + `/${path}`, {
            headers: httpHeaders,
            params: ((params != null) ? params : new HttpParams())
        }).pipe(
            retry({ count: 3, delay: 1000 }),
            catchError((err => this.handleError(err, handleError)))
        ).pipe(
            map((response: any) => {
                if (response && response.content) {
                    return <T><unknown>response.content;
                } else {
                    return <T><unknown>response;
                }
            })
        );
    }

    public put<T>(path: string, params?: HttpParams, body?: any, handleError: boolean = true): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http.put(this.API_TO_USE.ccAPIUrl + `/${path}`, body, {
            headers: httpHeaders,
            params: ((params != null) ? params : new HttpParams())
        }).pipe(
            retry({ count: 3, delay: 1000 }),
            catchError((err => this.handleError(err, handleError)))
        ).pipe(
            map((response: any) => {
                return <T><unknown>response;
            })
        );
    }

    public post<T>(apiUrl: string, path: string, params?: HttpParams, body?: any, handleError: boolean = true, needsContentType: boolean = true): Observable<T> {
        let httpHeaders = new HttpHeaders();
        httpHeaders = httpHeaders.set(
            'Authorization',
            'Bearer ' + this.authService.getIdToken()
        );

        if (needsContentType) {
            httpHeaders.set('Content-Type', 'application/json');
        }

        return this.http.post(this.API_TO_USE[apiUrl] + `/${path}`, body, {
            headers: httpHeaders,
            params: ((params != null) ? params : new HttpParams())
        }).pipe(
            retry({ count: 3, delay: 1000 }),
            catchError((err => this.handleError(err, handleError, body)))
        ).pipe(
            map((response: any) => {
                return <T><unknown>response;
            })
        );
    }

    public patch<T>(apiUrl: string, path: string, params?: HttpParams, body?: any, handleError: boolean = true): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http.patch(this.API_TO_USE[apiUrl] + `/${path}`, body, {
            headers: httpHeaders,
            params: ((params != null) ? params : new HttpParams())
        }).pipe(
            retry({ count: 3, delay: 1000 }),
            catchError((err => this.handleError(err, handleError)))
        ).pipe(
            map((response: any) => {
                return <T><unknown>response;
            })
        );
    }

    public delete<T>(path: string, params?: HttpParams, body?: any, handleError: boolean = true): Observable<T> {
        const httpHeaders = this.getHeaders()

        return this.http.request('DELETE', this.API_TO_USE.ccAPIUrl + `/${path}`, {
            body: body,
            headers: httpHeaders,
            params: ((params != null) ? params : new HttpParams())
        }).pipe(
            retry({ count: 3, delay: 1000 }),
            catchError((err => this.handleError(err, handleError)))
        ).pipe(
            map((response: any) => {
                return <T><unknown>response;
            })
        );
    }

    private handleError = (error: HttpErrorResponse, handleError: boolean, body?: any) => {
        if (!handleError) {
            return throwError(error);
        }

        const response = error.error;

        if (error.status === 403 && response.error === 'NotAuthorized') {
            if (window.location.pathname !== '/error') {
                window.location.href = window.location.origin + '/error';
            }
            return throwError(error);
        }


        if (error.status === 0) {
            this.notificationService.addErrorMessage('Request timed out', 'Socket unexpectedly closed. Request most likely timed out.');
        }

        if (BackendException.GATEWAY_NOT_REACHABLE === response.error) {
            this.notificationService.addErrorMessage('Gateway not reachable !', `${response.message} in path ${response.path}`);
        }
        if (BackendException.DEVICE_NOT_REACHABLE === response.error) {
            this.notificationService.addErrorMessage('Device not reachable !', `${response.message} in path ${response.path}`);
        } else {
            this.notificationService.addErrorMessage(`Response with Status: ${error.status}`, `"${response.error}" in path ${response.path} : ${response.message}`);
        }

        return throwError(error.message);
    }

    private getHeaders() {
        let httpHeaders =new HttpHeaders()
        httpHeaders = httpHeaders.set('Content-Type', 'application/json');

        return httpHeaders.set(
            'Authorization',
            'Bearer ' + this.authService.getIdToken()
        );
    }
}
