import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogService } from "primeng/dynamicdialog";
import { Subscription } from "rxjs";
import { DAYS, USER_ROLES } from "src/app/config/constants";
import { EditBuildingOpeningTimeComponent } from "src/app/dialogs/edit-building-opening-time/edit-building-opening-time.component";
import { RolePermissionListRes } from "src/app/models/core/alert-rule.model";
import {
    SelectedSite,
    SiteInfo,
    UpdateSiteScheduleReq,
} from "src/app/models/site";
import { BackendService } from "src/app/services/backend/backend.service";
import {
    EventNotificationService,
    EventType,
} from "src/app/services/notification/event-notification.service";
import { NotificationService } from "src/app/services/notification/notification.service";
import { SiteFilterService } from "src/app/services/site-filter.service";

@Component({
    selector: "cc-site-overview",
    templateUrl: "./site-overview.component.html",
    styleUrl: "./site-overview.component.scss",
})
export class SiteOverviewComponent implements OnInit, OnDestroy {
    mobileViewSelected = false;
    selectedSite: SelectedSite;
    siteOverviewInfo: SiteInfo;
    siteOverviewLink: string;
    customerId: string;
    siteId: string;
    trackSiteChange$ = new Subscription();
    days = DAYS;
    selectedCustomer: any;
    userHasEditPermission = false;
    readonly EDIT_SCHEDULE_POPUP_NAME = "Building Opening Time";

    constructor(
        private eventNotificationService: EventNotificationService,
        private backendService: BackendService,
        private siteFilterService: SiteFilterService,
        private activatedRoute: ActivatedRoute,
        private route: Router,
        private dialogService: DialogService,
        private notificationServ: NotificationService
    ) {}

    ngOnInit(): void {
        this.selectedCustomer = this.backendService.getSelectedCustomer();
        console.log(this.selectedCustomer);
        this.siteId = this.activatedRoute.snapshot.paramMap.get("siteId");
        this.siteOverviewLink = window.location.href;
        this.loadSiteOverview();

        this.trackSiteChange$ =
            this.siteFilterService.siteFilterChanged$.subscribe(
                (selectedSite: SelectedSite) => {
                    if (selectedSite && selectedSite?.siteOverviewEnabled) {
                        this.siteId = selectedSite.siteId;
                        const newPath = `/site-overview/site/${selectedSite.siteId}`;
                        this.route.navigate([newPath]);
                        this.siteOverviewLink =
                            window.location.origin + newPath;
                        this.loadSiteOverview();
                    } else if (selectedSite?.siteOverviewEnabled === false) {
                        this.route.navigate(["equipments"]);
                    }
                }
            );

        this.eventNotificationService
            .getEventDispatcher$()
            .subscribe((event) => {
                if (event.type === EventType.MOBILE_VIEW_SELECTED) {
                    this.mobileViewSelected = <boolean>event.payload;
                }
            });

        this.backendService
            .getUserRolePermissionList()
            .subscribe((response: RolePermissionListRes) => {
                this.userHasEditPermission =
                    response.userRole.includes(USER_ROLES.ROLE_GLOBAL_ADMIN) ||
                    response.userRole.includes(USER_ROLES.ROLE_CUSTOMER_ADMIN);
            });
    }

    loadSiteOverview() {
        this.backendService
            .getSiteOverviewInfo(this.siteId)
            .subscribe((value: SiteInfo) => {
                this.siteOverviewInfo = value;
            });
    }

    ngOnDestroy(): void {
        this.trackSiteChange$.unsubscribe();
    }

    editSchedule() {
        let dialog = this.dialogService.open(EditBuildingOpeningTimeComponent, {
            header: "Edit Building Opening Time",
            contentStyle: { padding: "0" },
            width: "35%",
            styleClass: "min-width-popup",
            closable: true,
            data: { siteSchedule: this.siteOverviewInfo.siteSchedule },
        });
        dialog.onClose.subscribe((response) => {
            let siteSchedule = response?.siteSchedule;
            if (siteSchedule) {
                this.days.forEach((day) => {
                    if (!siteSchedule[day]?.isOpen) {
                        siteSchedule[day].openTime = "";
                        siteSchedule[day].closeTime = "";
                    }
                });

                const body: UpdateSiteScheduleReq = {
                    readOnly: this.siteOverviewInfo.readOnly,
                    id: this.siteOverviewInfo.id,
                    name: this.siteOverviewInfo.name,
                    customerId: this.siteOverviewInfo.customer.id,
                    dashboardEnabled: this.siteOverviewInfo.dashboardEnabled,
                    siteOverviewEnabled:
                        this.siteOverviewInfo.siteOverviewEnabled,
                    siteSchedule: siteSchedule,
                    timezone: this.siteOverviewInfo.timezone,
                };

                this.backendService.updateSiteScheduleInfo(body).subscribe(
                    (res: SiteInfo) => {
                        this.siteOverviewInfo.siteSchedule = res.siteSchedule;
                        this.notificationServ.addSuccessMessage(
                            this.EDIT_SCHEDULE_POPUP_NAME,
                            "Time updated successfully!"
                        );
                    },
                    (err) => {
                        console.log(err);
                        this.notificationServ.addSuccessMessage(
                            this.EDIT_SCHEDULE_POPUP_NAME,
                            "Time was not updated!"
                        );
                    }
                );
            }
        });
    }
}
