<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your pinned locations</h1>
        <div
            class="sub-navigation flex justify-content-between align-items-center"
        >
            <ng-container>
                <div class="grouped-buttons">
                    <button class="grouped-button-left button-gray-bg" [routerLink]="['/locations']">All
                        <span></span></button>
                    <button class="grouped-button-right button-gray-bg selected"
                        [routerLink]="['/locations/pinned']">Pinned <span>{{noOfPinnedLocations}}</span></button>
                </div>

                <div class="grouped-buttons desktop-navigation">
                    <button class="grouped-button-left button-gray-bg"
                        [ngClass]="cardType === 'summary' ? 'selected' : ''" (click)="showSummary()">Summary</button>
                    <button class="grouped-button-right button-gray-bg"
                        [ngClass]="cardType === 'detailed' ? 'selected' : ''" (click)="showDetailed()">Detailed</button>
                </div>

                <div class="mobile-navigation">
                    <div class="checkbox-switch">
                        <input type="checkbox" checked="true" (change)="toggleCardState($event)" name="status"
                            class="input-checkbox" id="toolbar-active">
                        <div class="checkbox-animate">
                            <span class="checkbox-off">Detailed</span>
                            <span class="checkbox-on">Summary</span>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>

<div class="main-wrapper">
    <div class="container no-padding">

        <div class="grid" *ngIf="cardType === 'summary'">
            <div class="col-12 xl:col-4" *ngFor="let locationCard of locations">
                <div class="entity-card" [routerLink]="['/locations', locationCard.id]">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img src="assets/svg/{{locationCard.classIcon}}.svg" width="48" height="48">
                        </div>
                        <div class="entity-details">
                            <h2
                                class="entity-name"
                                [pTooltip]="locationCard.label"
                                [tooltipDisabled]="
                                    locationCard.label &&
                                    locationCard.label.length <= 30
                                "
                                tooltipPosition="top"
                                [hideDelay]="100"
                            >
                                {{ locationCard.label }}
                            </h2>
                            <p class="entity-class">{{locationCard.classLabel}}</p>
                        </div>
                        <eon-ui-icon *ngIf="locationCard.pinned" class="top-right-position cursor-pointer" name="bookmark"
                            (click)="togglePin($event, locationCard)"></eon-ui-icon>
                        <eon-ui-icon *ngIf="!locationCard.pinned" class="top-right-position cursor-pointer" name="bookmark_outline"
                            (click)="togglePin($event, locationCard)"></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div class="entity-kpi flex align-items-center justify-content-around"
                            *ngIf="locationCard.selectedKpi?.signalType">
                            <ng-container [ngSwitch]="locationCard.selectedKpi.signalType">
                                <ng-container *ngSwitchCase="'Binary'">
                                    <span class="margin-right-05">{{locationCard.selectedKpi.comment ||
                                        locationCard.selectedKpi.classLabel}}</span>
                                    <p-inputSwitch styleClass="default-switch__black" [disabled]="true"
                                        [(ngModel)]="locationCard.selectedKpi.presentValue"></p-inputSwitch>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span class="margin-right-05">{{locationCard.selectedKpi.comment ||
                                        locationCard.selectedKpi.classLabel}}</span>
                                    <span class="kpi-value">
                                        {{locationCard.selectedKpi.presentValue}}
                                        <span style="font-size:1rem" [innerHTML]="locationCard.selectedKpi.htmlSymbol"></span>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <p-tabView styleClass="custom-no-bg" *ngIf="cardType === 'detailed'">
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/live-view.svg" />
                    <span>Live View</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12 xl:col-6" *ngFor="let locationDetails of locationDetailsList">
                        <cc-location-details-view [username]="username" [selectedSite]="selectedSite"
                            [locationDetails]="locationDetails"></cc-location-details-view>
                    </div>
                </div>
            </p-tabPanel>
            <p-tabPanel>
                <ng-template pTemplate="header">
                    <img src="assets/svg/dashboard.svg" />
                    <span>History</span>
                </ng-template>

                <div class="grid">
                    <div class="col-12" *ngIf="(pointsObj | json) != '{}'">
                        <cc-historical-data [pointsObj]="pointsObj"></cc-historical-data>
                    </div>
                </div>

            </p-tabPanel>
        </p-tabView>
    </div>
</div>

<p-confirmDialog #cd [style]="{width: '50vw'}" [breakpoints]="{'640px': '90vw'}" [style]="{width: '50vw'}">
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end" *ngIf="userHasWritePermission$ | async as userHasWritePermission">
            <eon-ui-button *ngIf="!isOverrideResetOn || !userHasWritePermission" size="small" scheme="turquoise" text="Close" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" class="margin-right-1" size="small" scheme="turquoise" text="No" (click)="cd.reject()"></eon-ui-button>
            <eon-ui-button *ngIf="isOverrideResetOn && userHasWritePermission" size="small" scheme="turquoise" text="Yes" (click)="cd.accept()"></eon-ui-button>
        </div>
    </ng-template>
</p-confirmDialog>
