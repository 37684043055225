@if (alerts.length) { @for (alert of alertsToRender; track alert.alertUUID) {
<div class="flex justify-content-between entity-row px-2">
    <div class="w-2">{{ alert.priority }}</div>
    <div class="w-2">{{ alert.type }}</div>
    <div class="w-2">{{ alert.label }}</div>
    <div class="w-2">
        @if (userHasEditPermission) {
        <p-dropdown
            class="custom-dropdown"
            [options]="alertStatusList"
            [filter]="true"
            [(ngModel)]="alert.statusLabel"
            (onChange)="updateSmartAlertStatus(alert, $event)"
        ></p-dropdown>
        } @else {
        {{ alert.status | alertStatusLabel }}
        }
    </div>
    <div>
        <button
            pButton
            type="button"
            icon="pi pi-bell"
            class="bell-button"
            (click)="showAlertDetails(alert.alertUUID)"
        ></button>
    </div>
</div>
} } @else {
<div class="mb-3">No active Smart Alerts</div>
}
