<div class="app-sub-header">
    <div class="container">
        <h1 class="page-title red-color">Your equipment</h1>
        <div class="flex align-items-center justify-content-between sub-navigation">
            <div class="grouped-buttons">
                <button class="grouped-button-left button-gray-bg selected" [routerLink]="['/equipments']">All
                    <span>{{equipments.length}}</span></button>
                <button class="grouped-button-right button-gray-bg" [routerLink]="['/equipments/pinned']">Pinned <span>{{noOfPinnedEquipments}}</span></button>
            </div>
            <cc-filter-and-search [dataToFilter]="equipments" [filterOptions]="equipmentFiltersOptions" [category]="category" (onFilterChanged)="onFilterChangedHandler($event)"></cc-filter-and-search>
        </div>
    </div>
</div>
<div class="main-wrapper" [ngClass]="mobileViewSelected ? 'margin-top70' : ''">
    <div class="container">
        <div class="grid" *ngIf="equipments.length">
            <div class="col-12 xl:col-4" *ngFor="let equipmentCard of equipments">
                <div class="entity-card" [routerLink]="['/equipments', equipmentCard.id]">
                    <div class="entity-card-content">
                        <div class="entity-icon">
                            <img src="assets/svg/{{equipmentCard.classIcon}}.svg" width="48" height="48">
                        </div>
                        <div class="entity-details">
                            <h2
                                class="entity-name"
                                [pTooltip]="equipmentCard.label"
                                [tooltipDisabled]="
                                    equipmentCard.label &&
                                    equipmentCard.label.length <= 30
                                "
                                tooltipPosition="top"
                                [hideDelay]="100"
                            >
                                {{ equipmentCard.label }}
                            </h2>
                            <p class="entity-class">{{equipmentCard.classLabel}}</p>
                        </div>
                        <eon-ui-icon *ngIf="equipmentCard.pinned" class="top-right-position cursor-pointer" name="bookmark"
                            (click)="togglePin($event, equipmentCard)"></eon-ui-icon>
                        <eon-ui-icon *ngIf="!equipmentCard.pinned" class="top-right-position cursor-pointer" name="bookmark_outline"
                            (click)="togglePin($event, equipmentCard)"></eon-ui-icon>
                    </div>
                    <div class="entity-card-footer">
                        <div class="entity-kpi flex align-items-center justify-content-around"
                            *ngIf="equipmentCard.selectedKpi?.signalType">
                            <ng-container [ngSwitch]="equipmentCard.selectedKpi.signalType">
                                <ng-container *ngSwitchCase="'Binary'">
                                    <span class="margin-right-05">{{equipmentCard.selectedKpi.comment ||
                                        equipmentCard.selectedKpi.classLabel}}</span>
                                    <p-inputSwitch styleClass="default-switch__black" [disabled]="true"
                                        [(ngModel)]="equipmentCard.selectedKpi.presentValue"></p-inputSwitch>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <span class="margin-right-05">{{equipmentCard.selectedKpi.comment ||
                                        equipmentCard.selectedKpi.classLabel}}</span>
                                    <span class="kpi-value">
                                        {{equipmentCard.selectedKpi.presentValue}}
                                        <span style="font-size:1rem" [innerHTML]="equipmentCard.selectedKpi.htmlSymbol"></span>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid" *ngIf="!equipments.length">
            <div class="col-12">
                <h2>No equipments found</h2>
            </div>
        </div>

    </div>

    <router-outlet></router-outlet>
</div>
