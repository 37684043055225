<div class="app-sub-header">
    <div class="container">
        <div class="grid">
            <div class="col-12 md:col-6">
                <h1 class="page-title red-color">Customer Specification</h1>
            </div>
            <div class="col-12 md:col-6 flex">
                <eon-ui-link
                    class="download-link"
                    href="/assets/UserGuide_CollaborativeBuildingManagementDashboards_V1.1.pdf"
                    text="Download User Guide"
                    download="true"
                    scheme="turquoise"
                ></eon-ui-link>
            </div>
        </div>

        <div class="flex align-items-center gap-3">
            <p-dropdown
                class="dd_min-width"
                [options]="customerList"
                [(ngModel)]="selectedCustomer"
                [filter]="true"
                filterBy="label,name"
                placeholder="Customers"
                optionLabel="name"
                [disabled]="isEditMode"
                (onChange)="onCustomerChosen()"
            >
            </p-dropdown>
            <eon-ui-button
                *ngIf="userHasWritePermission"
                class="margin-right-05"
                size="small"
                scheme="turquoise"
                (click)="activateEditMode()"
                [disabled]="!this.selectedCustomer?.id"
                text="Edit"
            ></eon-ui-button>
        </div>
    </div>
</div>
<div class="main-wrapper">
    <div class="container">
        <div class="grid">
            <div class="col-12">
                <div class="text-section" *ngIf="!isEditMode">
                    <h2 *ngIf="!customerSpecificationsText">
                        No specifications found.
                    </h2>
                    <div
                        *ngIf="customerSpecificationsText"
                        [innerHTML]="customerSpecificationsText | safeHtml"
                    ></div>
                </div>
                <editor 
                    *ngIf="isEditMode"
                    [init]="{
                        plugins: 'lists link image table code help wordcount',
                        toolbar: 'undo redo | styles | backcolor | forecolor | bold italic | alignleft aligncenter alignright alignjustify | outdent indent'
                    }"
                    apiKey="jgcvu0makfo31g2cr360uaj1syrvgfgreep0uk18atwmo5cz"
                    [(ngModel)]="specificationsTextToSave"
                ></editor>
            </div>
            <div
                *ngIf="isEditMode"
                class="col-12 flex justify-content-end gap-3"
            >
                <eon-ui-button
                    *ngIf="userHasWritePermission"
                    size="small"
                    scheme="turquoise"
                    (click)="save()"
                    [disabled]="!specificationsTextToSave"
                    text="Save"
                ></eon-ui-button>
                <eon-ui-button
                    *ngIf="userHasWritePermission"
                    size="small"
                    scheme="turquoise"
                    (click)="cancel()"
                    text="Cancel"
                ></eon-ui-button>
            </div>
        </div>
    </div>
</div>
