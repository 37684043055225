import {Component, OnDestroy, OnInit} from "@angular/core";
import { BINARY } from "src/app/config/constants";
import {
    Location,
    EntityFilters,
    EntityStatusResponse,
    DeviceStatus
} from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import {
    EventNotificationService,
    EventType,
} from "src/app/services/notification/event-notification.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { Subscription } from "rxjs";
import { OAuthService } from 'angular-oauth2-oidc';
import {EntityType} from "../../models/api/entity-type";
import {AuthorizationService} from "../../services/authorization.service";

@Component({
    selector: "cc-locations",
    templateUrl: "./locations.component.html",
    styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit, OnDestroy {
    locations: Location[] = [];
    entityStatus: { [key: string]: DeviceStatus } = {};
    mobileViewSelected = false;

    polledKPIPointsValuesSubscription: Subscription;

    locationFiltersOptions: EntityFilters[] = [];
    selectedLocationClassList: string[] = [];

    selectedSite: { siteId: string; customerId: string };
    searchText: string = "";
    username: string = "";
    category = "location";
    firstSiteLoad = true;
    noOfPinnedLocations: number = null;
    entityOverriddenStatuses: { [key: string]: boolean };

	private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private authService: OAuthService,
        private siteFilterService: SiteFilterService,
        private eventNotificationService: EventNotificationService,
        private authorizationService: AuthorizationService,
    ) {}

    ngOnInit() {
        this.username = this.authorizationService.getUsername();

        this.siteFilterService.siteFilterChanged$.subscribe((selectedSite) => {
            if (selectedSite === null) {
                return;
            }
            if (this.firstSiteLoad) {
                this.restoreFilters();
            }
            if (!this.firstSiteLoad) {
                this.resetFilters();
            }

            this.selectedSite = selectedSite;
            this.firstSiteLoad = false;
            this.clearPreviousData();
            this.getLocations();
        });

        this.eventNotificationService
            .getEventDispatcher$()
            .subscribe((event) => {
                if (event.type === EventType.MOBILE_VIEW_SELECTED) {
                    this.mobileViewSelected = <boolean>event.payload;
                }
            });
    }

    restoreFilters() {
        const filters =
            JSON.parse(sessionStorage.getItem(`${this.category}-filters`)) ||
            null;

        if (filters) {
            this.searchText = filters.searchText;
            this.selectedLocationClassList = filters.selectedClassList;
        }
    }

    resetFilters() {
        this.searchText = "";
        this.selectedLocationClassList = [];
        sessionStorage.setItem(
            `${this.category}-filters`,
            JSON.stringify({
                searchText: this.searchText,
                selectedClassList: this.selectedLocationClassList,
            })
        );
    }

    onFilterChangedHandler($event: any) {
        this.searchText = $event.searchText;
        this.selectedLocationClassList = $event.selectedClassList;

        if ($event.action === "apply") {
            this.filterLocations();
        }

        if ($event.action === "clearFilter") {
            this.clearLocationsFilters();
        }

        if ($event.action === "clearSearch") {
            this.clearSearchInput();
        }
    }

    getLocations() {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedLocationClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
                this.loadEntityStatus(this.locations);
                this.loadEntityOverriddenStatus();
                this.noOfPinnedLocations = this.locations.filter(item => item.pinned).length;
                this.generateLocationsFilters();
                this.callKpiPolling(response
                    .flatMap((value) => value.selectedKpi)
                    .map((value: any) => value.pointUUID))
            });
    }

    generateLocationsFilters() {
        this.backendService
            .getLocationsClassifications(this.selectedSite.siteId)
            .subscribe((response: any[]) => {
                let locationsClassification = {};

                response.map((item) => {
                    let locationCategory = item.category.split("#")[1];
                    if (!locationsClassification[locationCategory]) {
                        locationsClassification[locationCategory] = [];
                    }
                    locationsClassification[locationCategory].push(
                        item.entityClass.split("#")[1].replaceAll("_", " ")
                    );
                });

                this.locationFiltersOptions = Object.keys(
                    locationsClassification
                ).map((category) => {
                    return {
                        label: category.replaceAll("_", " "),
                        value: category,
                        items: locationsClassification[category].map(
                            (eqClass: string) => {
                                return {
                                    label: eqClass.replaceAll("_", " "),
                                    value: eqClass,
                                };
                            }
                        ),
                    };
                });
            });
    }

    filterLocations() {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                this.searchText,
                this.selectedLocationClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
            });
    }

    clearFilters(searchText: string, equipmentClassList: string[]) {
        this.backendService
            .getDataByCategory(
                this.category,
                this.username,
                this.selectedSite,
                searchText,
                equipmentClassList
            )
            .subscribe((response: Location[]) => {
                this.locations = response;
                this.loadEntityStatus(this.locations);
            });
    }

    clearSearchInput() {
        this.searchText = "";
        this.clearFilters(this.searchText, this.selectedLocationClassList);
    }

    clearLocationsFilters() {
        this.selectedLocationClassList = [];
        this.clearFilters(this.searchText, this.selectedLocationClassList);
    }

    clearPreviousData() {
        if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}
		this.closeRsocket();
        this.locations = [];
    }

    callKpiPolling(datapointIdListFilter: string[]) {
		////////
		// Create an instance of a rSocketClient
		this.rSocketClient = this.backendService.getRSocketClient();
		// Open the connection
		this.polledKPIPointsValuesSubscription = this.rSocketClient.connect().subscribe({
			onComplete: (socket: RSocket) => {
				let requestStream = 'request-stream-get-datapoint-list2';
				socket
					.requestStream({
						data: {
							'siteId': this.selectedSite.siteId,
							'datapointIdListFilter': datapointIdListFilter.filter(datapointId => datapointId !== null),
							jwtToken: this.authService.getIdToken(),
							'interaction': 'Request'
						}, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
						metadata: String.fromCharCode(requestStream.length) + requestStream
					})
					.subscribe({
						onComplete: () => console.log('complete'),
						onError: error => {
							console.log("Connection has been closed due to:: " + error);
						},
						onNext: payload => {
							this.mapLocationKPI(payload.data);
						},
						onSubscribe: subscription => {
							subscription.request(1000000);
						},
					});

			},
			onError: error => {
				console.log("Connection has been refused due to:: " + error);
			},
			onSubscribe: cancel => {
				/* call cancel() to abort */
			}
		});

	}

    mapLocationKPI(point: any) {
		const mappedPointsPerId = {};

		this.locations.forEach(location => {
			const pointObjectReference = location.selectedKpi;
			if (pointObjectReference) {
				mappedPointsPerId[pointObjectReference.pointUUID] = pointObjectReference;
			}
		})

		const existingPointObjectReference = mappedPointsPerId[point.id];
		if (existingPointObjectReference) {
			existingPointObjectReference.presentValue = point.lastRecordedValue;
			existingPointObjectReference.signalType = point.signalType;
			existingPointObjectReference.type = point.type;
			existingPointObjectReference.units = point.units;

			if (point.signalType === BINARY) {
				existingPointObjectReference.presentValue = point.lastRecordedValue ? true : false;
			}
		}
	}

    ngOnDestroy() {
		if (this.polledKPIPointsValuesSubscription) {
			this.polledKPIPointsValuesSubscription.unsubscribe();
		}

		this.closeRsocket();
	}

    onCardPinToggle(pinnedStatus: "PINNED" | "UNPINNED") {
        if (pinnedStatus === "PINNED") {
            this.noOfPinnedLocations++;
        } else {
            this.noOfPinnedLocations--;
        }
    }

    private closeRsocket() {
		if (this.rSocketClient) {
			this.rSocketClient.close();
		}
	}

    private loadEntityStatus(locations: Location[]) {
        const params = {
            ...this.selectedSite,
            entityIds: locations.map((location) => location.id),
            entityType: EntityType.LOCATION,
        }
        this.backendService.getEntitiesStatus(params).subscribe((response: EntityStatusResponse) => this.entityStatus = response.entityStatus);
    }

    private loadEntityOverriddenStatus() {
        this.backendService.loadEntityOverriddenStatus(this.locations, EntityType.LOCATION, this.selectedSite).subscribe(response => {
            this.entityOverriddenStatuses = response.entityOverriddenStatuses;
        });
    }

    protected readonly EntityType = EntityType;
}
