@if (!isLoading) {
    <div class="p-3">
        <div class="grid">
            <div class="col-2">Customer</div>
            <div class="col-10">{{ alertData.alarmConfig.site.customer.name }}</div>
        </div>

        <div class="grid">
            <div class="col-2">Site</div>
            <div class="col-10">{{ alertData.alarmConfig.site.name }}</div>
        </div>

        <div class="grid">
            <div class="col-2">Alert Name</div>
            <div class="col-10">{{ alertData.alarmConfig.name }}</div>
        </div>

        <div class="grid">
            <div class="col-2">Alert Type</div>
            <div class="col-10">{{ alertData.alarmConfig.type }}</div>
        </div>

        <div class="grid">
            <div class="col-2">Alert Lifecycle</div>
            <div class="col-10">{{ alertData.alarmBackendStatus }}</div>
        </div>

        <div class="grid">
            <div class="col-2">Alert Description</div>
            <div class="col-10">
                <span>{{ alertData.alarmConfig.description }}</span>
            </div>
        </div>

        <hr>

        <cc-query-builder [isReadOnly]="true" [query]="alertData.alarmFilterMatcherResult"></cc-query-builder>

        <button
            pButton
            class="p-button-raised button-turquoise-bg mb-3"
            (click)="showTelemetryDialog()"
        >
            View Analysis
        </button>

        <div class="grid">
            <div class="col-2">Time Alert Started</div>
            <div class="col-8">
                {{ alertData.startDate | date : 'dd/MM/yyy, HH:mm:ss' }}
            </div>
            <div class="col-2">
                <div class="cbms-redBg">Active Rule or Condition</div>
            </div>
        </div>

        <div class="grid">
            <div class="col-2">Time Alert Ended</div>
            <div class="col-8">
                {{ alertData.endDate === null ? 'N/A' : (alertData.endDate | date : 'dd.MM.yyy, HH:mm:ss') }}
            </div>
            <div class="col-2">
                <div class="cbms-yellowBg">Missing Historical Data</div>
            </div>
        </div>
    </div>
}

<div class="footer">
    <button
        pButton
        class="p-button-raised button-turquoise-bg"
        (click)="close()">
        Close
    </button>
</div>
