<div class="py-5">
    <div>
        <div class="q-button-group q-right-align">
            <span *ngIf="isReadOnly && !parentValue">Last recorded value</span>
        </div>

        <div>
            @if (query.condition === 'or') {
                OR
            } @else {
                AND
            }
        </div>
    </div>

    <div class="q-tree-container">
        <ul class="q-tree">
            <ng-container *ngFor="let rule of query.rules; let i = index">
                <ng-container *ngIf="{ isRuleset: !!rule.rules } as local">
                    <li class="q-row q-connector q-transition q-rule"
                        [ngClass]="rule.isLastRecordedValueObsolete ? 'cbms-yellowBg' : rule.isMatching ? 'cbms-redBg' : ''">
                        <ng-container *ngIf="!local.isRuleset">
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.datapointName }}
                                </ng-container>
                            </div>
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.operator }}
                                </ng-container>
                            </div>
                            <div class="q-control-size">
                                <ng-container *ngIf="isReadOnly">
                                    {{ rule.value }}
                                </ng-container>
                            </div>
                            <div class="ml-auto" *ngIf="isReadOnly">
                                <span>{{ rule.matchWithValue }}</span>
                            </div>
                        </ng-container>

                        <cc-query-builder
                            *ngIf="local.isRuleset"
                            [isReadOnly]="isReadOnly"
                            [parentValue]="query"
                            [query]="rule"
                        ></cc-query-builder>
                    </li>
                </ng-container>
            </ng-container>
        </ul>
    </div>
</div>
