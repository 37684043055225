import {Component, OnInit} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, combineLatest } from "rxjs";
import {DeviceStatus, EntityStatusResponse, LocationDetails, Point} from "src/app/models/dashboard";
import { BackendService } from "src/app/services/backend/backend.service";
import { SiteFilterService } from "src/app/services/site-filter.service";
import { StoreService } from "src/app/services/store.service";
import { RSocket, RSocketClient } from "rsocket-core";
import { BINARY } from "src/app/config/constants";
import { OAuthService } from 'angular-oauth2-oidc';
import {EntityType} from "../../../models/api/entity-type";

@Component({
    selector: "cc-location-details-view-wrapper",
    templateUrl: "./details-view-wrapper.component.html",
    styleUrls: ["./details-view-wrapper.component.scss"],
})
export class LocationDetailsViewWrapperComponent implements OnInit {
    locationDetails: LocationDetails;
    pointsStatus: { [key: string]: DeviceStatus } = {};

    locationPointsArray: Point[];
	pointsObj: { [key: string]: { [key: string]: Point[] } } = {};
    username: string = "";
    isOverrideResetOn: boolean = false;
	userHasWritePermission$: Observable<boolean>;
    selectedSite: { siteId: string; customerId: string };

    locationId: string;
    private mainSubscription: Subscription;
    private polledPointsValuesSubscription: Subscription;
    private rSocketClient: RSocketClient;

    constructor(
        private backendService: BackendService,
        private activatedRoute: ActivatedRoute,
        private siteFilterService: SiteFilterService,
        private storeService: StoreService,
        private authService: OAuthService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        const pathnameArray = window.location.pathname.split('/');
		if (pathnameArray[1] === 'customers' && pathnameArray[5] === 'equipments') {
			this.router.navigate([`/collections/${pathnameArray[6]}`]);
		}

        this.userHasWritePermission$ = this.storeService.observable;

		this.storeService.canRemoveOverrideChanged$.subscribe((value) => {
			this.isOverrideResetOn = value;
		})

        const claims = this.authService.getIdentityClaims();
        this.username = claims ? claims['email'] : '';

        this.mainSubscription = combineLatest([
            this.activatedRoute.paramMap,
            this.siteFilterService.siteFilterChanged$,
        ]).subscribe(([routeParamMap, selectedSite]) => {
            this.selectedSite = selectedSite;
            let siteId = selectedSite?.siteId;
            this.locationId = routeParamMap["params"]["id"];

            if (!siteId) {
                return;
            }

            this.backendService
                .getLocationDetails(siteId, this.username, this.locationId, selectedSite?.customerId)
                .subscribe((response) => {
                    this.locationDetails = response;
                    this.loadDataPointsStatus(this.locationId);
                    this.locationPointsArray = [
                        ...this.locationDetails.setpointsAndParametersDatapointsList,
                        ...this.locationDetails
                            .commandsDatapointsList,
                        ...this.locationDetails
                            .sensorsAndStatusDatapointsList,
                        ...this.locationDetails.alarmsDatapointsList
                    ];

					this.pointsObj = { [this.locationDetails.label] : { [this.locationDetails.label]: this.locationPointsArray }};
					this.callKpiPolling(siteId, this.locationPointsArray.map(point => point.pointUUID));
                });
        });
    }

    ngOnDestroy() {
        if (this.mainSubscription) {
            this.mainSubscription.unsubscribe();
        }

        if (this.polledPointsValuesSubscription) {
            this.polledPointsValuesSubscription.unsubscribe();
        }

		this.closeRsocket();
    }

    callKpiPolling(siteId: string, datapointIdListFilter: string[]) {
        ////////
        // Create an instance of a rSocketClient
        this.rSocketClient = this.backendService.getRSocketClient();
        // Open the connection
        this.polledPointsValuesSubscription = this.rSocketClient
            .connect()
            .subscribe({
                onComplete: (socket: RSocket) => {
                    let requestStream = "request-stream-get-datapoint-list2";
                    socket
                        .requestStream({
                            data: {
                                siteId: siteId,
                                datapointIdListFilter: datapointIdListFilter,
                                //TODO get token from authentication
                                'jwtToken': this.authService.getIdToken(),
                                interaction: "Request",
                            }, // null is a must if it does not include a message payload, else the Spring server side will not be matched.
                            metadata:
                                String.fromCharCode(requestStream.length) +
                                requestStream,
                        })
                        .subscribe({
                            onComplete: () => console.log("complete"),
                            onError: (error) => {
                                console.log(
                                    "Connection has been closed due to:: " +
                                        error
                                );
                            },
                            onNext: (payload) => {
                                this.mapLocationPoints(payload.data);
                            },
                            onSubscribe: (subscription) => {
                                subscription.request(1000000);
                            },
                        });
                },
                onError: (error) => {
                    console.log(
                        "Connection has been refused due to:: " + error
                    );
                },
                onSubscribe: (cancel) => {
                    /* call cancel() to abort */
                },
            });
    }

    mapLocationPoints(point: any) {
        //do not update locked values;
        if (
            Object.keys(this.storeService.getLockedPoints()).includes(point.id)
        ) {
            return;
        }

        let pointToUpdate = this.locationPointsArray.find(
            (locationPoint) => locationPoint.pointUUID === point.id
        );

        if (pointToUpdate) {
            pointToUpdate.extensions = point.extensions;
            pointToUpdate.presentValue = point.lastRecordedValue;
            pointToUpdate.signalType = point.signalType;
            pointToUpdate.type = point.type;
            pointToUpdate.units = point.units;
            pointToUpdate.isWritable = this.isPointWritable(pointToUpdate);
            pointToUpdate.isOverridden = point.isOverridden;
            pointToUpdate.activeOverrides = point.activeOverrides;

            if (point.signalType === BINARY) {
                pointToUpdate.presentValue = point.lastRecordedValue
                    ? true
                    : false;
            }
        }
    }

    private loadDataPointsStatus(locationId: string) {
        const params = {
            ...this.selectedSite,
            entityIds: [locationId],
            entityType: EntityType.LOCATION
        }
        this.backendService.getEntitiesStatus(params).subscribe((response: EntityStatusResponse) => this.pointsStatus = response.entityStatus);
    }


    private isPointWritable(point: Point) {
        const pointMainClass = point.class.split("_").pop();
        if (
            ["Command", "Parameter", "Setpoint", "Limit"].includes(
                pointMainClass
            )
        )
            return true;
        if (["Alarm", "Sensor", "Status"].includes(pointMainClass))
            return false;
    }

    private closeRsocket() {
        if (this.rSocketClient) {
            this.rSocketClient.close();
        }
    }
}
