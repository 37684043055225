export enum ENV_NAME {
    PROD = "PROD",
    DEV = "DEV",
    LOCAL = "LOCAL",
}

// export const AAD_B2C = {
//     'DEV': {
//         clientId: '093651c6-fb00-4c22-999e-ab38f8244419',
//         aadAuthority: 'https://eonemsb2c.b2clogin.com/login.dev-qa.eon-optimum.com/B2C_1_connect',
//         resourceScope: 'https://login.dev-qa.eon-optimum.com/candc/connect.control',
//         knownAuthorities: 'eonemsb2c.b2clogin.com'
//     },
//     'PROD': {
//         clientId: '0bb0d8f7-d6b2-4f87-b079-4ce15f030538',
//         aadAuthority: 'https://optimumprodb2c.b2clogin.com/optimumprodb2c.onmicrosoft.com/B2C_1_Connect',
//         resourceScope: 'https://optimumprodb2c.onmicrosoft.com/candc/connect.control',
//         knownAuthorities: 'optimumprodb2c.b2clogin.com'
//     }
// }

export const AUTH0_CONFIG = {
    DEV: {
        issuer: "https://login.dev.eon-optimum.com",
        redirectUri: window.location.origin,
        //clientId: 'wedf8bYdPwL2Kz8NNZltYUaUa8OrWVZQ',
        clientId: "LqyW08BevUCrSvNHs8hwCGwK6hwD9qhU",
        scope: "openid name email",
        responseType: "code",
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    },
    PROD: {
        issuer: "https://login.eon-optimum.com",
        redirectUri: window.location.origin,
        clientId: "lOmEO7dGVfVON7ZuFSzHedcENqmsgIMw",
        scope: "openid name email",
        responseType: "code",
        requestAccessToken: false,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
    },
};

export const TESCO_CONFIG = {
    DEV: {
        issuer: 'https://loginppe.ourtesco.com/oidc/2',
        redirectUri: window.location.origin,
        clientId: '48f8a9b0-c11d-013d-6409-09e32d64717337548',
        scope: 'openid name profile groups email params',
        responseType: 'code',
        requestAccessToken: true,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
        dummyClientSecret: '3ee121c5d39221dcb944c1c52b2d0ef4896b7ca7f66e2ec5b3f542bf2d744c20',
        logoutUrl: 'https://loginppe.ourtesco.com/',
        postLogoutRedirectUri: window.location.origin,
    },
    PROD: {
        issuer: 'https://login.ourtesco.com/oidc/2',
        redirectUri: window.location.origin,
        clientId: 'todo: add it when available',//todo add it when available
        scope: 'openid name profile groups email params',
        responseType: 'code',
        requestAccessToken: true,
        requireHttps: false,
        strictDiscoveryDocumentValidation: false,
        skipIssuerCheck: true,
        oidc: true,
        showDebugInformation: true,
        disableAtHashCheck: true,
        dummyClientSecret: 'todo: add it when available',//todo add it when available
        logoutUrl: 'https://login.ourtesco.com/',
        postLogoutRedirectUri: window.location.origin,
    },
};

export const LOGIN_IDP = 'loginIdp';
export enum IDP_PROVIDER {
    AUTH0 = "auth0",
    TESCO_CC = "tesco-cc",
    TESCO_DASHBOARDS = "tesco-dashboards",
}


export enum TimeFramesValues {
    TODAY = 0,
    YESTERDAY = 1,
    LAST_7_DAYS = 7,
    LAST_30_DAYS = 30,
    LAST_365_DAYS = 365,
}

export enum Operation {
    Add = "ADD",
    Delete = "DELETE",
}

export const PAGINATION = {
    SIZE: 100,
    DEFAULT_PAGE_NR: 0,
};
export const PAGE_SIZE_50 = 50;
export const PAGE_SIZE_1000 = 1000;
export const PAGE_SIZE_10k = 10000;

export const ExternalSource = {
    BACKNET: "CC",
    WISEMETER: "WiseMeter",
};

export const TRUE = "true";
export const ANALOG = "Analog";
export const BINARY = "Binary";
export const MULTISTATE = "MultiState";
export const MANUAL_DATAPOINTS_TABLE_SIZE = "cbms.datapoints.page.size";

export const ViewMode = {
    PINNED: "pinned",
    EQUIPMENT: "equipment",
};

export const JWT_TOKEN_WEBSCKET =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImtpZCI6Ilg1ZVhrNHh5b2pORnVtMWtsMll0djhkbE5QNC1jNTdkTzZRR1RWQndhTmsifQ.eyJpc3MiOiJodHRwczovL2VvbmVtc2IyYy5iMmNsb2dpbi5jb20vMjYxYWEwYmEtODQzMC00NzVhLWI1ZGMtNjk5ZmVmMjA3Y2Q4L3YyLjAvIiwiZXhwIjoxNjQ2NzQ2MDA2LCJuYmYiOjE2NDY3NDI0MDYsImF1ZCI6IjA5MzY1MWM2LWZiMDAtNGMyMi05OTllLWFiMzhmODI0NDQxOSIsIm9pZCI6ImZlMGI1MjBhLWZhZTYtNDZjNi1hN2NiLTI4Y2JlYjJkZGU0MSIsInN1YiI6ImZlMGI1MjBhLWZhZTYtNDZjNi1hN2NiLTI4Y2JlYjJkZGU0MSIsIm5hbWUiOiJBbGV4YW5kcnUgTHVuZ3UiLCJlbWFpbHMiOlsiYWxleGFuZHJ1Lmx1bmd1QGVvbi1yb21hbmlhLnJvIl0sInRmcCI6IkIyQ18xX2Nvbm5lY3QiLCJub25jZSI6Ijg0YThkYWJiLWE4MjktNDU3NS05MjJjLWE5NzY2MjQxNjhhNiIsInNjcCI6ImNvbm5lY3QuY29udHJvbCIsImF6cCI6IjA5MzY1MWM2LWZiMDAtNGMyMi05OTllLWFiMzhmODI0NDQxOSIsInZlciI6IjEuMCIsImlhdCI6MTY0Njc0MjQwNn0.DSOWkP9mzxezcfB1WauVSTgqckEUKy2UarIR8g7mULjsuHTjp1VgB65oIlmDAHv_3JjIOs8PkdU2nl1cwSB6Kkswk8-jGZIit8yyaIi8NDu7nr7Ok_YWVAy23Idyq2UaIqxdNhjqbg3q09obxxxBgaVpcNcCOhd_2eh7iV-APnljQ7x6SZR3YreIbSLgGNVO0-r6AmxmR4c4s2nyO90zJ12C0soo8iO8T1UsYkNScCq_lnAO3K2ObNYv1T3eNDbKlDU8C_ug0UGvUYWG-hg-7Z60zGTtJFuiBaADRYOmAvkmeWH9M6MofXKlil2tr4jf_poT_Tiu3ybZ4uqy2RNkgw";

export const COLOR_LIST = [
    "#EA1C0A", //eon-red
    "#008000", //eon-green
    "#1EA2B1", //eon-turquoise
    "#B00402", //eon-bordeaux
    "#E3E000", //eon-limeyellow
    "#D51607", //eon-red-active
    "#2B91A3", //eon-turqoise-active
    "#A00311", //eon-bordeaux-active
    "#DAD600", //eon-limeyellow-active
    "#f05548", //eon-red-75
    "#76020C", //eon-bordeaux-dark
    "#0F738A", //eon-turquoise-dark
    "#eae840", //eon-limeyellow-75
    "#d78180", //eon-bordeaux-50,
    "#f48D84", //eon-red-50
    "#56b9c5", //eon-turquoise-75
    "#f1ef7f", //eon-limeyellow-50
];

export const COLOR_LIST_EXTENDED = [
    "#1EA2B1",
    "#EA1C0A",
    "#E3E000",
    "#B00402",
    "#0F738A",
    "#76020C",
    "#450096",
    "#1797E8",
    "#9B9B92",
    "#FB7D10",
    "#31B029",
    "#B5ECF2",
    "#FAA49D",
    "#FEFECC",
    "#FD9B9A",
    "#B1E9F6",
    "#FD9AA3",
    "#E3CCFE",
    "#B1DCF7",
    "#E6E6E4",
    "#FCAD68",
    "#D7F5D5",
    "#8BE1EB",
    "#F8796E",
    "#FFFD99",
    "#FD6B69",
    "#85DDF1",
    "#FC6A77",
    "#C799FF",
    "#84C8F2",
    "#CECEC9",
    "#FB9A45",
    "#B0EBAC",
    "#62D7E4",
    "#F64E3F",
    "#FFFC66",
    "#FC3B39",
    "#59D1ED",
    "#FB3A4B",
    "#AC66FF",
    "#57B4EE",
    "#B5B5AF",
    "#FB8622",
    "#88E282",
    "#38CCDD",
    "#F42210",
    "#FFFC33",
    "#FC0B08",
    "#2DC5E8",
    "#FA0A1F",
    "#9033FF",
    "#2BA0EA",
    "#9D9D94",
    "#F57404",
    "#61D859",
    "#21B2C3",
    "#CB1808",
    "#FFFB00",
    "#D10402",
    "#16ABCE",
    "#D00315",
    "#7500FF",
    "#1487CF",
    "#84847A",
    "#D26303",
    "#39CE30",
    "#1A8C99",
    "#9C1206",
    "#CCC900",
    "#A10301",
    "#1186A1",
    "#A00210",
    "#5D00CC",
    "#106AA3",
    "#6A6A61",
    "#AF5302",
    "#2EA526",
    "#12666F",
    "#6D0D04",
    "#999600",
    "#700201",
    "#0C6275",
    "#70010B",
    "#460099",
    "#0B4D76",
    "#4F4F49",
    "#8C4202",
    "#227C1C",
    "#0B3F45",
    "#3E0702",
    "#666400",
    "#400100",
    "#083D49",
    "#400106",
    "#2E0066",
    "#07304A",
    "#353530",
    "#693101",
    "#175213",
];

export const ONE_HOUR_IN_SECONDS = 3600000;

export const COLORS = {
    WHITE: "#FFFFFF",
    BLACK: "#000000",
    RED: "#EA1C0A",
    TURQOISE: "#1EA2B1",
    LIME_YELLOW: "#E3E000",
};

export const ANY = "Any";

export enum Months {
    Any = 0,
    January = 1,
    February = 2,
    March = 3,
    April = 4,
    May = 5,
    June = 6,
    July = 7,
    August = 8,
    September = 9,
    October = 10,
    November = 11,
    December = 12,
}

export enum DayOfWeek {
    Any = 0,
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 7,
}

export const WEEK_OF_MONTH_TEXT = {
    0: "Any",
    1: "Week1",
    2: "Week2",
    3: "Week3",
    4: "Week4",
    5: "Week5",
    6: "Last week of month",
    7: "2nd last week of the month",
    8: "3rd last week of the month",
    9: "4th last week of the month",
};

export enum FieldList {
    POLLED = "Polled",
    SITE_ASSIGNED = "Site Assigned",
    NAME = "Name",
    TYPE = "IO-Type",
    SIGNAL_TYPE = "Signal Type",
    SOURCE = "Source",
    SOURCE_DESCRIPTION = "Source Description",
    CUSTOM_NAME = "Custom Name",
    UNITS = "Units",
    LAST_RECORDED_VALUE = "Last recorded value",
    DATA_POINT_INTERNAL_ID = "Datapoint ID",
    WISEMETER_ID = "ID",
    INSTANCE = "Instance",
    CLASSIFICATION = "Classification",
}

export enum USER_ROLES {
    ROLE_GLOBAL_ADMIN = "ROLE_GLOBAL_ADMIN",
    ROLE_CUSTOMER_ADMIN = "ROLE_CUSTOMER_ADMIN",
    ROLE_CUSTOMER_USER = "ROLE_CUSTOMER_USER",
    ROLE_CUSTOMER_OPERATOR = "ROLE_CUSTOMER_OPERATOR",
}

export const IN_PROGRESS = "IN PROGRESS";
