import {Injectable} from '@angular/core';
import {saveAs} from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class CsvExportService {

    private saveToCsv(headerRow1: string[], headerRow2: string[], data: Array<Array<string | number | null>>, fileName: string): void {
        const replacer = (key, value) => value === null ? '' : value;

        // Combine header rows
        let csv = [
            headerRow1.join(','),
            headerRow2.join(',')
        ];

        // Process data rows
        csv = csv.concat(data.map(row =>
            row.map(value => JSON.stringify(value, replacer)).join(',')
        ));

        let csvArray = csv.join('\r\n');

        const blob = new Blob([csvArray], {type: 'text/csv'});
        saveAs(blob, fileName + '.csv');
    }


    exportToCsv(options: Highcharts.Options, fileName: string) {
        const headers = ['', ''];
        const firstRow = ['Date', 'Time'];

        // Create an efficient timestamp index
        const timeIndex = new Map<string, {
            values: Map<string, number | null>,
            timestamp: number
        }>();

        // Single pass through data
        options.series.forEach((series, seriesIndex) => {
            headers.push(series.name);
            firstRow.push(`Unit: ${options.yAxis[series.yAxis as number].title.text}`);

            // @ts-ignore
            (series.data as Array<[number, number]>).forEach(([timestamp, value]) => {
                const date = new Date(timestamp);
                const key = `${date.toISOString().split('T')[0]}-${date.getHours()}:${date.getMinutes()}`;

                if (!timeIndex.has(key)) {
                    timeIndex.set(key, {
                        values: new Map(),
                        timestamp: timestamp
                    });
                }
                timeIndex.get(key)!.values.set(series.name, value);
            });
        });

        // Convert to final format in a single operation
        const formattedData = Array.from(timeIndex.entries())
            .sort(([, a], [, b]) => a.timestamp - b.timestamp)
            .map(([, entry]) => {
                const date = new Date(entry.timestamp);
                return [
                    date.toLocaleDateString(),
                    `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`,
                    ...headers.slice(2).map(name => entry.values.get(name) ?? null)
                ];
            });

        this.saveToCsv(headers, firstRow, formattedData, fileName);
    }
}
