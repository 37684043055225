import { Component } from "@angular/core";

@Component({
    selector: "cc-equipment-details-v2",
    templateUrl: "./equipment-details-v2.component.html",
    styleUrls: ["./equipment-details-v2.component.scss"],
})
export class EquipmentDetailsV2Component {
	switchOn = true;
}
