import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[ccPreventDash]'
})
export class PreventDashDirective {

  constructor() { }
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    // If the key pressed is a dash, prevent it
    if (event.key === '-') {
      event.preventDefault();
    }
  }
}
