import { Component, OnInit, Renderer2 } from '@angular/core';
import { BackendService } from 'src/app/services/backend/backend.service';
import { SiteFilterService } from 'src/app/services/site-filter.service';
import { Router } from '@angular/router';
import _ from 'lodash';
import { EventNotificationService, EventType } from 'src/app/services/notification/event-notification.service';
import { UtilsService } from 'src/app/services/utils/util.service';
import { Customer } from 'src/app/models/customer';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
	selector: 'cc-site-filter',
	templateUrl: './site-filter.component.html',
	styleUrls: ['./site-filter.component.scss']
})
export class SiteFilterComponent implements OnInit {
	public isSiteSeletionActive: boolean = false;

	groupedSites: {
		label: string,
		value: string,
		items: any[]
	}[];

	selectedSite: {
		label: string,
		value: string,
		customerId: string,
		siteOverviewEnabled: boolean,
		timezone: string
	};

	sharedSiteId: string = '';
	sharedCustomerId: string = '';

	disableSiteSelection: boolean = false;

	constructor(
		private renderer: Renderer2,
		private backendService: BackendService,
		private siteFilterService: SiteFilterService,
		private router: Router,
		private eventNotificationService: EventNotificationService,
		private utilsService: UtilsService,
		private authService: OAuthService
		) {
	}

	ngOnInit(): void {
		const pathnameArray = window.location.pathname.split('/');

		if (pathnameArray[1] === 'customers') {
			this.sharedCustomerId = pathnameArray[2];
			this.sharedSiteId = pathnameArray[4];
		}

		this.backendService.getCustomers().subscribe(response => {
			this.groupedSites = response.map( (customer: Customer) => {
				return {
					label: customer.name,
					value: customer.id,
					items: [...this.utilsService.orderListByFieldName(customer.siteList, 'name').map( (site: any) => {
						return { label: site.name, value: site.id, customerId: customer.id, timezone: site.timezone, siteOverviewEnabled: site.siteOverviewEnabled }
					})]
				}
			});

			if (this.sharedCustomerId) {
				const customer = response.find(customer => customer.id === this.sharedCustomerId);
				if (customer) {
					const site = customer.siteList.find(item => item.id === this.sharedSiteId);
					setTimeout(() => {
						this.selectedSite = {
							label: site.name,
							value: site.id,
							customerId: customer.id,
							timezone: site.timezone,
							siteOverviewEnabled: site.siteOverviewEnabled,
						};

						this.siteFilterService.announceSiteChange({ siteId: this.selectedSite.value, customerId: this.selectedSite.customerId, siteOverviewEnabled: this.selectedSite.siteOverviewEnabled });
						sessionStorage.setItem(`selectedSite`, JSON.stringify(this.selectedSite));
					}, 100)
				} else {
					this.router.navigate(['/no-access']);
				}
			} else {
				setTimeout(() => {
					this.selectedSite = JSON.parse(sessionStorage.getItem('selectedSite')) || '';
					if (this.selectedSite) {
						this.siteFilterService.announceSiteChange({ siteId: this.selectedSite.value, customerId: this.selectedSite.customerId, siteOverviewEnabled: this.selectedSite.siteOverviewEnabled });
					}
				}, 10)
			}

		});

		this.eventNotificationService.getEventDispatcher$().subscribe((event) => {
			if (event.type === EventType.DETAILED_VIEW_SELECTED || event.type === EventType.CUSTOMER_SPECIFICATIONS_VIEW_SELECTED) {
				this.disableSiteSelection = true;
			}

			if (event.type === EventType.DEFAULT_VIEW_SELECTED) {
				this.disableSiteSelection = false;
			}
		})

	}

	public openSiteSelectionOnMobile() {
		this.isSiteSeletionActive = true;
		this.renderer.addClass(document.body, 'noscroll');
		this.renderer.addClass(document.getElementById('app-wrapper'), 'noscroll');
	}

	public closeSiteSelectionOnMobile() {
		this.isSiteSeletionActive = false;
		this.renderer.removeClass(document.body, 'noscroll');
		this.renderer.removeClass(document.getElementById('app-wrapper'), 'noscroll');
	}

	public resetSelection() {
		this.selectedSite = null;
	}

	public handleSiteSelection(event) {
		sessionStorage.setItem(`selectedSite`, JSON.stringify(this.selectedSite));
		this.siteFilterService.announceSiteChange({ siteId: this.selectedSite.value, customerId: this.selectedSite.customerId, siteOverviewEnabled: this.selectedSite.siteOverviewEnabled });
		this.closeSiteSelectionOnMobile();
	}
}
